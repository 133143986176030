/**
 *
 * GraphicData
 *
 */

import GraphicData, { GraphicDataProperty } from './GraphicData';

export { GraphicData, GraphicDataProperty };

export default GraphicData;
