import { Plants } from 'models';
import { Images } from 'constants/index';

export const plants: Plants = [
    {
        id: '100200',
        name: 'Affalterbach',
        thumbnail: Images.Affalterbach,
        location: 'Germany',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.1',
        target: '45/1209',
        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '1', name: 'E-Class', lines: [{ id: '213', name: '213' }] },
        ],
    },
    {
        id: '102020',
        name: 'Berlin',
        thumbnail: Images.Berlin,
        location: 'Germany',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.3',
        target: '45/1209',
        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    {
        id: '103100',
        name: 'Bremen',
        thumbnail: Images.Bremen,
        location: 'Germany',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.8',
        target: '45/1209',
    },
    {
        id: '104200',
        name: 'Hamburg',
        thumbnail: Images.Hamburg,
        location: 'Germany',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.9',
        target: '45/1209',

        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '1', name: 'E-Class', lines: [{ id: '213', name: '213' }] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    {
        id: '109700',
        name: 'Sindelfingen',
        thumbnail: Images.Sindelfingen,
        location: 'Germany',
        route: '/plantHealthOverview',
        active: true,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.3',
        target: '45/1209',
        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '1', name: 'E-Class', lines: [{ id: '213', name: '213' }] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    {
        id: '105300',
        name: 'Stuttgart',
        thumbnail: Images.Stuttgart,
        location: 'Germany',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.5',
        target: '45/1209',
    },
    {
        id: '106400',
        name: 'Kamenz',
        thumbnail: Images.Kamenz,
        location: 'Germany',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.8',
        target: '45/1209',

        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    {
        id: '107500',
        name: 'Kölleda',
        thumbnail: Images.Kolleda,
        location: 'Germany',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.9',
        target: '45/1209',
    },
    // {
    //     id: '108600',
    //     name: 'Rastatt',
    //     thumbnail: Images.Rastatt,
    //     location: 'Germany',
    //     route: '/plantHealthOverview',
    //     active: false,
    // },
];

export const plantsDemo: Plants = [
    {
        id: '001', //'100200',
        name: 'Factory 1',
        thumbnail: Images.Affalterbach,
        location: 'Baunatal',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.8',
        target: '45/1209',
    },
    {
        id: '001', //'102020',
        name: 'Factory 2',
        thumbnail: Images.Berlin,
        location: 'Braunschweig',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.9',
        target: '45/1209',

        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    {
        id: '001', //'103100',
        name: 'Factory 3',
        thumbnail: Images.Bremen,
        location: 'Chemnitz',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.6',
        target: '45/1209',
        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '1', name: 'E-Class', lines: [{ id: '213', name: '213' }] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    {
        id: '001', //'104200',
        name: 'Factory 4',
        thumbnail: Images.Hamburg,
        location: 'Dresden',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.9',
        target: '45/1209',
    },
    {
        id: '001', //'109700',
        name: 'Factory 5',
        thumbnail: Images.Sindelfingen,
        location: 'Düsseldorf',
        route: '/plantHealthOverview',
        active: true,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.8',
        target: '45/1209',
        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '1', name: 'E-Class', lines: [{ id: '213', name: '213' }] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    {
        id: '001', //'105300',
        name: 'Factory 6',
        thumbnail: Images.Stuttgart,
        location: 'Emden',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.6',
        target: '45/1209',
        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '1', name: 'E-Class', lines: [{ id: '213', name: '213' }] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    {
        id: '001', //'106400',
        name: 'Factory 7',
        thumbnail: Images.Kamenz,
        location: 'Hanover',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.7',
        target: '45/1209',
    },
    {
        id: '001', //'107500',
        name: 'Factory 8',
        thumbnail: Images.Kolleda,
        location: 'Ludwigsfelde',
        route: '/plantHealthOverview',
        active: false,
        risk: '4.5%',
        lastUpdated: '05-12-2021',
        faultTrend: Images.Affalterbach,
        technicalAvailability: '99.9',
        target: '45/1209',
        carClasses: [
            { id: '0', name: 'All Classes', lines: [] },
            { id: '1', name: 'E-Class', lines: [{ id: '213', name: '213' }] },
            { id: '2', name: 'S-Class', lines: [{ id: '213', name: '223' }] },
        ],
    },
    // {
    //     id: '108600',
    //     name: 'Rastatt',
    //     thumbnail: Images.Rastatt,
    //     location: 'Germany',
    //     route: '/plantHealthOverview',
    //     active: false,
    // },
];

export const plantsJLR: any = [
    {
        id: '001',
        name: 'Demo',
        thumbnail: Images.TileImage,
        location: 'Slovakia',
        route: '/plantHealthOverview',
        active: true,
        lastUpdated: 'NA',
        faultTrend: Images.Affalterbach,
        carClasses: [{ id: '0', name: 'All', lines: [] }],
    },
];
export default plants;
