/**
 *
 * EventCodeFrequency
 *
 */

import EventCodeFrequency, { EventCodeFrequencyProperty } from './EventCodeFrequency';

export { EventCodeFrequency, EventCodeFrequencyProperty };

export default EventCodeFrequency;
