/**
 *
 * OpportunityAnalysis
 *
 */

import OpportunityAnalysis, { OpportunityAnalysisProperty } from './OpportunityAnalysis';

export { OpportunityAnalysis, OpportunityAnalysisProperty };

export default OpportunityAnalysis;
