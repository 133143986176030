export const translations = {
    i18nFeature: {
        title: 'Internationalisation et pluralisation i18n',
        selectLanguage: 'Choisir la langue',
        description:
            'Les applications évolutives doivent prendre en charge plusieurs langues, ajouter et prendre en charge facilement plusieurs langues. Changez la langue ci-dessous pour voir comment instantanément il met à jour la page sans actualiser.',
    },
    Blank: {
        title: 'Page vierge i18n',
    },
    General: {
        All: 'Tous',
        Plant: 'Usine: {{plantId}}',
        TimeInUnitsLabel: 'Temps dans',
        LayoutLabel: 'Layout',
        NoData: 'Aucune donnée à afficher',
    },
    Components: {
        NavNotification: {
            StatusInfo: 'Notification',
        },
        DashboardFilterPanel: {
            Title: 'Filters',
            Reset: 'réinitialisation',
        },
        Search: {
            Placeholder: 'chercher',
        },
        UploadPanel: {
            dropzoneText: 'Déposez les fichiers ici.',
            Clear: 'Effacer',
            Upload: 'Télécharger',
        },
        OverviewCard: {
            AverageTA: 'Moyenne TA',
            FaultsText: 'Défaut',
            CyclesText: 'Cycles',
            MTTRUnitsText: 'mins',
            MTTRText: 'MTTR',
        },
        OperationsForm: {
            AddNew: 'Ajouter nouveau',
            Shift: 'postes',
            Break: 'Pause',
            Time: 'Temps',
            Date: 'Date',
            To: 'à',
            Cancel: 'Annuler',
            Add: 'Ajouter',
            Hour: 'Heure',
            Minutes: 'Procès-verbal',
        },
    },
    DashboardPowerBi: { PageTitle: 'Tableau de bordPowerBi', Title: 'Tableau de bordPowerBi' },
    Dashboard: { CycleGapTempo: 'Cycle Gap Tempo', MeasurementTrends: 'Measurement Trends' },
    Filters: {
        // filter labels
        FilterByFaultCodeLabel: 'Défaut',
        FilterByStudTypeLabel: 'Goujon-Type',
        FilterByStudIdLabel: 'Goujon-ID',
        FilterByDeviceNameLabel: "Nom de l'appareil",
        FilterByDeviceTypeLabel: 'Type d’appareil',
        FilterByBodyShopLabel: 'Atelier de carrosserie',
        FilterByDateLabel: 'Date',
        EventTypeLabel: 'Type d’événement',
        LineLabel: 'ligne',
        WeekLabel: 'Semain',
        FilterByReportThresholdLabel: 'Seuil de rapport',
        StationLabel: 'Gare',
        FilterByAnomalyLabel: 'Anomalie',
        FilterByAnomalyConfidenceLabel: 'Confiance',
        SelectFilterLabel: 'Filtre',
        // filter placeholders
        FilterByFaultCodePlaceholder: 'Filtrer par Défaut',
        FilterByStudTypePlaceholder: 'Filtrer par Goujon-Type',
        FilterByStudIdPlaceholder: 'Filtrer par Goujon-ID',
        FilterByDeviceNamePlaceholder: "Filtrer par Nom de l'appareil",
        FilterByDeviceTypePlaceholder: 'Filtrer par type de périphérique',
        FilterByBodyShopPlaceholder: 'Filtrer par atelier de carrosserie',
        FilterByDatePlaceholder: 'Date',
        EventTypePlaceholder: 'Filtrer par Type d’événement',
        FilterByLinePlaceholder: 'Filtrer par ligne',
        FilterByWeekPlaceholder: 'Filtrer par semaine',
        FilterByReportThresholdPlaceholder: 'Sélectionner le seuil de rapport',
        FilterByStationPlaceholder: 'Filtrer par station',
        FilterByAnomalyPlaceholder: 'Filtrer par anomalie',
        FilterByAnomalyConfidencePlaceholder: 'Filtrer par Confiance',
        SelectFilterPlaceholder: 'Select',
        Fault: 'Störung',
        Warning: 'Warnung',
    },
    Widgets: {
        NinePanelTable: {
            NoData: 'Pas de données',
        },
        ProcessLog: {
            Title: 'Journal diagnostique',
            SubTitle: 'Journal diagnostique',
            AnomalyTypeLabel: 'le Type',
            AnomalyDeviceLabel: 'Informations sur l’appareil',
            AnomalyStationLabel: 'Gare',
            AnomalyListLabel: 'Anomalie',
            AnomalyConfidenceLabel: 'Confiance',
            AnomalyTimeLabel: 'Time',
            AnomalyFeedbackLabel: 'Commentaires',
        },
        StationAnomaly: {
            Title: 'Liste des anomalies de station',
            SubTitle: 'Liste des anomalies de station',
            NoAnomaly: 'Aucun',
            AnomalyFeedbackLabel: 'commentaires',
            AnomalyMetadataLabel: 'métadonnées',
        },
        EventCodeFrequency: {
            Title: 'Event Code Frequency Widget',
            WidgetTitle: 'Event Code Frequency Widget',
            SubTitle: 'Event Code Frequency Widget',
            FaultFrequencyChartTitle: `Fréquences du code d'événement`,
            YAxisFaultFrequencyTitle: `Nombre d'événements`,
            XAxisFaultFrequencyTitle: `Numéro de l'événement`,
            Fault: 'Fault',
            FaultCount: 'Nombre de défauts',
        },
        EventDescFrequency: {
            Title: 'Frequency Event Description Widget',
            WidgetTitle: 'Event Desc Frequency Widget',
            SubTitle: 'EventDescFrequency Widget',
            FaultFrequencyChartTitle: 'Frequencies of Event Description 2021',
            YAxisFaultFrequencyTitle: 'Description',
            XAxisFaultFrequencyTitle: `Nombre d'événements`,
            Fault: 'Fault',
        },
        EventCountFrequencyWidget: {
            Title: 'Event Count Frequency widget',
            WidgetTitle: 'EventCountFrequencyWidget Widget',
            SubTitle: 'EventCountFrequencyWidget Widget',
            FaultFrequencyCountTitle: 'Event Code by Week 2021',
            YAxisFaultFrequencyCountTitle: 'Week',
            XAxisFaultFrequencyCountTitle: 'Event Count',
        },
        EventCountDailyFrequency: {
            Title: 'Event Count Frequency widget',
            WidgetTitle: 'EventCountFrequencyWidget Widget',
            SubTitle: 'EventCountFrequencyWidget Widget',
            FaultFrequencyCountTitle: 'Event Code by Day 2021',
            YAxisFaultFrequencyCountTitle: 'Date',
            XAxisFaultFrequencyCountTitle: 'Event Count',
        },
        OpportunityAnalysis: {
            WidgetTitle: 'Analyse des Opportunités',
            SubTitle: 'Analyse des Opportunités',
            ActionRecommended: 'Mesure Recommandée',
            ActionTaken: 'Mesures Prises',
            CaseClosed: 'Affaire Classée',
        },
        EventRateCycleCount: {
            WidgetTitle: 'EventRateCycleCount Widget',
            SubTitle: 'EventRateCycleCount Widget',
            EventRateCycleCountitle: `Compte de cycles et événement Pourcentage par semaine`,
            YAxisEventRateCycleCountTitle: 'Nombre de cycles',
            SecondaryYAxisEventRateCycleCountTitle: `Taux d'événement`,
            XAxisEventRateCycleCountTitle: 'Date',
        },
        EventRatePerEvent: {
            WidgetTitle: 'EventRatePerEvent Widget',
            SubTitle: 'EventRatePerEvent Widget',
            Fault: 'Fault',
            Warning: 'Warning',
        },
        EventRatePerDevice: {
            WidgetTitle: 'EventRatePerDevice Widget',
            SubTitle: 'EventRatePerDevice Widget',
            EventRatePerDeviceTitle: `Taux d'événement par appareil par jour 2021`,
            EventRatePerBodyshopTitle: 'Tarif événement pour',
            EventRateByDay: 'de jour',
            EventRateByWeek: 'par semaine',
            YAxisEventRatePerDeviceTitle: `Taux d'événements (parties par million)`,
            XAxisEventRatePerDeviceTitle: 'Date',
        },
        Lift: {
            WidgetTitle: 'De Levage',
            SubTitle: 'De Levage',
            YAxisTitle: 'De Levage (mm)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Réel',
        },
        Penetration: {
            WidgetTitle: `Pénétration`,
            SubTitle: `Pénétration`,
            YAxisTitle: 'Pénétration (mm)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Réel',
        },
        Voltage: {
            WidgetTitle: 'Tension',
            SubTitle: 'Tension',
            YAxisTitle: 'Tension (V)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Réel',
        },
        WeldTime: {
            WidgetTitle: 'Temps de soudage',
            SubTitle: 'Temps de soudage',
            YAxisTitle: 'Temps (ms)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Réel',
        },
        DurationOfFaults: {
            WidgetTitle: "Durée du code d'événement",
            Fault: 'Défaut',
            xAxisLabel: "Numéro de l'événement",
            yAxisLabel: "Durée de l'événement Nombre",
        },
        TaAnalysisTable: {
            WidgetTitle: `Ta Tableau d'analyse`,
            SubTitle: `Ta Tableau d'analyse`,
            TableDeviceName: `Nom du dispositif Sortie / Code d'erreur`,
            TableTitle: `TA Pour le nom du dispositif et la sortie`,
        },
        MtbfAnalysisTable: {
            WidgetTitle: 'MtbfAnalysisTable Widget',
            SubTitle: 'MtbfAnalysisTable Widget',
            TableDeviceName: `Nom du dispositif Sortie / Code d'erreur`,
            TableTitle: `Temps moyen entre les occurrences d'un défaut`,
        },
    },
    HomePage: {
        PageTitle: `Page d'accueil`,
        Title: `Page d'accueil`,
        Last_Updated: 'Dernière mise à jour',
        Risk: 'Risque',
        Target: 'Cible',
        TechnicalAvail: 'TA',
        FaultTrend: 'Tendance des défauts',
    },
    SignIn: {
        PageTitle: 'Connexion',
        Title: 'connexion',
        Email: {
            Placeholder: 'E-mail',
        },
        Password: {
            Placeholder: 'Mots de passe',
        },
        LoggedIn: 'Rester connecté',
        LoginButton: {
            Label: 'Connexion',
            Loading: 'Chargement...',
        },
        ForgetUsernameOrPassword: `Oubliez le nom d'utilisateur / mot de passe`,
        NewUser: 'Nouvel utilisateur',
        Error: {
            ValidEmailRequired: 'Obligatoire: veuillez fournir une adresse e-mail valide.',
            ValidPasswordRequired: 'Obligatoire: veuillez fournir un mot de passe valide.',
        },
    },
    NavProfile: {
        UserAvatarAltMessage: `Avatar de l'utilisateur`,
    },
    PanelScreenPage: {
        PageTitle: '9 Panneau - Alt',
        Title: `Progrès en un coup d'œil`,
    },
    ProjectsPage: {
        PageTitle: 'Projets',
        Title: `Progrès en un coup d'œil`,
    },
    ReportingViewPage: {
        PageTitle: 'Analyse de la cause originelle',
        Title: 'Analyse de la cause originelle',
        Table: {
            StudType: 'Goujon-Type',
            DeviceName: `Nom de l'appareil`,
            StudId: 'Goujon-ID',
        },
        DatePicker: {
            ANNUALLY: '[An] YYYY',
            WEEKLY: '[Semaine] W',
            DAILY: 'ddd',
            HOURLY: 'YYYYMMDD HH:MM:ss',
        },
        FileUpload: 'Déposer Télécharger',
        ExportToExcel: 'Exporter au format eXcel',
        ExportToPdf: 'Exporter au format PDF',
        FaultAssignment: 'Affectation des défauts',
        FaultAssignmentVal: 'Actif',
        StatusB: 'Statut B',
        StatusBVal: 'Vide',
        Event: 'Evénement',
        EventVal: 'Défaut',
        SelectFaultCode: 'Select Défaut #:',
        FilterByFaultCode: 'Filter By Défaut #:',
        FilterByStudType: 'Filter By Goujon-Type',
        FilterByDeviceName: 'Filter By Appareil',
        FilterByStudID: 'Filter By Goujon-ID',
        RecommendedActionTitle: 'Actions',
        InformationPlaceholder: 'Espace réservé au titre des informations',
        AddCommentsTitle: 'Ajouter des commentaires (facultatif):',
        AddCommentsBtn: 'Soumettre',
        History: 'Histoire',
        Close: 'Fermer',
        filterBy: 'Filter By',
        ParentLabelPage: {
            Year: 'An',
            Week: 'Semaine',
            Day: 'Jour',
            Hour: 'Heure',
        },
        NoData: 'Aucune donnée à afficher',
        DataUptodate: 'Les données sont à jour. Dernière mise à jour',
    },
    ReportingViewPageB: {
        PageTitle: 'Vue du rapport',
        Title: 'Vue du rapport',
        Table: {
            Fault: 'Défaut',
            Description: 'Description',
        },
        FileUpload: 'Déposer Télécharger',
        ExportToExcel: 'Exporter au format eXcel',
        ExportToPdf: 'Exporter au format PDF',
        FaultAssignment: 'Affectation des défauts',
        FaultAssignmentVal: '(Plusieurs éléments)',
        StatusB: 'Status B',
        StatusBVal: 'Vide',
        Event: 'Evénement',
        EventVal: 'Défaut',
        SelectFaultCode: 'Select Défaut #:',
        FilterByStudType: 'Filter By Goujon-Type',
        FilterByDeviceName: 'Filter By Appareil',
        FilterByStudID: 'Filter By Goujon-ID',
        WeekDay: 'Jour de la semaine',
        All: 'Tous',
        DeviceName: `Nom de l'appareil`,
        ThresholdRedMarkingNote: ' {{threshold}} erreurs par jour',
        StudType: 'Goujon-Type',
        OverAllResult: 'Résultat total',
        Sunday: 'dimanche',
        Monday: 'Lundi',
        Tuesday: 'Mardi',
        Wednesday: 'Mercredi',
        Thursday: 'Jeudi',
        Friday: 'Vendredi',
        Saturday: 'Samedi',
        Fault: 'Défaut',
        Info: 'Info',
        ComponentExchange: 'Échange de composants',
        FirmwareUpdate: 'Mise à jour du firmware',
        Maintainance: 'Maintainance',
        Active: 'Actif',
        // Events: 'Défaut,Info,Échange de composants,Mise à jour du firmware,Maintainance,Actif',
        Events: 'Défaut',
        Year: 'An',
        Week: 'Semaine',
        PDFTitle: `Rapport d'erreur quotidien`,
        PDFFileName: 'Erreur_Rapport',
    },
    SummaryDashboard: {
        PageTitle: 'Tableau de bord récapitulatif',
        Title: `Tableau de bord récapitulatif en un coup d'œil`,
    },
    PlantLevelKpi: {
        PageTitle: 'KPI Usine',
        Title: `Résumé des KPI de l'usine en un coup d'œil`,
    },
    LabAnalysis: {
        PageTitle: 'KPI des données de laboratoire',
        Title: 'KPI des données de laboratoire',
    },
    Sidebar: {
        PageTitle: 'Seitenleiste',
        Title: 'Seitenleiste Seite',
        NinePanel: 'Neuf panneau',
        Home: `'Page d'accueil'`,
        Projects: 'Projets',
        ReportingView: 'Vue du rapport',
        ReportingViewB: 'Vue du rapport B',
        Dashboards: 'Tableau de bord',
        SummaryDashboard: 'Tableau de bord récapitulatif',
        PlantKPI: 'KPI Usine',
        Analytics: 'Analytics',
        LabAnalysis: 'Analyse',
        DashboardKPIs: 'Tableau de bord KPIs',
        FileUpload: 'Déposer Télécharger',
        Api: 'ELU API',
        Logout: 'Se déconnecter',
    },
    FileUpload: {
        PageTitle: 'Déposer Télécharger',
        Title: 'Déposer Télécharger',
        DropzoneText: 'Déposer les fichiers à télécharger',
        UploadCyclesLabel: 'Télécharger les mesures / cycles XML',
        UploadSytemEventsLabel: 'Téléchargement des événements système XML',
        UploadMaintenanceLabel: 'Maintenance du téléchargement XML',
        Back: 'Retour',
        Finish: 'Terminé',
    },
    SystemOverview: {
        PageTitle: `Vue d’ensemble de la flotte`,
        Title: `Vue d’ensemble de la flotte`,
        TableColumnFault: `Nom de l'appareil / Outlet`,
    },
    PlantOverview: {
        PageTitle: "Vue d'ensemble de l'usine",
        Title: "Vue d'ensemble de l'usine",
        NavBarTitle: 'APPs',
        MaintenanceTitle: 'Vue du rapport',
        MaintenanceSubTitle: 'Analyse de la maintenance',
        SystemTitle: `Vue d’ensemble de la flotte`,
        SystemSubTitle: 'Santé du système',
        UploadTitle: 'Télécharger',
        UploadSubTitle: 'Déposer Télécharger',
        SystemHealthCard: "Carte d'intégrité du système",
        Week: 'Semaine',
        Change: 'Changement',
        New: 'Nouveau',
        NoChange: 'Pas de changement',
        FaultFrequencyChartTitle: `Top {{topX}} Fréquences du code d'événement`,
        FaultByOccurrence: 'Top {{topX}} Défauts avec la fréquence la plus élevée',
        FaultByDuration: 'Top {{topX}} Défauts avec la durée la plus longue',
        SystemWithFaults: `Top {{topX}} Appareils avec l'occurrence de défaut la plus élevée`,
        StudTypeWithFaults: `Top {{topX}} Types de goujons avec l'occurrence de défaut la plus élevée`,
        DeviceByDuration: 'Top {{topX}} Dispositifs ayant la plus longue durée',
        Fault: 'Défaut',
        Occurrences: 'Occurrences',
        Position: 'Positionner',
        System: 'Système',
        StudType: 'Type-Goujon',
        YAxisFaultFrequencyTitle: `Nombre d'événements`,
        XAxisFaultFrequencyTitle: `Numéro de l'événement`,
        TotalCyclesTooltip: 'en semaine',
        PreviousDay: 'Jour précédent',
        PreviousWeek: 'Semaine précédente',
        TotalCycles: 'Nombre de cycles hebdomadaire',
        TechnicalAvailability: 'Disponibilité technique',
        MeanTimeToRepair: 'Temps moyen de réparation',
        MeanTimeBetweenFailure: 'Temps moyen entre les pannes',
        MeanTimeToFailure: `Temps moyen jusqu'à l'échec`,
        Ratio: 'Le ratio',
        Cycle: 'Cycle',
    },
    ProcessLogView: {
        PageTitle: 'Journal diagnostique',
        Title: 'Journal diagnostique',
        BtnBarDateFilter: 'Historique',
    },
    ParetoAnalysis: {
        PageTitle: 'Analyse de Pareto',
        Title: 'Analyse de Pareto ',
        EventDescriptionChartTitle: `Fréquence de la description de l'événement`,
        EventDescriptionChartXAxisLabel: `Nombre d'événements`,
        EventDescriptionChartYAxisLabel: 'Description',
        EventCodeChartTitle: `Fréquence du code d'événement`,
        EventCodeChartXAxisLabel: `Numéro de l'événement`,
        EventCodeChartYAxisLabel: `Nombre d'événements`,
        EventCodeChartSecondaryYAxisLabel: 'Pourcentage',
    },
    FailureRateTrend: {
        PageTitle: `Tendance du taux d'échec`,
        Title: `Tendance du taux d'échec`,
        EventRatePerEventChartTitleDaily: `Taux d'événement par numéro d'événement par jour 2021`,
        EventRatePerEventChartTitleWeekly: `Taux d'événement par numéro d'événement par semaine 2021`,
        EventRatePerDeviceTitleDaily: `Taux d'événement par appareil par jour 2021`,
        EventRatePerDeviceTitleWeekly: `Taux d'événement par appareil par semaine 2021`,
        EventRatePerEventXAxisLabelDaily: 'Day',
        EventRatePerEventXAxisLabelWeekly: 'Semaine',
        EventRatePerEventYAxisLabel: `Taux d'événements (parties par million)`,
    },
    FaultCountTrend: { PageTitle: 'Tendance du nombre de défauts', Title: 'Tendance du nombre de défauts ' },
    RootCauseVisibility: { PageTitle: 'Root Cause Visibility', Title: 'Root Cause Visibility ' },
    EssentialControlChart: { PageTitle: 'Tableau de contrôle essentiel', Title: 'Tableau de contrôle essentiel' },
    MtbfAnalysis: {
        PageTitle: 'Analyse du temps moyen entre les défaillances',
        Title: 'Analyse du temps moyen entre les défaillances',
    },
    MttrAnalysis: { PageTitle: 'Mean Time To Repair Analysis', Title: 'Mean Time To Repair Analysis ' },
    TaAnalysis: { PageTitle: `Analyse de la disponibilité technique`, Title: `Analyse de la disponibilité technique` },
    EventOccurrence: { PageTitle: 'Event Occurrence', Title: 'Event Occurrence ' },
    TopXFaultCountAnalysis: { PageTitle: 'Top Faults Per AFO', Title: 'Top Faults Per AFO' },
    FaultRateForecast: { PageTitle: 'Fault Rate Forecast', Title: 'Fault Rate Forecast ' },
    CarBodyAnalysis: { PageTitle: `Analyse de la carrosserie`, Title: `Analyse de la carrosserie` },
    OpportunityAnalysis: { PageTitle: 'Analyse des opportunités', Title: 'Analyse des opportunités' },
    SettingsPage: {
        PageTitle: 'Settings',
        Operation: 'Fonctionnement',
        AddNew: 'Ajouter nouveau',
        Shifts: 'Équipes',
        Breaks: 'Pause',
        ShiftName: "Nom de l'équipe",
        BreakName: 'Nom de la pause',
        TimeFrom: 'Temps de',
        TimeTo: 'Il est temps de',
        DateFrom: 'Date du',
        DateTo: 'Date à',
        Delete: 'effacer',
        Edit: 'Éditer',
        Actions: 'Actions',
    },
    // [INSERT NEW COMPONENT EN TITLE KEY ABOVE] < Needed for generating containers language seamlessly
};

export default translations;
