/**
 *
 * Breadcrumb
 *
 */

import { default as Breadcrumb } from './Breadcrumb';

export * from './Breadcrumb';
export { Breadcrumb };

export default Breadcrumb;
