/**
 *
 * PlantCycleAverages
 *
 */

import PlantCycleAverages, { PlantCycleAveragesProperty } from './PlantCycleAverages';

export { PlantCycleAverages, PlantCycleAveragesProperty };

export default PlantCycleAverages;
