/**
 *
 * SelectFaultCodeFilter
 *
 */

import SelectFaultCodeFilterNew from './SelectFaultCodeFilter';

export { SelectFaultCodeFilterNew };

export default SelectFaultCodeFilterNew;
