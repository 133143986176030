/**
 *
 * SelectStudIdFilter
 *
 */

import SelectStudIdFilterNew from './SelectStudIdFilter';

export { SelectStudIdFilterNew };

export default SelectStudIdFilterNew;
