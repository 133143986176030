/**
 *
 * DeviceInfoCard
 *
 */

import DeviceInfoCard from './DeviceInfoCard';
import DeviceInfoCardLoadable from './Loadable';

export { DeviceInfoCard, DeviceInfoCardLoadable };

export default DeviceInfoCard;
