/**
 *
 * TopXTable
 *
 */

import TopXTable from './TopXTable';
import TopXTableLoadable from './Loadable';

export { TopXTable, TopXTableLoadable };

export default TopXTable;
