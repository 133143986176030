export const translations = {
    i18nFeature: {
        title: 'i18n Internationalisierung und Pluralisierung',
        selectLanguage: 'Sprache auswählen',
        description:
            'Das Internet ist global. Mehrsprachige- und Pluralisierungsunterstützung ist entscheidend für große Web-Anwendungen. Sie können die Sprache unten verändern, ohne die Seite aktualisieren zu müssen.',
    },
    Blank: {
        title: 'i18n Leere Seite',
    },
    General: {
        All: 'Alle',
        Colors: { Red: 'Rote' },
        Plant: 'Fabrik: {{plantId}}',
        TimeInUnitsLabel: 'Zeit in',
        LayoutLabel: 'Layout',
        NoData: 'Keine anzuzeigenden Daten',
    },
    Components: {
        NavNotification: {
            StatusInfo: 'Benachrichtigung',
        },
        DashboardFilterPanel: {
            Title: 'Filter',
            Reset: 'Zurücksetzen',
        },
        Search: {
            Placeholder: 'Suche',
        },
        UploadPanel: {
            dropzoneText: 'Dateien hier ablegen.',
            Clear: 'klar',
            Upload: 'hochladen',
        },
        OverviewCard: {
            AverageTA: 'Durchschnittliche TA',
            FaultsText: 'Störung',
            CyclesText: 'Zyklen',
            MTTRUnitsText: 'mins',
            MTTRText: 'MTTR',
        },
        OperationsForm: {
            AddNew: 'Neu hinzufügen',
            Shift: 'Schicht',
            Break: 'Pause',
            Time: 'Zeit',
            Date: 'Datum',
            To: 'zu',
            Cancel: 'Abbrechen',
            Add: 'Hinzufügen ',
            Hour: 'Stunde',
            Minutes: 'Minuten',
        },
    },
    DashboardPowerBi: {
        PageTitle: 'DashboardPowerBi',
        Title: 'DashboardPowerBi',
    },
    Dashboard: {
        CycleGapTempo: 'Cycle Gap Tempo',
        MeasurementTrends: 'Measurement Trends',
        DailyFaultTrends: 'Daily Fault Trends',
        StationAvailabilityReport: 'Station Availability Report',
        StationAvailabilityTrend: 'Station Availability Trend',
        GraphicDataAnalysis: 'Graphic Data Analysis',
        CarbodyDuration: 'Carbody Duration',
        CarbodyDurationPerStation: 'Carbody Duration Per Station',
    },
    Filters: {
        // filter labels
        FilterByFaultCodeLabel: 'Störung',
        FilterByStudTypeLabel: 'Bolzen',
        FilterByStudIdLabel: 'BolzenID',
        FilterByDeviceNameLabel: 'AFO',
        FilterByDeviceTypeLabel: 'AFO Type',
        FilterByBodyShopLabel: 'Karosseriebau',
        FilterByDateLabel: 'Datum',
        EventTypeLabel: 'Event Typ',
        LineLabel: 'Linie',
        WeekLabel: 'Woche',
        FilterByReportThresholdLabel: 'Berichtsschwellenwert',
        StationLabel: 'Station',
        FilterByAnomalyLabel: 'Anomalie',
        FilterByAnomalyConfidenceLabel: 'Vertrauen',
        SelectFilterLabel: 'Filter',
        // filter placeholders
        FilterByFaultCodePlaceholder: 'Filtern nach Störung',
        FilterByStudTypePlaceholder: 'Filtern nach Bolzen',
        FilterByStudIdPlaceholder: 'Filtern nach BolzenID',
        FilterByDeviceNamePlaceholder: 'Filtern nach AFO',
        FilterByDeviceTypePlaceholder: 'Filtern nach AFO Type',
        FilterByBodyShopPlaceholder: 'Filtern nach Karosseriebau',
        FilterByDatePlaceholder: 'DaFiltern nach Datumte',
        EventTypePlaceholder: 'Filtern nach Ereignistyp',
        FilterByLinePlaceholder: 'Filter nach Zeile',
        FilterByWeekPlaceholder: 'Filter nach Woche',
        FilterByReportThresholdPlaceholder: 'Berichtschwellenwert auswählen',
        FilterByStationPlaceholder: 'Filter nach Station',
        FilterByAnomalyPlaceholder: 'Filter nach Anomalie',
        FilterByAnomalyConfidencePlaceholder: 'Filter nach Vertrauen',
        SelectFilterPlaceholder: 'Wählen Sie',
        Fault: 'Störung',
        Warning: 'Warnung',
        FirmwareUpdate: 'Firmware Update',
        Info: 'Info',
        Componentexchange: 'Komponententausch',
        Maintenance: 'Wartung',
    },
    Widgets: {
        NinePanelTable: {
            NoData: 'Keine Daten',
        },
        ProcessLog: {
            Title: 'Diagnoseprotokoll',
            SubTitle: 'Diagnoseprotokoll',
            AnomalyTypeLabel: 'mod',
            AnomalyDeviceLabel: 'Geräteinformationen',
            AnomalyStationLabel: 'Station',
            AnomalyListLabel: 'Anomalie',
            AnomalyConfidenceLabel: 'Vertrauen',
            AnomalyTimeLabel: 'Zeit',
            AnomalyFeedbackLabel: 'Ruckmeldung',
        },
        StationAnomaly: {
            Title: 'Stationsanomalieliste',
            SubTitle: 'Stationsanomalieliste',
            NoAnomaly: 'Keiner',
            AnomalyFeedbackLabel: 'Ruckmeldung',
            AnomalyMetadataLabel: 'Metadaten',
        },
        EventCodeFrequency: {
            Title: 'Event Code Frequency',
            WidgetTitle: 'Event Code Frequency',
            SubTitle: 'Event Code Frequency',
            FaultFrequencyChartTitle: 'Häufigkeit des Ereigniscodes',
            YAxisFaultFrequencyTitle: 'Anzahl der Ereignisnummern',
            XAxisFaultFrequencyTitle: 'Ereignisnummer',
            Fault: 'Störung',
            FaultCount: 'Anzahl von Fehlern',
        },
        EventDescFrequency: {
            Title: 'Frequency Event Description Widget',
            WidgetTitle: 'Event Desc Frequency Widget',
            SubTitle: 'EventDescFrequency Widget',
            FaultFrequencyChartTitle: 'Frequencies of Event Description 2021',
            YAxisFaultFrequencyTitle: 'Beschreibung',
            XAxisFaultFrequencyTitle: 'Anzahl der Ereignisnummern',
            Fault: 'Störung',
        },
        EventCountFrequencyWidget: {
            Title: 'Ereignisanzahl Frequency widget',
            WidgetTitle: 'EventCountFrequencyWidget Widget',
            SubTitle: 'EventCountFrequencyWidget Widget',
            FaultFrequencyCountTitle: 'Ereignisanzahl pro Woche 2021',
            YAxisFaultFrequencyCountTitle: 'Ereignisanzahl',
            XAxisFaultFrequencyCountTitle: 'Woche',
            Fault: 'Störung',
        },
        EventCountDailyFrequency: {
            Title: 'Ereignisanzahl Frequency widget',
            WidgetTitle: 'EventCountFrequencyWidget Widget',
            SubTitle: 'EventCountFrequencyWidget Widget',
            FaultFrequencyCountTitle: 'Ereignisanzahl nach Tag 2021',
            YAxisFaultFrequencyCountTitle: 'Ereignisanzahl',
            XAxisFaultFrequencyCountTitle: 'Datum',
        },
        OpportunityAnalysis: {
            WidgetTitle: 'Opportunity-Analyse',
            SubTitle: 'Opportunity-Analyse',
            ActionRecommended: 'Aktion Empfohlen',
            ActionTaken: 'Ergriffene Maßnahmen',
            CaseClosed: 'Fall Geschlossen',
        },
        EventRateCycleCount: {
            WidgetTitle: 'EventRateCycleCount Widget',
            SubTitle: 'EventRateCycleCount Widget',
            EventRateCycleCountitle: 'Zykluszähler und Ereignis Prozentsatz nach Woche',
            YAxisEventRateCycleCountTitle: 'Anzahl der Durchgänge',
            SecondaryYAxisEventRateCycleCountTitle: 'Ereignisrate',
            XAxisEventRateCycleCountTitle: 'Woche',
        },
        EventRatePerEvent: {
            WidgetTitle: 'EventRatePerEvent Widget',
            SubTitle: 'EventRatePerEvent Widget',
            Fault: 'Störung',
            Warning: 'Warnung',
        },
        EventRatePerDevice: {
            WidgetTitle: 'EventRatePerDevice Widget',
            SubTitle: 'EventRatePerDevice Widget',
            EventRatePerDeviceTitle: 'Ereignisrate pro Gerät pro Tag 2021',
            EventRatePerBodyshopTitle: 'Ereignisrate für',
            EventRateByDay: 'nach Tag',
            EventRateByWeek: 'nach Woche',
            YAxisEventRatePerDeviceTitle: 'Ereignisrate ( Teil pro Million )',
            XAxisEventRatePerDeviceTitleWeekly: 'Woche',
            XAxisEventRatePerDeviceTitleDaily: 'Day',
            Fault: 'Störung',
        },
        Lift: {
            WidgetTitle: 'Anheben',
            SubTitle: 'Anheben',
            YAxisTitle: 'Anheben (mm)',
            Maximum: 'Maximal',
            Minimum: 'Minimum',
            Actual: 'Tatsächlich',
        },
        Penetration: {
            WidgetTitle: 'Penetration',
            SubTitle: 'Penetration',
            YAxisTitle: 'Penetration (mm)',
            Maximum: 'Maximal',
            Minimum: 'Minimum',
            Actual: 'Tatsächlich',
        },
        Voltage: {
            WidgetTitle: 'Stromspannung',
            SubTitle: 'Stromspannung',
            YAxisTitle: 'Stromspannung (V)',
            Maximum: 'Maximal',
            Minimum: 'Minimum',
            Actual: 'Tatsächlich',
        },
        WeldTime: {
            WidgetTitle: 'Schweißzeit',
            SubTitle: 'Schweißzeit',
            YAxisTitle: 'Zeit (ms)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Tatsächlich',
        },
        FaultReport: { WidgetTitle: 'Fault Report', SubTitle: 'Fault Report' },
        TopFaultCount: {
            WidgetTitle: 'Höchste Fehleranzahl',
            SubTitle: 'Höchste Fehleranzahl',
            TableColumnDeviceName: 'AFO',
            TotalResult: 'Summes',
        },
        DurationOfFaults: {
            WidgetTitle: 'Dauer des Ereigniscodes',
            Fault: 'Störung',
            xAxisLabel: 'Ereignisnummer',
            yAxisLabel: 'Dauer der Veranstaltung Nummer',
        },
        TaAnalysisTable: {
            WidgetTitle: 'TA Analyse-Tabelle',
            SubTitle: 'TA Analyse-Tabelle',
            TableDeviceName: 'Gerätename Ausgang / Störungscode',
            TableTitle: 'TA Für Gerätename & Ausgang',
        },
        MtbfAnalysisTable: {
            WidgetTitle: 'MtbfAnalysisTable Widget',
            SubTitle: 'MtbfAnalysisTable Widget',
            TableDeviceName: 'Gerätename Ausgang / Störungscode',
            TableTitle: 'Mittlere Zeit zwischen Störungsereignissen',
        },
        TtrAnalysisTable: {
            WidgetTitle: 'TTR Analyse-Tabelle',
            SubTitle: 'TA Analyse-Tabelle',
            TableDeviceName: 'Gerätename Ausgang / Störungscode',
            TableTitle: 'TA Für Gerätename & Ausgang',
        },
        PlantDeviceByDuration: {
            WidgetTitle: 'Top 10 Devices with Longest Duration',
            SubTitle: 'Top 10 Devices with Longest Duration',
        },
        DReportWidget: {
            WidgetTitle: 'DReportWidget Widget', SubTitle: 'DReportWidget Widget',
            PrimaryYAxisTitle: 'Zyklen',
            SecondaryYAxisTitle: 'Quote %',
            ChartTitle: 'Verlauf',
            Target: 'Ziel',
            Event_20002: 'Quote Ausspucker',
            Event_20028: 'Quote Klemmer',
            Event_17068: 'Quote Ladestift',
        },
    },
    HomePage: {
        PageTitle: 'Startseite',
        Title: 'Startseite',
        Last_Updated: 'Zuletzt aktualisiert',
        Risk: 'Risiko',
        Target: 'Ziel',
        TechnicalAvail: 'TA',
        FaultTrend: 'Fehlertrend',
    },
    SignIn: {
        PageTitle: 'Anmelde',
        Title: 'Anmelden',
        Email: {
            Placeholder: 'E-Mail',
        },
        Password: {
            Placeholder: 'Passwort',
        },
        LoggedIn: 'Halten Sie mich angemeldet',
        LoginButton: {
            Label: 'Anmelden',
            Loading: 'Laden...',
        },
        ForgetUsernameOrPassword: 'Benutzername / Passwort Vergessen',
        NewUser: 'Neuer Benutzer',
        Error: {
            ValidEmailRequired: 'Erforderlich: Bitte geben Sie eine gültige E-Mail-Adresse.',
            ValidPasswordRequired: 'Erforderlich: Bitte geben Sie ein gültiges Passwort.',
        },
    },
    NavProfile: {
        UserAvatarAltMessage: 'User Avatar',
    },
    PanelScreenPage: {
        PageTitle: '9 Panel - Alt',
        Title: 'Fortschritt auf einen Blick',
    },
    ProjectsPage: {
        PageTitle: 'Projekte',
        Title: 'Fortschritt auf einen Blick',
    },
    ReportingViewPage: {
        PageTitle: 'Ursachenanalyse',
        Title: 'Ursachenanalyse',
        Table: {
            StudType: 'Bolzen',
            DeviceName: 'AFO',
            StudId: 'BolzenID',
        },
        DatePicker: {
            ANNUALLY: '[Jahr] YYYY',
            WEEKLY: '[Woche] W',
            DAILY: 'ddd',
            HOURLY: 'YYYYMMDD HH:MM:ss',
        },
        FileUpload: 'Datei hochladen',
        ExportToExcel: 'Exportieren in eXcel',
        ExportToPdf: 'Exportieren in PDF',
        FaultAssignment: 'Fehlerzuordnung',
        FaultAssignmentVal: 'Aktiv',
        StatusB: 'Status B',
        StatusBVal: 'Leer',
        Event: 'Ereignis',
        EventVal: 'Störung',
        SelectFaultCode: 'Select Störung #:',
        FilterByFaultCode: 'Filter By Störung #:',
        FilterByStudType: 'Filter By Bolzen',
        FilterByDeviceName: 'Filter By AFO',
        FilterByStudID: 'Filter By Bolzen ID',
        RecommendedActionTitle: 'Maßnahmen',
        InformationPlaceholder: 'Informationstitel Platzhalter',
        AddCommentsTitle: 'Kommentare hinzufügen (optional):',
        AddCommentsBtn: 'Einreichen.',
        History: 'Historie',
        Close: 'Schließen',
        filterBy: 'Filter By',
        ParentLabelPage: {
            Year: 'Jahr',
            Week: 'Woche',
            Day: 'Tag',
            Hour: 'Stunde',
        },
        NoData: 'Es werden keine Daten angezeigt',
        DataUptodate: 'Daten sind aktuell. Letzte Aktualisierung',
        FaultCountSum: 'Fehlerzählsumme',
    },
    ReportingViewPageB: {
        PageTitle: 'Berichtsansicht',
        Title: 'Berichtsansicht',
        Table: {
            Fault: 'Störung',
            Description: 'Beschreibung',
        },
        FileUpload: 'Datei hochladen',
        ExportToExcel: 'Exportieren in eXcel',
        ExportToPdf: 'Exportieren in PDF',
        FaultAssignment: 'Fehlerzuordnung',
        FaultAssignmentVal: '(Mehrere Elemente)',
        StatusB: 'Status B',
        StatusBVal: 'Leer',
        Event: 'Ereignis',
        EventVal: 'Störung',
        SelectFaultCode: 'Select Storung #:',
        FilterByStudType: 'Filter By Bolzen',
        FilterByDeviceName: 'Filter By AFO',
        FilterByStudID: 'Filter By Bolzen ID',
        WeekDay: 'Wochentag',
        All: 'Alle',
        DeviceName: 'AFO',
        ThresholdRedMarkingNote: ' {{threshold}} Fehlern pro Tag',
        StudType: 'Bolzen',
        OverAllResult: 'Gesamtergebnis',
        Sunday: 'Sonntag',
        Monday: 'Montag',
        Tuesday: 'Dienstag',
        Wednesday: 'Mittwoch',
        Thursday: 'Donnerstag',
        Friday: 'Freitag',
        Saturday: 'Samstag',
        Fault: 'Störung',
        Info: 'Info',
        ComponentExchange: 'Komponententausch',
        FirmwareUpdate: 'Firmware Update',
        Maintainance: 'Wartung',
        Active: 'Aktiv',
        // Events: 'Störung, Info, Komponententausch, Firmware Update, Wartung',
        Events: 'Störung',
        Year: 'Jahr',
        Week: 'Woche',
        PDFTitle: 'Täglicher Fehlerbericht',
        PDFFileName: 'Fehler_Report',
        ExpandTooltip: 'Fehler anzeigen Cycle Ratio & WOPs',
        CollapeTooltip: 'Fehlerzähler anzeigen',
    },
    SummaryDashboard: {
        PageTitle: 'Zusammenfassung Dashboard',
        Title: 'Zusammenfassung Dashboard auf einen Blick',
    },
    PlantLevelKpi: {
        PageTitle: 'Zusammenfassung Dashboard auf einen Blick',
        Title: 'Zusammenfassung Anlagen-KPIs im Überblick',
    },
    LabAnalysis: {
        PageTitle: 'Labordaten-KPI',
        Title: 'Labordaten-KPI',
    },
    Sidebar: {
        PageTitle: 'Seitenleiste',
        Title: 'Seitenleiste Seite',
        NinePanel: 'Neun Panel',
        Home: 'Startseite',
        Projects: 'Projekt',
        ReportingView: 'Berichtsansicht',
        ReportingViewB: 'Berichtsansicht B',
        SummaryDashboard: 'Zusammenfassendes Dashboard',
        PlantKPI: 'Werk KPI',
        Analytics: 'Analytics',
        LabAnalysis: 'Analyse',
        DashboardKPIs: 'Dashboard KPIs',
        FileUpload: 'Datei hochladen',
        Api: 'ELU API',
        Logout: 'Ausloggen',
    },
    FileUpload: {
        PageTitle: 'FilDatei hochladeneUpload',
        Title: 'Dateiupload-Seite',
        DropzoneText: 'Dateien zum Hochladen ablegen',
        UploadCyclesLabel: 'Wip/Wop XML hochladen',
        UploadSytemEventsLabel: 'Ereignis-Speicher XML hochladen',
        UploadMaintenanceLabel: 'Wartung des XML-Uploads',
        Back: 'Zurück',
        Finish: 'Fertig',
    },
    SystemOverview: {
        PageTitle: 'Flottenübersicht',
        Title: 'Flottenübersicht',
        TableColumnFault: 'Gerätename / Outlet',
    },
    PlantOverview: {
        PageTitle: 'Anlagenübersicht',
        Title: 'Anlagenübersicht',
        NavBarTitle: 'APPs',
        MaintenanceTitle: 'Berichtsansicht',
        MaintenanceSubTitle: 'Wartungsanalyse',
        SystemTitle: 'Flottenübersicht',
        SystemSubTitle: 'System Zustandsanalyse',
        UploadTitle: 'Hochladen',
        UploadSubTitle: 'Datei-Upload',
        TotalCycles: 'Wöchentliche Zykluszählung',
        PreviousDay: 'Vorheriger tag',
        PreviousWeek: 'Vorherige Woche',
        Week: 'Wochen',
        YAxisFaultFrequencyTitle: 'Anzahl der Ereignisnummern',
        XAxisFaultFrequencyTitle: 'Ereignisnummer',
        SystemHealthCard: 'Systemzustands Karte',
        Change: 'Änderung',
        New: 'Neu',
        NoChange: 'Keine Änderung',
        FaultFrequencyChartTitle: 'Top {{topX}} Häufigkeit des Ereigniscodes',
        FaultByOccurrence: 'Top {{topX}} Fehler mit der höchsten Häufigkeit',
        FaultByDuration: 'Top {{topX}} Störungen mit der längsten Dauer',
        SystemWithFaults: 'Top {{topX}} Geräte mit der höchsten Fehlerhäufigkeit',
        StudTypeWithFaults: 'Top {{topX}} Bolzentypen mit der höchsten Fehlerquote',
        DeviceByDuration: 'Top {{topX}} Geräte mit der längsten Laufzeit',
        Fault: 'Störung',
        Occurrences: 'Vorkommen',
        Duration: 'Dauer (mins)',
        Position: 'Position',
        System: 'System',
        StudType: 'Bolzen Type',
        TotalCyclesTooltip: 'in der Woche',
        TechnicalAvailability: 'Technische Verfügbarkeit',
        MeanTimeToRepair: 'Mittlere Reparaturzeit',
        MeanTimeBetweenFailure: 'Mittlere Zeit zwischen Ausfällen',
        MeanTimeToFailure: 'Mittlere Zeit bis zum Ausfall',
        Ratio: 'Quote',
        Cycle: 'Zyklen',
        CurrentWeekText: 'Aktuelle Woche',
        PercentageChange: '% Veränderung',
    },
    ProcessLogView: { PageTitle: 'Diagnoseprotokoll', Title: 'Diagnoseprotokoll', BtnBarDateFilter: 'Historischen' },
    ParetoAnalysis: {
        PageTitle: 'Pareto-Analyse',
        Title: 'Pareto-Analyse ',
        EventDescriptionChartTitle: 'Häufigkeit der Ereignisbeschreibung',
        EventDescriptionChartXAxisLabel: 'Anzahl der Ereignisnummern',
        EventDescriptionChartYAxisLabel: 'Beschreibung',
        EventCodeChartTitle: 'Häufigkeit des Ereigniscodes',
        EventCodeChartXAxisLabel: 'Ereignisnummer',
        EventCodeChartYAxisLabel: 'Anzahl der Ereignisnummern',
        EventCodeChartSecondaryYAxisLabel: 'Prozentsatz',
    },
    FailureRateTrend: {
        PageTitle: 'Ausfallratentrend',
        Title: 'Ausfallratentrend ',
        // EventRatePerEventChartTitle: 'Ereignisrate pro Ereignisnummer pro Woche 2021',
        EventRatePerEventChartTitleDaily: `Ereignisrate pro Ereignisnummer pro Tag 2021`,
        EventRatePerEventChartTitleWeekly: `Ereignisrate pro Ereignisnummer pro Woche 2021`,
        EventRatePerDeviceTitleDaily: 'Ereignisrate pro Gerät pro Tag 2021',
        EventRatePerDeviceTitleWeekly: 'Ereignisrate pro Gerät pro Woche 2021',
        EventRatePerEventXAxisLabelDaily: 'Day',
        EventRatePerEventXAxisLabelWeekly: 'Woche',
        EventRatePerEventYAxisLabel: 'Ereignisrate ( Teil pro Million )',
        Fault: 'Störung',
    },
    FaultCountTrend: { PageTitle: 'Fehlerzählungs-Trend', Title: 'Fehlerzählungs-Trend ' },
    OpportunityAnalysis: { PageTitle: 'Opportunity-Analyse', Title: 'Opportunity-Analyse ' },
    RootCauseVisibility: { PageTitle: 'Root Cause Visibility', Title: 'Root Cause Visibility ' },
    EssentialControlChart: { PageTitle: 'Wesentlich Kontrollkarte', Title: 'Wesentlich Kontrollkarte' },
    MtbfAnalysis: {
        PageTitle: 'Analyse der mittleren Zeit zwischen zwei Ausfällen',
        Title: 'Analyse der mittleren Zeit zwischen zwei Ausfällen',
    },
    MttrAnalysis: { PageTitle: 'Mean Time To Repair Analysis', Title: 'Mean Time To Repair Analysis ' },
    TaAnalysis: { PageTitle: 'Analyse der technischen Verfügbarkeit', Title: 'Analyse der technischen Verfügbarkeit' },
    TtrAnalysis: { PageTitle: 'Time To Repair Analysis', Title: 'Time To Repair Analysis ' },
    EventOccurrence: { PageTitle: 'Event Occurrence', Title: 'Event Occurrence ' },
    TopXFaultCountAnalysis: { PageTitle: 'Faults Per AFO', Title: 'Faults Per AFO' },
    FaultRateForecast: { PageTitle: 'Fault Rate Forecast', Title: 'Fault Rate Forecast ' },
    CarBodyAnalysis: {
        PageTitle: 'Karosserie-Analyse',
        Title: 'Karosserie-Analyse',
    },
    SettingsPage: {
        PageTitle: 'Settings',
        Operation: 'Betrieb',
        AddNew: 'Neu hinzufügen',
        Shifts: 'Schichten',
        Breaks: 'Pausen',
        ShiftName: 'Name der Schicht',
        BreakName: 'Name der Pause',
        TimeFrom: 'Zeit von',
        TimeTo: 'Zeit für',
        DateFrom: 'Datum von',
        DateTo: 'Datum bis',
        Delete: 'löschen',
        Edit: 'Bearbeiten',
        Actions: 'Aktionen',
    },
};

export default translations;
