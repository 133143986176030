/**
 *
 * RiskItem
 *
 */

import RiskItem from './RiskItem';

export { RiskItem };

export default RiskItem;
