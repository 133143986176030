/**
 *
 * Table
 *
 */

import Table from './Table';

export { Table };

export default Table;
