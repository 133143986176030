/**
 *
 * SideFilterPanel
 *
 */

import SideFilterPanel from './SideFilterPanel';

export { SideFilterPanel };

export default SideFilterPanel;
