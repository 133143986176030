/**
 *
 * EventDescFrequency
 *
 */

import EventDescFrequency, { EventDescFrequencyWidgetProperty } from './EventDescFrequency';

export { EventDescFrequency, EventDescFrequencyWidgetProperty };

export default EventDescFrequency;
