export const data: string[] = [
    '5089a56f-f031-4c92-86a9-320b75de8b51',
    'ccc2c15c-77a9-4cb8-a35c-cb5927a49401',
    '5c15bf89-712f-40e5-984b-07d5edf5faa1',
    '9269bbfc-f22a-4af2-81d0-312252cedef5',
    '71b6bfde-3167-4d62-b6d0-512ac3f4b8e9',
    'a6758306-d14f-4697-bfc7-79bedbe77953',
    '83b4d5d7-4252-4eff-890a-89fd48f105f7',
    '31a807ff-5947-48a7-8a6b-546b8c632f81',
    '9fbd0c24-44a9-4f45-b7f7-07d5b7168148',
    'ef659d21-385a-4ba4-ad12-af5cceb4d5c7',
    '86010188-1bef-4dc6-958a-cf35896b3bcc',
    'dc1ad24c-b6eb-4c7c-9d91-bcb6977db4ee',
    '0b77f9cf-5394-471a-bec0-981b0a13f640',
    'c7070138-afd2-442d-8a30-c3f0990b8fdf',
    'ed333d52-2aee-4b1c-a52a-fc9e0821d37d',
    'c3651909-0a41-40cf-91e9-56b93af7eac9',
    '063552aa-2b98-4f03-b9d3-0c49b57b1a61',
    '66b5385c-cdd4-4dc4-b5bb-c986437a8e70',
    'c5beb73a-b952-40bb-838e-04d1a762a38f',
    '280c43a2-40ab-45c9-b1b5-b2d0b65ff557',
    '3e1d3b6d-1bb2-4aac-9097-666e5aaa63cd',
    '0c50dd36-6d76-4840-b619-5e8b52a697bc',
    'ef2f6c60-0a12-413e-828c-a06c03aca426',
    'e788494f-5275-4956-aa4b-29aae4c352b1',
    '71150526-2f4e-4c67-aa24-4b09a1fe45b4',
    '7c67ce19-95a0-47e3-9e79-31dee793c9d6',
    'da18c830-d501-4022-a7a4-afddba8434da',
    '4d5cb944-c7ce-4c67-b4ff-0572a4f74b8b',
    '91fdf3a3-40a7-459d-91f6-2b43ba44b027',
    'c4a2f477-f05a-4811-9e11-a364647fc784',
    '5c3a5c1c-ac93-4b9f-bb43-2cd20e9fcb79',
    '9576129e-a5ac-4eef-a9ef-d6d385112b13',
    'aabb3b11-c252-4151-a9e8-97f2e48cb090',
    'c44c7782-8383-4ae9-aece-adecf0e59c2d',
    'c6e871b9-f13e-4c2d-a503-5f30053f449e',
    '2f9e1660-0965-4ed6-a5d3-5606578b715b',
    '59ceec07-e3cc-426c-b503-64be7bba5d6f',
    '70b8b208-76cb-429c-93f0-1861cf5a3dc1',
    'ae21d34c-ede8-462a-a8e6-c11803432cb9',
    'cc8e9c0e-44a1-4cfe-8f0b-ec3dcae8bcba',
    'd99c8828-036a-40a0-912d-b1e54458aa8f',
    'db9af827-b361-44e8-9b27-40de0eb089a2',
    'dc99e9c9-90b6-4fbd-a550-bc3d21a285d8',
    '5321bc50-37b3-4a37-bcb9-f512f70d9ebb',
    '791766ac-7947-4e36-92dd-02d2255c451a',
    'a3792b74-7fe6-42d7-8f24-dd5d9f44b10e',
    'c15ca10f-8a23-496b-936a-dd12676dbe24',
    'd82d88ef-8960-4354-8b85-8893de8f0f9d',
    'd8e1d0f7-35ef-4be1-ba83-755214a732a9',
    'ebbfd76f-3602-465e-aced-917afaa58355',
    'f0a0df80-a5f0-435f-9908-c13b57ebdb51',
    '4ac6fe04-a379-4049-8170-4a3aec04e082',
    '0da5b184-aa80-4f7e-927b-d466056a60a2',
    '0dc9c6c8-c6ae-4709-9ce6-4d78cc36aceb',
    '6583bf1e-0a50-44ea-a269-586aa4064c1a',
    'dd0067b4-0e10-4260-aa08-b105bfb52aff',
    '2d2f7f86-72c3-4cb4-aea5-dd84a247ca21',
    '562129e6-a3be-4dec-9952-e34f57563549',
    '6214cdb7-6a51-41e1-9a19-097cc87627e2',
    '7e5914cf-d5dc-445b-b972-d9ebb5d08b1d',
    'f1e5f152-cb0f-4b6b-b7f9-9384f3d5865a',
    '10cadf43-3238-447f-9a55-3d95209850a5',
    '3f404228-0086-4d04-987a-d23a79b8eb51',
    '5b7f7717-5b96-46ac-9edd-ffa41bc50905',
    '284a5d21-a1f0-4768-a820-ef13882f63cd',
    '7e5914cf-d5dc-445b-b972-d9ebb5d08b1d',
    'f1e5f152-cb0f-4b6b-b7f9-9384f3d5865a',
    '68d91b1a-768c-4054-8111-3a0a2b72d843',
];
export default data;
