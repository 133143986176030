export const translations = {
    i18nFeature: {
        title: 'i18n Internationalization & Pluralization',
        selectLanguage: 'Select Language',
        description:
            'Scalable apps need to support multiple languages, easily add and support multiple languages. Change the language below to see how instantly it updates the page without refreshing.',
    },
    Blank: {
        title: 'i18n खाली पेज',
    },
    General: {
        All: 'All',
        Plant: 'कारखाना: {{plantId}}',
        TimeInUnitsLabel: 'समय में',
    },
    Components: {
        NavNotification: {
            StatusInfo: 'सूचना',
        },
        DashboardFilterPanel: {
            Title: 'फ़िल्टर',
            Reset: 'रीसेट',
        },
        Search: {
            Placeholder: 'खोज',
        },
        UploadPanel: {
            dropzoneText: 'यहां फाइलें छोड़ दें.',
            Clear: 'क्लियर',
            Upload: 'अपलोड',
        },
        OverviewCard: {
            AverageTA: 'औसत टीए',
            FaultsText: 'दोष',
            CyclesText: 'चक्र',
            MTTRUnitsText: 'मिनट',
            MTTRText: 'एमटीटीआर',
        },
    },
    DashboardPowerBi: { PageTitle: 'DashboardPowerBi', Title: 'DashboardPowerBi Page' },
    Dashboard: { CycleGapTempo: 'साइकिल गैप टेम्पो', MeasurementTrends: 'Measurement Trends' },
    Filters: {
        // filter labels
        FilterByFaultCodeLabel: 'फॉल्ट कोड',
        FilterByStudTypeLabel: 'स्टड प्रकार',
        FilterByStudIdLabel: 'Sस्टड आईडी',
        FilterByDeviceNameLabel: 'डिवाइस का नाम',
        FilterByDeviceTypeLabel: 'डिवाइस प्रकार',
        FilterByBodyShopLabel: 'Atelier de carrosserie',
        FilterByDateLabel: 'खजूर',
        EventTypeLabel: 'इवेंट टाइप',
        LineLabel: 'Liक़तारें लगानाne',
        WeekLabel: 'कार्य-सप्‍ताह',
        FilterByReportThresholdLabel: 'रिपोर्ट सीमा',
        StationLabel: 'स्टेशन',
        FilterByAnomalyLabel: 'असंगति',
        FilterByAnomalyConfidenceLabel: 'विश्वास',
        SelectFilterLabel: 'छानना',
        // filter placeholders
        FilterByFaultCodePlaceholder: 'गलती कोड से फ़िल्टर करें',
        FilterByStudTypePlaceholder: 'स्टड प्रकार से फ़िल्टर करें',
        FilterByStudIdPlaceholder: 'स्टड आईडी द्वारा फ़िल्टर करें',
        FilterByDeviceNamePlaceholder: 'डिवाइस नाम से फ़िल्टर करें',
        FilterByDeviceTypePlaceholder: 'डिवाइस प्रकार के अनुसार फ़िल्टर करें',
        FilterByBodyShopPlaceholder: 'बॉडी शॉप द्वारा फ़िल्टर करें',
        FilterByDatePlaceholder: 'खजूर',
        EventTypePlaceholder: 'इवेंट टाइप द्वारा फ़िल्टर करें',
        FilterByLinePlaceholder: 'लाइन से फ़िल्टर करें',
        FilterByWeekPlaceholder: 'सप्ताह तक फ़िल्टर करें',
        FilterByReportThresholdPlaceholder: 'रिपोर्ट सीमा चुनें',
        FilterByStationPlaceholder: 'स्टेशन द्वारा फ़िल्टर करें',
        FilterByAnomalyPlaceholder: 'विसंगति से फ़िल्टर करें',
        FilterByAnomalyConfidencePlaceholder: 'आत्मविश्वास से फ़िल्टर करें',
        SelectFilterPlaceholder: 'चुनना',
    },
    Widgets: {
        NinePanelTable: {
            NoData: 'कोई डेटा नहीं',
        },
        OpportunityAnalysis: {
            WidgetTitle: 'अवसर विश्लेषण',
            SubTitle: 'अवसर विश्लेषण',
            ActionRecommended: 'कार्रवाई की सिफारिश की',
            ActionTaken: 'कार्रवाई की गई',
            CaseClosed: 'मामला बंद',
        },
        ProcessLog: {
            Title: 'डायग्नोस्टिक लॉग',
            SubTitle: 'डायग्नोस्टिक लॉग',
            AnomalyTypeLabel: 'प्रकार',
            AnomalyDeviceLabel: 'डिवाइस की जानकारी',
            AnomalyStationLabel: 'स्टेशन',
            AnomalyListLabel: 'असंगति',
            AnomalyConfidenceLabel: 'विश्वास',
            AnomalyTimeLabel: 'Time',
            AnomalyFeedbackLabel: 'प्रतिपुष्टि',
        },
        StationAnomaly: {
            Title: 'स्टेशन विसंगति सूची',
            SubTitle: 'स्टेशन विसंगति सूची',
            NoAnomaly: 'कोई नहीं',
            AnomalyFeedbackLabel: 'प्रतिपुष्टि',
            AnomalyMetadataLabel: 'मेटाडेटा',
        },
    },
    HomePage: {
        PageTitle: 'होम पेज',
        Title: 'होम पेज',
    },
    SignIn: {
        PageTitle: 'लॉगइन',
        Title: 'लॉग इन करें',
        Email: {
            Placeholder: 'ईमेल',
        },
        Password: {
            Placeholder: 'पासवर्ड',
        },
        LoggedIn: 'मुझे लॉग इन रखें',
        LoginButton: {
            Label: 'लॉग इन करें',
            Loading: 'लोड हो रहा है । ..',
        },
        ForgetUsernameOrPassword: 'उपयोगकर्ता नाम / पासवर्ड भूल जाओ',
        NewUser: 'नया उपयोक्ता',
        Error: {
            ValidEmailRequired: 'अपेक्षित: कृपया एक मान्य ईमेल पता प्रदान करें.',
            ValidPasswordRequired: 'अपेक्षित: कृपया एक मान्य पासवर्ड प्रदान करें.',
        },
    },
    NavProfile: {
        UserAvatarAltMessage: 'उपयोगकर्ता अवतार',
    },
    PanelScreenPage: {
        PageTitle: 'परियोजनाओं',
        Title: 'प्रगति एक नजर में',
    },
    ProjectsPage: {
        PageTitle: 'परियोजनाओं',
        Title: 'प्रगति एक नजर में',
    },
    ReportingViewPage: {
        PageTitle: 'हुबर्ट  रिपोर्टिंग  व्यू ',
        Title: 'हुबर्ट  रिपोर्टिंग  व्यू ',
        Table: {
            StudType: 'स्टड टाइप',
            DeviceName: 'यन्त्र का नाम',
            StudId: 'स्टडी आईडी',
        },
        DatePicker: {
            ANNUALLY: '[वष] YYYY',
            WEEKLY: '[कार्य-सप्‍ताह] W',
            DAILY: 'ddd',
            HOURLY: 'YYYYMMDD HH:MM:ss',
        },
        FileUpload: 'फाइल अपलोड',
        ExportToExcel: 'ईएक्ससेल को निर्यात',
        ExportToPdf: 'पीडीएफ को निर्यात',
        FaultAssignment: 'दोष कार्य',
        FaultAssignmentVal: '(कई तत्व)',
        StatusB: 'स्थिति बी',
        StatusBVal: 'खाली',
        Event: 'प्रतिस्पर्धा',
        EventVal: 'दोष',
        SelectFaultCode: 'दोष का चयन करें #:',
        FilterByFaultCode: 'Filter By Défaut #:',
        FilterByStudType: 'स्टड प्रकार से फ़िल्टर करें',
        FilterByDeviceName: 'डिवाइस द्वारा फ़िल्टर करें',
        FilterByStudID: 'फ़िल्टर स्टड आईडी द्वारा',
        RecommendedActionTitle: 'अनुशंसित क्रियाएँ',
        InformationPlaceholder: 'जानकारी शीर्षक प्लेसहोल्डर',
        AddCommentsTitle: 'टिप्पणियाँ जोड़ें (वैकल्पिक)',
        AddCommentsBtn: 'टिप्पणी करें',
        History: 'इतिहास',
        Close: 'क्लोज',
        filterBy: 'फ़िल्टर बी',
        ParentLabelPage: {
            Year: 'साल',
            Week: 'कार्य-सप्‍ताह',
            Day: 'दिन',
            Hour: 'घंटा',
        },
        NoData: 'कोई डेटा प्रदर्शित नहीं किया जाएगा',
    },
    SummaryDashboard: {
        PageTitle: 'सारांश डैशबोर्ड',
        Title: 'सारांश डैशबोर्ड एक नजर में',
    },
    PlantLevelKpi: {
        PageTitle: 'प्लांट केपीआई',
        Title: 'सारांश संयंत्र केपीआई एक नजर में',
    },
    LabAnalysis: {
        PageTitle: 'विश्लेषण',
        Title: 'विश्लेषण',
    },
    Sidebar: {
        PageTitle: 'साइडबार',
        Title: 'साइडबार पेज',
        NinePanel: 'नाइन पैनल',
        Home: 'होम',
        Projects: 'प्रोजेक्ट्स ',
        ReportingView: 'रिपोर्टिंग व्यू ',
        ReportingViewB: 'रिपोर्टिंग व्यू बी ',
        SummaryDashboard: 'समरी डैशबोर्ड ',
        PlantKPI: 'प्लांट कपि ी',
        Analytics: 'विश्लेषिकी',
        LabAnalysis: 'विश्लेषण',
        DashboardKPIs: 'डैशबोर्ड कपीस ',
        FileUpload: 'फाइल अपलोड',
        Api: 'ELU API',
        Logout: 'लॉगआउट',
    },
    FileUpload: {
        PageTitle: 'फाइल अपलोड',
        Title: 'फाइल अपलोड पेज',
        DropzoneText: 'अपलोड करने के लिए फ़ाइलें छोड़ दें',
        UploadCyclesLabel: 'माप/चक्र XML अपलोड करें',
        UploadSytemEventsLabel: 'अपलोड सिटेम इवेंट्स XML',
        UploadMaintenanceLabel: 'मेंटेनेंस एक्सएमएल अपलोड करें',
        Back: 'बैक',
        Finish: 'समाप्त',
    },
    SystemOverview: {
        PageTitle: 'फ्लीट अवलोकन',
        Title: 'फ्लीट अवलोकन',
        TableColumnFault: 'डिवाइस का नाम/आउटलेट',
    },
    ProcessLogView: { PageTitle: 'प्रोसेस लॉग', Title: 'प्रोसेस लॉग', BtnBarDateFilter: 'ऐतिहासिक' },
};

export default translations;
