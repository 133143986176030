/**
 *
 * SelectEventTypeFilter
 *
 */

import SelectEventTypeFilterNew from './SelectEventTypeFilter';

export { SelectEventTypeFilterNew };

export default SelectEventTypeFilterNew;
