/**
 *
 * UptimeDistribution
 *
 */

import UptimeDistribution, { UptimeDistributionProperty } from './UptimeDistribution';

export { UptimeDistribution, UptimeDistributionProperty };

export default UptimeDistribution;
