/**
 *
 * EventCountFrequencyWidget
 *
 */

import EventCountFrequencyWidget, { EventCountFrequencyWidgetProperty } from './EventCountFrequencyWidget';

export { EventCountFrequencyWidget, EventCountFrequencyWidgetProperty };

export default EventCountFrequencyWidget;
