/**
 *
 * Chart
 *
 */

import Chart from './Chart';

export { default as Chart } from './Chart';
export { default as WeldSignalChart } from './WeldSignalChart';

export default Chart;
