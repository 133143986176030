export const translations = {
    i18nFeature: {
        title: 'i18n Internationalization & Pluralization',
        selectLanguage: 'Select Language',
        description:
            'Scalable apps need to support multiple languages, easily add and support multiple languages. Change the language below to see how instantly it updates the page without refreshing.',
    },
    Blank: {
        title: 'i18n Blank',
    },
    General: {
        All: 'All',
        Colors: {
            Red: 'red',
        },
        Plant: 'Plant: {{plantId}}',
        TimeInUnitsLabel: 'Time in',
        SampleTime: 'Sample Time',
        Voltage: 'Voltage',
        LayoutLabel: 'Layout',
        faultCode: 'faultCode',
        studType: 'studType',
        deviceName: 'deviceName',
        Filters: 'Filters',
        EventTypeLabel: 'Event Type',
        NoData: 'No data to display',
        Home: 'Home',
        NoOptions: 'No Options',
        NoPlants: 'No plants to display',
    },
    ScreenName: {
        Home: 'Home',
        PlantOveriew: 'Plant Overview',
        FailureModeAnalysis: 'Failure Mode Analysis',
        ReportingViewB: 'Reporting View',
        FleetOverview: 'Fleet Overview',
        ParetoAnalysis: 'Pareto Analysis',
        FailureRateTrend: 'Failure Rate Trend',
        FaultCountTrend: 'Fault Count Trend',
        EssentialControlCharts: 'Essential Control Charts',
        CarBodyRisk: 'Car Body Risk',
        OpportunityAnalysis: 'Opportunity Analysis',
        CarbodyDurationPerStation: 'Car Body Duration Per Station',
        CycleMeasurementCombo: 'Cycle Measurement Combo',
        MeasurementTrends: 'Measurement Trends',
        Measurement: 'Measurement',
        TaAnalysis: 'TA Analysis',
        MttrAnalysis: 'MTTR Analysis',
        MtbfAnalysis: 'MTBF Analysis',
        TtrAnalysis: 'TTR Analysis',
        MaintainanceAction: 'Maintainance Action',
        StationAvailability: 'Station Availability',
        DailyFaultTrends: 'Daily Fault Trends',
        StationAvailabilityTrends: 'Station Availability Trends',
        GraphicDataAnalysis: 'Graphic Data Analysis',
        CycleGap: 'Cycle Gap',
        CycleGapSPR: 'Cycle Gap SPR',
        DiagnosticView: 'Diagnostic View',
        FaultsPerDevice: 'Faults Per Device',
        DReport: 'D-Report',
    },
    Components: {
        NavNotification: {
            StatusInfo: 'Notification',
        },
        DashboardFilterPanel: {
            Title: 'Filters',
            Reset: 'reset',
        },
        DeviceExpand: {
            deviceName: 'Device Name',
            health: 'HEALTH',
            cycles: 'CYCLES',
            faults: 'FAULTS',
            mtbf: 'MTBF',
            mttr: 'MTTR',
            ta: 'TA',
        },
        Search: {
            Placeholder: 'Search',
        },
        UploadPanel: {
            dropzoneText: 'Drop files here.',
            Clear: 'Clear',
            Upload: 'Upload',
        },
        OverviewCard: {
            AverageTA: 'TA',
            FaultsText: 'Faults',
            CyclesText: 'Cycles',
            MTTRUnitsText: 'min',
            MTBFUnitsText: 'hr',
            MTTRText: 'MTTR',
            MTBFText: 'MTBF',
        },
        OperationsForm: {
            AddNew: 'Add New',
            Shift: 'Shift',
            Break: 'Break',
            Time: 'Time',
            Date: 'Date',
            To: 'To',
            Cancel: 'Cancel',
            Add: 'Add',
            Hour: 'Hour',
            Minutes: 'Minutes',
        },
        RulesPanel: {
            Title: 'Create rule',
            NewRule: 'Create new rule',
            SavedRules: 'Saved rules',
            Saved: 'saved',
        },
        // [INSERT NEW COMPONENT EN TITLE KEY ABOVE] < Needed for generating containers language seamlessly
    },
    DashboardPowerBi: { PageTitle: 'Dashboard Power BI', Title: 'Dashboard Power BI' },
    Dashboard: {
        CycleGapTempo: 'Cycle Gap Tempo',
        MeasurementTrends: 'Measurement Trends',
        DailyFaultTrends: 'Daily Fault Trends',
        StationAvailabilityReport: 'Station Availability Report',
        StationAvailabilityTrend: 'Station Availability Trend',
        GraphicDataAnalysis: 'Graphic Data Analysis',
        CarbodyDuration: 'Car Body Duration',
        CarbodyDurationPerStation: 'Car Body Duration Per Station',
    },
    Filters: {
        FilterByEventTypeLabel: 'Event Type',
        FilterByEventTypePlaceholder: 'Filter By Event Type',
        // filter labels
        FilterByFaultCodeLabel: 'Event Code',
        FilterByStudTypeLabel: 'Stud Type',
        FilterByStudIdLabel: 'Stud ID',
        FilterByDeviceNameLabel: 'Device Name',
        FilterByDeviceTypeLabel: 'Device Type',
        FilterByBodyShopLabel: 'Body Shop',
        FilterByDateLabel: 'Date',
        EventTypeLabel: 'Event Type',
        LineLabel: 'SubLine',
        WeekLabel: 'Week',
        FilterByReportThresholdLabel: 'Report Threshold',
        StationLabel: 'Station',
        FilterByAnomalyLabel: 'Anomaly',
        FilterByAnomalyConfidenceLabel: 'Confidence',
        SelectFilterLabel: 'Filter',
        WeekDayChange: 'Week or Day',
        CarBodyIDLabel: 'CarBody',
        EngineLabel: 'Engine',
        CarModelLabel: 'Car Model',
        CarTypeLabel: 'Car Type',
        DirectionLabel: 'Direction',
        OutletLabel: 'Outlet',
        FeederLabel: 'Feeder',
        Program: 'Program',
        AggregateColumn: 'Aggregate Column',
        // filter placeholders
        FilterByFaultCodePlaceholder: 'Filter By Event Code',
        FilterByStudTypePlaceholder: 'Filter By Stud Type',
        FilterByStudIdPlaceholder: 'Filter By Stud Id',
        FilterByDeviceNamePlaceholder: 'Filter By Device Name',
        FilterByDeviceTypePlaceholder: 'Filter By Device Type',
        FilterByBodyShopPlaceholder: 'Filter By BodyShop',
        FilterByDatePlaceholder: 'Date',
        EventTypePlaceholder: 'Filter By Event Type',
        FilterByLinePlaceholder: 'Filter By SubLine',
        FilterByWeekPlaceholder: 'Filter By Week',
        FilterByReportThresholdPlaceholder: 'Select Report Threshold',
        FilterByStationPlaceholder: 'Filter By Station',
        FilterByAnomalyPlaceholder: 'Filter By Anomaly',
        FilterByAnomalyConfidencePlaceholder: 'Filter By Confidence',
        FilterByCarBodyIDPlaceholder: 'Filter By CarBody',
        FilterByEnginePlaceholder: 'Filter By Engine',
        FilterByCarModelPlaceholder: 'Filter By Car Model',
        FilterByCarTypePlaceholder: 'Filter By Car Type',
        FilterByDirectionPlaceholder: 'All',
        SelectFilterPlaceholder: 'Select',
        Fault: 'Fault',
        Warning: 'Warning',
        Weekly: 'Weekly',
        FirmwareUpdate: 'Firmware Update',
        Info: 'Info',
        Componentexchange: 'Component Replacement',
        Maintenance: 'Maintenance',
        Daily: 'Daily',
        Hourly: 'Hourly',
        View: 'View',
        Voltage: 'Voltage',
        Current: 'Current',
        LiftPosition: 'Lift Position',
        GroupBy: ' Group By',
        All: 'ALL',
        SystemFaults: 'System Faults',
        HeadType: 'Head Type',
        AggregateType: 'AggregateType',
        AggregateNeeded: 'AggregateNeeded',
    },
    OpportunityAnalysis: {
        PageTitle: 'Opportunity Analysis',
        Title: 'Opportunity Analysis',
    },
    Widgets: {
        NinePanelTable: {
            NoData: 'no data',
        },
        ProcessLog: {
            Title: 'Diagnostic Log',
            SubTitle: 'Diagnostic Log',
            AnomalyTypeLabel: 'Type',
            AnomalyDeviceLabel: 'Device Information',
            AnomalyStationLabel: 'Station',
            AnomalyListLabel: 'Anomaly',
            AnomalyConfidenceLabel: 'Confidence',
            AnomalyTimeLabel: 'Time',
            AnomalyFeedbackLabel: 'Feedback',
            studId: 'Stud Id',
        },
        StationAnomaly: {
            Title: 'Station Anomaly List',
            SubTitle: 'Station Anomaly List',
            NoAnomaly: 'None',
            AnomalyFeedbackLabel: 'Feedback',
            AnomalyMetadataLabel: 'Metadata',
        },
        EventCodeFrequency: {
            Title: 'Event Code Frequency Widget',
            WidgetTitle: 'Event Code Frequency Widget',
            SubTitle: 'Event Code Frequency Widget',
            FaultFrequencyChartTitle: 'Frequencies Of Event Code',
            YAxisFaultFrequencyTitle: 'Count Of Event Number',
            XAxisFaultFrequencyTitle: 'Event Number',
            Fault: 'Fault',
            FaultCount: 'Number of Faults',
        },
        EventDescFrequency: {
            Title: 'Frequency Event Description Widget',
            WidgetTitle: 'Event Desc Frequency Widget',
            SubTitle: 'EventDescFrequency Widget',
            FaultFrequencyChartTitle: 'Frequencies of Event Description 2021',
            YAxisFaultFrequencyTitle: 'Description',
            XAxisFaultFrequencyTitle: 'Count of Event Number',
            Fault: 'Fault',
        },
        EventCountFrequencyWidget: {
            Title: 'Event Count Frequency widget',
            WidgetTitle: 'EventCountFrequencyWidget Widget',
            SubTitle: 'EventCountFrequencyWidget Widget',
            FaultFrequencyCountTitle: 'Event Count by Week',
            YAxisFaultFrequencyCountTitle: 'Event Count',
            XAxisFaultFrequencyCountTitle: 'Week',
        },
        EventCountFrequencyWidgetSpr: {
            Title: 'Event Count Frequency widget',
            WidgetTitle: 'EventCountFrequencyWidget Widget',
            SubTitle: 'EventCountFrequencyWidget Widget',
            FaultFrequencyCountTitle: 'Event Count by Week',
            YAxisFaultFrequencyCountTitle: 'Event Count',
            XAxisFaultFrequencyCountTitle: 'Week',
        },
        EventCountDailyFrequency: {
            Title: 'Event Count Frequency',
            WidgetTitle: 'EventCountFrequencyWidget',
            SubTitle: 'EventCountFrequencyWidget Widget',
            FaultFrequencyCountTitle: 'Event Count By Day',
            YAxisFaultFrequencyCountTitle: 'Event Count',
            XAxisFaultFrequencyCountTitle: 'Date',
        },
        EventCountDailyFrequencySpr: {
            Title: 'Event Count Frequency',
            WidgetTitle: 'EventCountFrequencyWidget',
            SubTitle: 'EventCountFrequencyWidget Widget',
            FaultFrequencyCountTitle: 'Event Count By Day',
            YAxisFaultFrequencyCountTitle: 'Event Count',
            XAxisFaultFrequencyCountTitle: 'Date',
        },
        OpportunityAnalysisWidget: {
            WidgetTitle: 'Opportunity Analysis',
            SubTitle: 'Opportunity Analysis',
            ActionRecommended: 'Action Recommended',
            ActionTaken: 'Action Taken',
            CaseClosed: 'Case Closed',
        },
        EventRateCycleCount: {
            WidgetTitle: 'EventRateCycleCount Widget',
            SubTitle: 'EventRateCycleCount Widget',
            EventRateCycleCountitleDaily: 'Cycle count and Event Rate Percentage By Day',
            EventRateCycleCountitleWeekly: 'Cycle count and Event Rate Percentage By Week',
            YAxisEventRateCycleCountTitle: 'Cycle Count',
            SecondaryYAxisEventRateCycleCountTitle: 'Event Rate',
            XAxisEventRateCycleCountTitleWeekly: 'Week',
            XAxisEventRateCycleCountTitleDaily: 'Day',
            EventRateCycleCountChartTitleDaily: 'Cycle count and Event Rate Percentage per Day {{yearLabel}}',
            EventRateCycleCountWeekly: 'Cycle count and Event Rate Percentage per Week {{yearLabel}}',
        },
        EventRateCycleCountSpr: {
            WidgetTitle: 'EventRateCycleCount Widget',
            SubTitle: 'EventRateCycleCount Widget',
            EventRateCycleCountitleDaily: 'Cycle count and Event Percentage By Day',
            EventRateCycleCountitleWeekly: 'Cycle count and Event Percentage By Week',
            YAxisEventRateCycleCountTitle: 'Cycle Count',
            SecondaryYAxisEventRateCycleCountTitle: 'Event Rate',
            XAxisEventRateCycleCountTitle: 'Week',
        },
        EventRatePerEvent: {
            WidgetTitle: 'EventRatePerEvent Widget',
            SubTitle: 'EventRatePerEvent Widget',
            Fault: 'Fault',
            Warning: 'Warning',
        },
        EventRatePerEventSpr: {
            WidgetTitle: 'EventRatePerEvent Widget',
            SubTitle: 'EventRatePerEvent Widget',
            Fault: 'Fault',
            Warning: 'Warning',
        },
        EventRatePerDevice: {
            WidgetTitle: 'EventRatePerDevice Widget',
            SubTitle: 'EventRatePerDevice Widget',
            EventRatePerDeviceTitle: 'Event Rate per Device by Week 2021',
            EventRatePerBodyshopTitle: 'Event rate for',
            EventRateByDay: 'by Day',
            EventRateByWeek: 'by Week',
            YAxisEventRatePerDeviceTitle: 'Event Rate ( Part Per Million )',
            XAxisEventRatePerDeviceTitleWeekly: 'Week',
            XAxisEventRatePerDeviceTitleDaily: 'Day',
            Fault: 'Fault',
        },
        EventRatePerDeviceSpr: {
            WidgetTitle: 'EventRatePerDevice Widget',
            SubTitle: 'EventRatePerDevice Widget',
            EventRatePerDeviceTitle: 'Event Rate per Device by Week 2021',
            EventRatePerBodyshopTitle: 'Event rate for',
            EventRateByDay: 'by Day',
            EventRateByWeek: 'by Week',
            YAxisEventRatePerDeviceTitle: 'Event Rate ( Part Per Million )',
            XAxisEventRatePerDeviceTitleWeekly: 'Week',
            XAxisEventRatePerDeviceTitleDaily: 'Day',
            Fault: 'Fault',
        },
        Lift: {
            WidgetTitle: 'Lift',
            SubTitle: 'Lift',
            YAxisTitle: 'Lift (mm)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Actual',
        },
        Penetration: {
            WidgetTitle: 'Penetration',
            SubTitle: 'Penetration',
            YAxisTitle: 'Penetration (mm)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Actual',
        },
        Voltage: {
            WidgetTitle: 'Voltage',
            SubTitle: 'Voltage',
            YAxisTitle: 'Voltage (V)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Actual',
        },
        WeldTime: {
            WidgetTitle: 'Welding Time',
            SubTitle: 'Welding Time',
            YAxisTitle: 'Time (ms)',
            Maximum: 'Maximum',
            Minimum: 'Minimum',
            Actual: 'Actual',
        },
        FaultReport: { WidgetTitle: 'Fault Report', SubTitle: 'Fault Report' },
        TopFaultCount: {
            WidgetTitle: 'Top Fault Count',
            SubTitle: 'Top Fault Count',
            TableColumnDeviceName: 'Device Name',
            TotalResult: 'Sum',
        },
        PlantCycleAverages: { WidgetTitle: 'PlantCycleAverages Widget', SubTitle: 'PlantCycleAverages Widget' },
        PlantAppNavBar: { WidgetTitle: 'PlantAppNavBar Widget', SubTitle: 'PlantAppNavBar Widget' },
        PlantHighestOccurenceFaults: {
            WidgetTitle: 'PlantHighestOccurenceFaults Widget',
            SubTitle: 'PlantHighestOccurenceFaults Widget',
        },
        PlantFaultByOccurence: {
            WidgetTitle: 'PlantFaultByOccurence Widget',
            SubTitle: 'PlantFaultByOccurence Widget',
        },
        PlantFaultByDevices: { WidgetTitle: 'PlantFaultByDevices Widget', SubTitle: 'PlantFaultByDevices Widget' },
        PlantFaultByStudType: { WidgetTitle: 'PlantFaultByStudType Widget', SubTitle: 'PlantFaultByStudType Widget' },
        PlantFaultByDuration: { WidgetTitle: 'PlantFaultByDuration Widget', SubTitle: 'PlantFaultByDuration Widget' },
        PlantCycleCount: { WidgetTitle: 'PlantCycleCount Widget', SubTitle: 'PlantCycleCount Widget' },
        PlantFaultFrequency: { WidgetTitle: 'PlantFaultFrequency Widget', SubTitle: 'PlantFaultFrequency Widget' },
        DurationOfFaults: {
            WidgetTitle: 'Duration of Event Code',
            Fault: 'Fault',
            xAxisLabel: 'Event Number',
            yAxisLabel: 'Duration Of event Number',
        },
        CarBodyGraph: { WidgetTitle: 'CarBodyGraph Widget', SubTitle: 'CarBodyGraph Widget' },
        CarBodyTable: {
            WidgetTitle: 'CarBodyTable Widget',
            SubTitle: 'CarBodyTable Widget',
            TableColumnCarBodyID: 'CarBody ID',
            Total: ' Overall risk',
            StudID: 'Stud ID',
        },
        DevicesFaultReport: { WidgetTitle: 'DevicesFaultReport Widget', SubTitle: 'DevicesFaultReport Widget' },
        TaAnalysisTable: {
            WidgetTitle: 'TaAnalysisTable',
            SubTitle: 'TaAnalysisTable',
            TableDeviceName: 'DeviceName Output / FaultCode',
            TableTitle: 'TA For Device Name & Output',
        },
        MtbfAnalysisTable: {
            MenuTitle: 'MTBF',
            WidgetTitle: 'MtbfAnalysisTable Widget',
            SubTitle: 'MtbfAnalysisTable Widget',
            TableDeviceName: 'DeviceName Output / FaultCode',
            TableTitle: 'Mean Time Between Fault Occurrences',
        },
        PlantDeviceByDuration: {
            WidgetTitle: 'Top 10 Devices with Longest Duration',
            SubTitle: 'Top 10 Devices with Longest Duration',
        },
        MaintainanceActionTable: {
            WidgetTitle: 'MaintenanceActionTable widget',
            SubTitle: 'maintenanceActionTable widget',
        },
        DailyFaultTrendsWidget: {
            WidgetTitle: 'DailyFaultTrendsWidget Widget',
            SubTitle: 'DailyFaultTrendsWidget Widget',
            HourOfDay: 'hour of day',
            TotalDayFaults: '% total day faults',
            Code: 'Code',
            Time: 'Time',
            CountDayPercent: 'Count Day Percent',
            CountDay: 'Count Day',
        },
        CarBodyDurationWidget: {
            WidgetTitle: 'CarBodyDurationWidget Widget',
            SubTitle: 'CarBodyDurationWidget Widget',
        },
        CycleGap: {
            WidgetTitle: 'CycleGap Widget',
            SubTitle: 'CycleGap Widget',
            GraphTitle: 'Cycle Gaps ( station: {{stationName}} )',
            None: 'None',
            EventTypeLabel: 'Event Type',
            Unitslabel: 'Minutes',
        },
        FaultRateMeasurementTrend: {
            WidgetTitle: 'FaultRateMeasurementTrend Widget',
            SubTitle: 'FaultRateMeasurementTrend Widget',
            FaultRate: 'Fault Rate',
        },
        TtrTable: {
            WidgetTitle: 'TtrTable Widget',
            SubTitle: 'TtrTable Widget',
            TableDeviceName: 'DeviceName Outlet',
            TotalTtr: 'All fault',
        },
        MttrTable: {
            WidgetTitle: 'MttrTable Widget',
            SubTitle: 'MttrTable Widget',
            TableDeviceName: 'DeviceName Outlet',
            TotalMttr: 'All fault',
        },
        TaTable: {
            WidgetTitle: 'TaTable Widget',
            SubTitle: 'TaTable Widget',
            TableDeviceName: 'DeviceName Outlet',
            TotalTa: 'All fault',
        },
        MeanTimeBetweenFailureTableAnalysis: {
            WidgetTitle: 'MTBF Widget',
            SubTitle: 'MTBF Widget',
            TableDeviceName: 'DeviceName Outlet',
            TotalMtbf: 'All fault',
        },
        UptimeDistribution: {
            Title: 'UptimeDistribution widget',
            SubTitle: 'A uptimeDistribution widget',
            DistributionDailyText: 'Distribution of daily Uptime per station',
        },
        CarbodyRiskTable: {
            WidgetTitle: 'CarBodyTable Widget',
            SubTitle: 'CarBodyTable Widget',
            TableColumnCarBodyID: 'CarBody ID',
            Total: ' Overall risk',
            StudID: 'Stud ID',
        },
        GraphicData: {
            Title: 'GraphicData widget',
            SubTitle: 'A graphicData widget',
        },
        VarianceAnalysis: {
            Title: 'VarianceAnalysis widget',
            SubTitle: 'VarianceAnalysis widget',
        },
        SaTable: {
            Title: 'SaTable widget',
            SubTitle: 'A saTable widget',
            Station: 'Station',
            MeanUptime: 'Mean Uptime (min)',
            DeviationUptime: 'St. Deviation Uptime (min)',
            MaxUptime: 'Max Uptime (min)',
            TotalUptime: 'Total Uptime (min)',
            Downtime: 'Downtime (min)',
            TA: 'TA (%)',
        },
        UploadStat: {
            WidgetTitle: 'UploadStat Widget',
            SubTitle: 'UploadStat Widget',
            UploadTimeLabel: 'Upload Time',
            FileSizeLabel: 'Total Size',
            NumberOfFilesLabel: 'Number of Files',
            FileNamesLabel: 'Filenames',
        },
        UploadChart: {
            WidgetTitle: 'UploadChart Widget',
            SubTitle: 'UploadChart Widget',
            UploadSizeLabel: 'Upload Size',
            NumberOfFilesUploadedLabel: 'Number Of Files Uploaded',
        },
        WopRateMeasurementTrend: {
            WidgetTitle: 'WopRateMeasurementTrend Widget',
            SubTitle: 'WopRateMeasurementTrend Widget',
        },
        WeldingTimeMeasurementTrend: {
            WidgetTitle: 'Welding Time Measurement',
            SubTitle: 'WeldingTimeMeasurementTrend Widget',
            WeldingTime: 'Welding time (deviation average)',
        },
        PenetrationMeasurementTrend: {
            WidgetTitle: 'Penetration Measurement',
            SubTitle: 'PenetrationMeasurementTrend Widget',
        },
        StudProjectionMeasurementTrend: {
            WidgetTitle: 'StudProjection Measurement',
            SubTitle: 'StudProjectionMeasurementTrend Widget',
            BoltProjection: 'Bolt projection (deviation average)',
        },
        LiftingHeightMeasurementTrend: {
            WidgetTitle: 'Lifting Height Measurement',
            SubTitle: 'Lifting Height Measurement Trend',
            LiftingHeight: 'Lifting height (deviation average)',
        },
        DropTimeMeasurementTrend: {
            WidgetTitle: 'DropTime Measurement',
            SubTitle: 'DropTimeMeasurementTrend Widget',
            DropTimeRate: 'Drop Time Rate (deviation average)',
        },
        StationAvailabilityTrend: {
            Title: 'Station Availability Trend',
            SubTitle: 'StationAvailabilityTrend widget',
            XAxis: 'Week interval',
            YAxis: 'TA (%)',
            Station: 'Station',
            CycleCount: 'Cycle Count',
            FaultCount: 'Fault Count',
            WopCount: 'WOP Count',
            TotalUptime: 'Total Uptime (min)',
            Downtime: 'Downtime (min)',
        },
        DeviceAreaGraph: { WidgetTitle: 'DeviceAreaGraph Widget', SubTitle: 'DeviceAreaGraph Widget' },
        ProbabilityDensityFunction: {
            WidgetTitle: 'ProbabilityDensityFunction Widget',
            SubTitle: 'ProbabilityDensityFunction Widget',
        },
        FleetExpandDeviceTable: {
            WidgetTitle: 'FleetExpandDeviceTable Widget',
            SubTitle: 'FleetExpandDeviceTable Widget',
        },
        MeasurementsWidget: {
            WidgetTitle: 'MeasurementsWidget Widget',
            SubTitle: 'MeasurementsWidget Widget',
            GraphTitle: 'Measurements ( station: {{stationName}} )',
            None: 'None',
            EventTypeLabel: 'Event Type',
            Unitslabel: 'Weld Energy',
        },
        MeasurementsSprWidget: {
            WidgetTitle: 'MeasurementsWidget Widget',
            SubTitle: 'MeasurementsWidget Widget',
            GraphTitle: 'Measurements ( station: {{stationName}} )',
            None: 'None',
            EventTypeLabel: 'Event Type',
            Unitslabel: 'Weld Energy',
        },
        FaultsPerDeviceHistogram: {
            WidgetTitle: 'FaultsPerDeviceHistogram Widget',
            SubTitle: 'FaultsPerDeviceHistogram Widget',
            xAxisTitle: 'Device Name',
            yAxisTitle: 'Event Count',
        },
        FaultsPerDeviceHistogramSpr: {
            WidgetTitle: 'FaultsPerDeviceHistogram Widget',
            SubTitle: 'FaultsPerDeviceHistogram Widget',
            xAxisTitle: 'Device Name',
            yAxisTitle: 'Event Count',
        },

        ProcessLogSpr: { WidgetTitle: 'ProcessLogSpr Widget', SubTitle: 'ProcessLogSpr Widget' },
        CycleGapSpr: { WidgetTitle: 'CycleGapSpr Widget', SubTitle: 'CycleGapSpr Widget' },
        DurationOfFaultsSpr: {
            WidgetTitle: 'Duration of Event Code',
            Fault: 'Fault',
            xAxisLabel: 'Event Number',
            yAxisLabel: 'Duration Of event Number',
        },
        EventCodeFrequencySpr: {
            WidgetTitle: 'EventCodeFrequencySpr Widget',
            SubTitle: 'EventCodeFrequencySpr Widget',
        },
        EventDescFrequencySpr: {
            WidgetTitle: 'EventDescFrequencySpr Widget',
            SubTitle: 'EventDescFrequencySpr Widget',
        },
        PlantCycleAveragesWidgetSpr: {
            WidgetTitle: 'PlantCycleAveragesWidgetSpr Widget',
            SubTitle: 'PlantCycleAveragesWidgetSpr Widget',
        },
        PlantCycleCountSpr: { WidgetTitle: 'PlantCycleCountSpr Widget', SubTitle: 'PlantCycleCountSpr Widget' },
        PlantAppNavBarSpr: { WidgetTitle: 'PlantAppNavBarSpr Widget', SubTitle: 'PlantAppNavBarSpr Widget' },
        PlantFaultFrequencySpr: {
            WidgetTitle: 'PlantFaultFrequencySpr Widget',
            SubTitle: 'PlantFaultFrequencySpr Widget',
        },
        PlantFaultByOccurenceSpr: {
            WidgetTitle: 'PlantFaultByOccurenceSpr Widget',
            SubTitle: 'PlantFaultByOccurenceSpr Widget',
        },
        PlantFaultByDurationSpr: {
            WidgetTitle: 'PlantFaultByDurationSpr Widget',
            SubTitle: 'PlantFaultByDurationSpr Widget',
        },
        PlantFaultByStudTypeSpr: {
            WidgetTitle: 'PlantFaultByStudTypeSpr Widget',
            SubTitle: 'PlantFaultByStudTypeSpr Widget',
        },
        PlantFaultByDevicesSpr: {
            WidgetTitle: 'PlantFaultByDevicesSpr Widget',
            SubTitle: 'PlantFaultByDevicesSpr Widget',
        },
        PlantDeviceByDurationSpr: {
            WidgetTitle: 'PlantDeviceByDurationSpr Widget',
            SubTitle: 'PlantDeviceByDurationSpr Widget',
        },
        PenetrationSpr: { WidgetTitle: 'PenetrationSpr Widget', SubTitle: 'PenetrationSpr Widget' },
        LiftSpr: { WidgetTitle: 'LiftSpr Widget', SubTitle: 'LiftSpr Widget' },
        VoltageSpr: { WidgetTitle: 'VoltageSpr Widget', SubTitle: 'VoltageSpr Widget' },
        WeldTimeSpr: { WidgetTitle: 'WeldTimeSpr Widget', SubTitle: 'WeldTimeSpr Widget' },
        TopFaultCountSpr: { WidgetTitle: 'TopFaultCountSpr Widget', SubTitle: 'TopFaultCountSpr Widget' },

        MeanTimeBetweenFailureTableAnalysisSpr: {
            WidgetTitle: 'MeanTimeBetweenFailureTableAnalysisSpr Widget',
            SubTitle: 'MeanTimeBetweenFailureTableAnalysisSpr Widget',
        },
        MttrTableSpr: { WidgetTitle: 'MttrTableSpr Widget', SubTitle: 'MttrTableSpr Widget' },
        TtrTableSpr: { WidgetTitle: 'TtrTableSpr Widget', SubTitle: 'TtrTableSpr Widget' },
        TaTableSpr: { WidgetTitle: 'TaTableSpr Widget', SubTitle: 'TaTableSpr Widget' },
        MeasurementTrend: { WidgetTitle: 'MeasurementTrend Widget', SubTitle: 'MeasurementTrend Widget' },
        MeasurementAggregateWidget: {
            WidgetTitle: 'MeasurementAggregateWidget Widget',
            SubTitle: 'MeasurementAggregateWidget Widget',
        },
        MeasurementAggregateSprWidget: {
            WidgetTitle: 'MeasurementAggregateSprWidget Widget',
            SubTitle: 'MeasurementAggregateSprWidget Widget',
        },
        EssentialControlWidgets: {
            WidgetTitle: 'EssentialControlWidgets Widget',
            SubTitle: 'EssentialControlWidgets Widget',
        },
        DReportWidget: {
            WidgetTitle: 'DReportWidget Widget', SubTitle: 'DReportWidget Widget',
            PrimaryYAxisTitle: 'Cycles',
            SecondaryYAxisTitle: 'Event Rate %',
            ChartTitle: 'Trend',
            Target: 'Target',
            Event_20002: 'Quote Ausspucker',
            Event_20028: 'Quote Klemmer',
            Event_17068: 'Quote Ladestift',
        },
        // [INSERT NEW WIDGET EN TITLE KEY ABOVE] < Needed for generating containers language seamlessly
    },
    Menu: { AvailabilityAndReliabilityTitle: 'Technical Availability' },
    HomePage: {
        PageTitle: 'Home ',
        Title: 'Home',
        Last_Updated: 'Last Updated',
        Risk: 'Risk',
        Target: 'Target',
        TechnicalAvail: 'TA',
        FaultTrend: 'Fault Trend',
        cycleCount: 'Cycles',
        eventCount: 'Faults',
        eventRate: 'Fault Rate',
    },
    SignIn: {
        PageTitle: 'Login',
        Title: 'Login',
        Email: {
            Placeholder: 'Email',
        },
        Password: {
            Placeholder: 'Password',
        },
        LoggedIn: 'Keep me logged in',
        LoginButton: {
            Label: 'Log In',
            Loading: 'Loading...',
        },
        ForgetUsernameOrPassword: 'Forgot Password?',
        NewUser: 'New User',
        RequestAccess: 'Request Access',
        TermCondition: 'I accept the Terms and Conditions',
        Error: {
            ValidEmailRequired: 'Required: Please provide a valid email address.',
            ValidPasswordRequired: 'Required: Please provide a valid pasword.',
        },
    },
    NavProfile: {
        UserAvatarAltMessage: 'User Avatar',
    },
    PanelScreenPage: {
        PageTitle: '9 Panel - Alt',
        Title: 'Progress at a Glance',
    },
    ProjectsPage: {
        PageTitle: 'Projects',
        Title: 'Progress at a Glance',
    },
    ReportingViewPage: {
        PageTitle: 'Failure Mode Analysis',
        Title: 'Failure Mode Analysis',
        Table: {
            StudType: 'Stud Type',
            DeviceName: 'Device Name',
            StudId: 'StudID',
        },
        DatePicker: {
            ANNUALLY: '[Year] YYYY',
            WEEKLY: '[Week] W',
            DAILY: 'ddd',
            HOURLY: 'HH:mm',
        },
        FileUpload: 'File Upload',
        ExportToExcel: 'Export to Excel',
        ExportToPdf: 'Export to PDF',
        FaultAssignment: 'Fault assignment',
        FaultAssignmentVal: 'Active',
        StatusB: 'Status B',
        StatusBVal: 'Empty',
        Event: 'Event',
        EventVal: 'Fault',
        SelectFaultCode: 'Select Fault #:',
        FilterByFaultCode: 'Filter By Event',
        FilterByStudType: 'Filter By Stud Type',
        FilterByDeviceName: 'Filter By Device',
        FilterByStudID: 'Filter By Stud ID',
        RecommendedActionTitle: 'Actions',
        InformationPlaceholder: 'Information title placeholder',
        AddCommentsTitle: 'Add Comments (Optional):',
        AddCommentsBtn: 'Submit',
        History: 'History',
        Close: 'Close',
        filterBy: 'Filter By',
        ParentLabelPage: {
            Year: 'Year',
            Week: 'Week',
            Day: 'Day',
            Hour: 'Hour',
        },
        NoData: 'No data to display',
        NoDataSub: 'Select a value below to display correlating signals',
        DataUptodate: 'Data is up to date. Last updated',
        FaultCountSum: 'Event {{unit}} Sum',
        PDFTitle: 'Error Report',
        UnitSwitchToolTip: 'Event Rate in {{unit}}',
    },
    ReportingViewPageB: {
        PageTitle: 'Reporting',
        Title: 'Reporting',
        Table: {
            Fault: 'Fault',
            Description: 'Description',
        },
        FileUpload: 'File Upload',
        ExportToExcel: 'Export to Excel',
        ExportToPdf: 'Export to PDF',
        FaultAssignment: 'Fault assignment',
        FaultAssignmentVal: '(Multiple elements)',
        StatusB: 'Status B',
        StatusBVal: 'Empty',
        Event: 'Event',
        EventVal: 'Fault',
        SelectFaultCode: 'Select Fault #:',
        FilterByStudType: 'Filter By Stud Type',
        FilterByDeviceName: 'Filter By Device',
        FilterByFault: 'Filter By Fault',
        FilterByStudID: 'Filter By Stud ID',
        WeekDay: 'WeekDay',
        All: 'All',
        DeviceName: 'Device Name',
        ThresholdRedMarkingNote: ' {{threshold}} errors per {{period}}',
        StudType: 'Stud Type',
        OverAllResult: 'Overall Result',
        Sunday: 'Sunday',
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
        Fault: 'Fault',
        Info: 'Info',
        ComponentExchange: 'Component Exchange',
        FirmwareUpdate: 'Firmware Update',
        Maintainance: 'Maintenance',
        Active: 'Active',
        // Events: 'Fault, Info, Component Exchange, Firmware Update, Maintainance',
        Events: 'Fault',
        Year: 'Year',
        Week: 'Week',
        PDFTitle: 'Daily Error Report',
        PDFFileName: 'Error_Report',
        ExpandTooltip: 'Show Events Cycle Ratio etc.',
        CollapeTooltip: 'Hide Events Cycle Ratio etc.',
    },
    SummaryDashboard: {
        PageTitle: 'Summary Dashboard',
        Title: 'Summary Dashboard at a Glance',
    },
    PlantLevelKpi: {
        PageTitle: 'Plant KPIs',
        Title: 'Summary Plant KPIs at a Glance',
    },
    LabAnalysis: {
        PageTitle: 'Lab Data KPIs',
        Title: 'Lab Data KPIs',
    },
    Sidebar: {
        PageTitle: 'Seitenleiste',
        Title: 'Seitenleiste Seite',
        NinePanel: 'Nine Panel',
        Home: 'Home',
        Projects: 'Project',
        ReportingView: 'Reporting View',
        ReportingViewB: 'Reporting View B',
        SummaryDashboard: 'Summary Dashboard',
        PlantKPI: 'Plant KPI',
        Analytics: 'Analytics',
        LabAnalysis: 'Analysis',
        DashboardKPIs: 'Dashboard KPIs',
        FileUpload: 'File Upload',
        EluAnalytics: 'Platform',
        Api: 'ELU API',
        Logout: 'Logout',
    },
    FileUpload: {
        PageTitle: 'File Upload',
        Title: 'Upload Files',
        DropzoneText: 'Drag & drop files or Browse',
        UploadCyclesLabel: 'Upload Measurement/Cycle File(s)',
        UploadSytemEventsLabel: 'Upload System Events File(s)',
        UploadMaintenanceLabel: 'Upload Maintence File(s)',
        Back: 'Back',
        Finish: 'Finish',
    },
    SystemOverview: {
        PageTitle: 'Fleet Overview',
        Title: 'Fleet Overview',
        MenuLabel: 'Fleet',
        TableColumnFault: 'Device Name / Outlet',
    },
    PlantOverview: {
        PageTitle: 'Plant Overview',
        Title: 'Plant Overview',
        MenuLabel: '9 - Panel',
        NavBarTitle: 'APPs',
        MaintenanceTitle: 'Reporting',
        MaintenanceSubTitle: 'Maintenance Analysis',
        SystemTitle: 'Fleet Overview',
        SystemSubTitle: 'System Health Analytics',
        UploadTitle: 'Upload',
        UploadSubTitle: 'File Upload',
        RootCauseAnalysisTitle: 'Failure Mode Analysis',
        RootCauseAnalysisSubTitle: 'Failure Mode Analysis',
        TotalCycles: 'Weekly Cycle Count',
        PreviousDay: 'Previous Day',
        PreviousWeek: 'Previous Week',
        Week: 'Week',
        YAxisFaultFrequencyTitle: 'Count Of Event Number',
        XAxisFaultFrequencyTitle: 'Event Number',
        SystemHealthCard: 'System Health Card',
        Change: 'Change',
        New: 'New',
        NoChange: 'No Change',
        FaultFrequencyChartTitle: 'Top {{topX}} Frequencies Of Event Code',
        FaultByOccurrence: 'Top {{topX}} Faults with Highest Occurrence',
        FaultByDuration: 'Top {{topX}} Faults with Longest Duration',
        SystemWithFaults: 'Top {{topX}} Devices with Highest Fault Occurrence',
        StudTypeWithFaults: 'Top {{topX}} Stud Types with Highest Fault Occurrence',
        DeviceByDuration: 'Top {{topX}} Devices with Longest Duration',
        Fault: 'Fault',
        Occurrences: 'Occurrences',
        Duration: 'Duration (mins)',
        Position: 'Position',
        System: 'System',
        StudType: 'Stud Type',
        TotalCyclesTooltip: 'in week',
        TechnicalAvailability: 'Technical Availability',
        MeanTimeToRepair: 'Mean Time To Repair',
        MeanTimeBetweenFailure: 'Mean Time Between Failure',
        MeanTimeToFailure: 'Mean Time To Failure',
        Ratio: 'Ratio',
        Cycle: 'Cycle',
        CurrentWeekText: 'Current Week',
        PercentageChange: '% Change',
        CurrentYear: 'Year',
    },
    ProcessLogView: { PageTitle: 'Diagnostic Log', Title: 'Diagnostic Log', BtnBarDateFilter: 'Historical' },
    ParetoAnalysis: {
        MenuLabel: 'Pareto',
        PageTitle: 'Pareto Analysis',
        Title: 'Pareto Analysis ',
        EventDescriptionChartTitle: 'Frequency of Event Description',
        EventDescriptionChartXAxisLabel: 'Count Of Event Number',
        EventDescriptionChartYAxisLabel: 'Description',
        EventCodeChartTitle: 'Frequency of Event Code',
        EventCodeChartXAxisLabel: 'Event Number',
        EventCodeChartYAxisLabel: 'Count Of Event Number',
        EventCodeChartSecondaryYAxisLabel: 'Percentage',
    },
    FailureRateTrend: {
        PageTitle: 'Failure Rate Trend',
        Title: 'Failure Rate Trend ',
        EventRatePerEventChartTitleDaily: 'Event Rate per Root Cause per Day {{yearLabel}}',
        EventRatePerEventChartTitleWeekly: 'Event Rate per Root Cause per Week {{yearLabel}}',
        EventRatePerDeviceTitleDaily: 'Event Rate per Device by Day 2021',
        EventRatePerDeviceTitleWeekly: 'Event rate per Device by Week 2021',
        EventRatePerEventXAxisLabelDaily: 'Day',
        EventRatePerEventXAxisLabelWeekly: 'Week',
        EventRatePerEventYAxisLabel: 'Event Rate ( Part Per Million )',
        Fault: 'Fault',
    },
    FaultCountTrend: { PageTitle: 'Fault Count Trend', Title: 'Fault Count Trend ' },
    RootCauseVisibility: { PageTitle: 'Root Cause Visibility', Title: 'Root Cause Visibility ' },
    EssentialControlChart: {
        MenuTitle: 'Essential Controls',
        PageTitle: 'Essential Controls Chart',
        Title: 'Essential Controls Chart ',
    },
    MtbfAnalysis: {
        MenuTitle: 'MTBF',
        PageTitle: 'Mean Time Between Failure Analysis',
        Title: 'Mean Time Between Failure Analysis ',
    },
    MttrAnalysis: {
        MenuTitle: 'MTTR',
        PageTitle: 'Mean Time To Repair Analysis',
        Title: 'Mean Time To Repair Analysis ',
    },
    MttrAnalysisSpr: {
        MenuTitle: 'MTTR',
        PageTitle: 'Mean Time To Repair Analysis',
        Title: 'Mean Time To Repair Analysis ',
    },
    TaAnalysis: {
        MenuTitle: 'TA',
        PageTitle: 'Technical Availability Analysis',
        Title: 'Technical Availability Analysis ',
    },
    EventOccurrence: { PageTitle: 'Event Occurrence', Title: 'Event Occurrence ' },
    TopXFaultCountAnalysis: { PageTitle: 'Faults Per Device', Title: 'Faults Per Device' },
    FaultRateForecast: { PageTitle: 'Fault Rate Forecast', Title: 'Fault Rate Forecast ' },
    CarBodyAnalysis: {
        PageTitle: 'Car Body Analysis',
        Title: 'Car Body Analysis ',
    },
    OpportunityAnalysisWidget: { PageTitle: 'Opportunity Analysis', Title: 'Opportunity Analysis ' },
    Diagnostic: { PageTitle: 'Diagnostic', Title: 'Diagnostic ' },
    PlantHealthOverview: {
        PageTitle: 'Plant Health Overview',
        Title: 'Plant Health Overview ',
        MenuLabel: 'Plant Health',
    },
    SettingsPage: {
        PageTitle: 'Settings',
        Operation: 'Operation',
        AddNew: 'Add new',
        Shifts: 'Shifts',
        Breaks: 'Breaks',
        ShiftName: 'Shift name',
        BreakName: 'Break name',
        TimeFrom: 'Time from',
        TimeTo: 'Time to',
        DateFrom: 'Date from',
        DateTo: 'Date to',
        Delete: 'delete',
        Edit: 'edit',
        Actions: 'Actions',
    },
    MaintainanceAction: { PageTitle: 'Maintenance Action', Title: 'Maintenance Action' },
    EluPlatformAnalytics: {
        PageTitle: 'Platform Overview',
        Title: 'Platform Overview ',
        MenuLabel: 'Platform',
        DailyDataUploadLabel: "ELU's Daily Data Upload",
        WeeklyDataUploadLabel: "ELU's Weekly Data Upload",
        MonthlyDataUploadLabel: "ELU's Monthly Data Upload",
        AnnualDataUploadLabel: "ELU's Annual Data Upload",
        HourlyDataUploadLabel: "ELU's Hourly Data Upload",
    },
    SaReport: {
        PageTitle: 'SaReport',
        Title: 'SaReport',
    },
    SaTrend: {
        PageTitle: 'SaTrend',
        Title: 'SaTrend',
    },
    GraphDataAnalysis: {
        PageTitle: 'Graph Data Analysis',
        Title: 'Graph Data Analysis',
    },
    MeasurementTrend: { PageTitle: 'MeasurementTrend', Title: 'MeasurementTrend ' },
    CycleGapTempo: {
        PageTitle: 'Cycle Gap Tempo',
        Title: 'Cycle Gap Tempo ',
        GraphTitle: 'Cycle Gaps ( station: {{stationName}} )',
    },
    CarbodyRiskAnalysis: { PageTitle: 'Carbody Risk Analysis', Title: 'Carbody Risk Analysis ' },
    FleetOverview: {
        PageTitle: 'FleetOverview',
        Title: 'Fleet Overview ',
        MenuLabel: 'Fleet',
        AllDevices: 'ALL DEVICES',
        PinnedDevices: 'PINNED DEVICES',
    },
    DailyFaultTrends: { PageTitle: 'Daily Fault Trends', Title: 'Daily Fault Trends ' },
    StationAvailability: { PageTitle: 'Station Availability', Title: 'Station Availability ' },
    OpportunityAnalysisTool: {
        PageTitle: 'Opportunity Analysis',
        Title: 'Opportunity Analysis',
        MenuTitle: 'Opportunity',
    },
    CarBodyDurationsPerStation: {
        PageTitle: 'Car Body Duration Per Station ',
        Title: 'Car Body Duration Per Station ',
    },
    CycleMeasurementCombo: { PageTitle: 'Cycle Measurement Combo', Title: 'Cycle Measurement Combo ' },
    Measurements: { PageTitle: 'Measurements', Title: 'Measurements ' },
    Program: {
        PageTitle: 'Program',
        Title: 'Program',
        SubmitChangeButton: 'SUBMIT CHANGE',
        CancelButton: 'CANCEL',
        TableColumns: {
            Program: 'Program',
            Devices: 'Devices',
            Schedule: 'Schedule',
            Outlet: 'Outlet',
            Feeder: 'Feeder',
            Tool: 'Tool',
            Station: 'Station',
            LastModifiedBy: 'Last Modified By',
            LastUpdated: 'Last Updated',
        },
        ProgramParameters: {
            title: 'Parameters',
            subTitle: 'Select parameters to make changes to',
            TableColumns: {
                Parameter: 'parameter',
                Reference: 'Reference',
                Minimum: 'Minimum',
                Maximum: 'Maximum',
            },
        },
        ProgramSubmitChangeModal: {
            Title: 'Confirm Changes',
            SubTitle: 'You have made changes to the following parameters',
            TableColumns: {
                Previous: 'PREVIOUS',
                Proposed: 'PROPOSED',
            },
            AdditionalDetail: {
                Title: 'Please provide any additional details below.',
                Placeholder: 'Type description here',
            },
            ActionableButtons: {
                Submit: 'SUBMIT FOR APPROVAL',
                Cancel: 'CANCEL',
                PasswordChange: 'PASSWORD OVERRIDE',
            },
        },
        ProgramSubmittedChangeModal: {
            Title: 'Changes Submitted for approval',
            CloseButton: 'CLOSE',
            TitleParameterChange: 'Parameter change pushed',
        },
        ProgramPasswordOverrideModal: {
            Title: 'Enter admin password',
            ApplyButton: 'APPLY CHANGES',
            TextfieldPlaceholder: 'Please enter the administrative password to apply changes',
        },
        programDetail: {
            title: 'Program Overview',
            TableRows: {
                Feeder: 'Feeder',
                Tool: 'Tool',
                DateCreated: 'Date Created',
                CreatedBy: 'Created By',
                Optimization: 'Optimization',
                WeldMode: 'Weld Mode',
                Faults: 'Faults',
            },
        },
        programDevices: {
            Title: 'Devices',
            SubTitle: 'Select devices to apply changes to',
            TableHeader: 'Device Name',
        },
    },
    ProcessLogViewSpr: { PageTitle: 'Diagnostic Log', Title: 'Diagnostic Log' },
    TtrAnalysis: { PageTitle: 'TTR', Title: 'TTR' },
    CyclegapTempoSpr: {
        PageTitle: 'Cycle Gap Tempo SPR',
        Title: 'CyclegapTempoSpr ',
        ActualCurve: 'Actual Curve',
        ReferenceCurve: 'ReferenceCurve',
    },
    ParetoAnalysisSpr: { PageTitle: 'ParetoAnalysisSpr', Title: 'ParetoAnalysisSpr ' },
    PlantHealthOverviewSpr: { PageTitle: 'PlantHealthOverviewSpr', Title: 'PlantHealthOverviewSpr ' },
    FailureRateTrendSpr: { PageTitle: 'FailureRateTrendSpr', Title: 'FailureRateTrendSpr ' },
    ReportingViewBspr: { PageTitle: 'ReportingViewBspr', Title: 'ReportingViewBspr ' },
    ReportingViewSpr: { PageTitle: 'ReportingViewSpr', Title: 'ReportingViewSpr ' },
    EssentialControlChartSpr: { PageTitle: 'EssentialControlChartSpr', Title: 'EssentialControlChartSpr ' },
    TopXFaultCountAnalysisSpr: { PageTitle: 'TopXFaultCountAnalysisSpr', Title: 'TopXFaultCountAnalysisSpr ' },
    FaultCountTrendSpr: { PageTitle: 'FaultCountTrendSpr', Title: 'FaultCountTrendSpr ' },
    MtbfAnalysisSpr: { PageTitle: 'MtbfAnalysisSpr', Title: 'MtbfAnalysisSpr ' },

    TtrAnalysisSpr: { PageTitle: 'TtrAnalysisSpr', Title: 'TtrAnalysisSpr ' },
    TaAnalysisSpr: { PageTitle: 'TaAnalysisSpr', Title: 'TaAnalysisSpr ' },
    MeasurementsSpr: { PageTitle: 'Measurements', Title: 'Measurements' },
    DReportPage: { PageTitle: 'D-Report', Title: 'D-Report' },
    // [INSERT NEW PAGE EN TITLE KEY ABOVE] < Needed for generating containers language seamlessly
};

export default translations;
