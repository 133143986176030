/**
 *
 * EventCountDailyFrequency
 *
 */

import EventCountDailyFrequency, { EventCountDailyFrequencyProperty } from './EventCountDailyFrequency';

export { EventCountDailyFrequency, EventCountDailyFrequencyProperty };

export default EventCountDailyFrequency;
