/**
 *
 * StationAnomaly
 *
 */

import StationAnomaly, { StationAnomalyProperty } from './StationAnomaly';

export { StationAnomaly, StationAnomalyProperty };

export default StationAnomaly;
