/**
 *
 * SaTable
 *
 */

import SaTable, { SaTableProperty } from './SaTable';

export { SaTable, SaTableProperty };

export default SaTable;
