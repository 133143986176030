export enum MXP_PAGE_VIEW {
    PlantHealthOverview = 'Plant Health Overview',
    FleetOverview = 'Fleet Overview',
    Measurements = 'Measurements',
    DiagnosticLog = 'Diagnostic Log',
    CycleGapTempo = 'Cycle Gap Tempo',
    EssentialControls = 'Essential Controls',
    OpportunityAnalysis = 'Opportunity Analysis',
    Pareto= 'Pareto',
    FailureRateTrend='Failure Rate Trend',
    FaultCountTrend = 'Fault Count Trend',
    FailureModeAnalysis = 'Failure Mode Analysis',
    Reporting = 'Reporting',
    FaultsPerDevice = 'Faults Per Device',
    MTBF = 'MTBF',
    MTTR = 'MTTR',
    TA = 'TA Analysis',
    TTR ='TTR Analysis',
    CarBodyDuration = 'Car Body Duration',
    StationAvailability = 'Station Availability',
    MeasurementTrend = 'Measurement Trend',
    MaintainanceAction = 'Maintainance Action',
    CycleMeasurementCombo = 'Cycle Measurement Combo',
    PlatformAnalytics = 'Platform Analytics',
    LandingPage = 'Landing Page',
    DReport ='D Report',
    FileUpload = 'File Upload'
}