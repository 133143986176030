/**
 *
 * MuiDateRangePicker
 *
 */

import MuiDateRangePicker from './MuiDateRangePicker';

export { MuiDateRangePicker };

export default MuiDateRangePicker;
