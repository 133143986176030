/**
 *
 * OverallRiskItem
 *
 */

import { default as OverallRiskItem } from './OverallRiskItem';

export { OverallRiskItem };

export default OverallRiskItem;
