export const stations = [
    'UB73 070',
    'UB73 090',
    'UB73 100',
    'UB74 020',
    'UB74 030',
    'RS75 080',
    'UB74 070',
    'UB75 020',
    'UB75 030',
    'UB74 060',
    'UB73 065',
    'UB75 050',
    'VB73 204',
    'UB73 070',
    'UB73 090',
    'UB73 100',
    'UB74 020',
    'VB73 204',
    'RS75 080',
    'UB73 065',
    'UB74 060',
    'UB74 030',
    'UB74 070',
    'UB75 050',
    'UB75 020',
    'UB74 060',
    'UB73 065',
    'UB73 070',
    'UB73 090',
    'VB73 204',
    'UB73 100',
    'UB74 020',
    'UB74 070',
    'RS75 080',
    'UB75 020',
    'UB75 050',
    'UB74 030',
    'UB74 020',
    'VB73 204',
    'UB75 020',
    'UB73 070',
    'UB74 060',
    'UB74 070',
    'UB73 100',
    'UB73 065',
    'UB75 050',
    'UB73 090',
    'UB75 030',
    'UB74 030',
    'RS75 080',
    'UB74 030',
    'RS75 080',
    'UB73 100',
    'VB73 204',
    'UB73 070',
    'UB73 090',
    'UB73 065',
    'UB75 030',
    'UB74 070',
    'UB74 020',
    'UB74 060',
    'UB75 050',
    'UB73 100',
    'UB74 020',
    'UB74 070',
    'UB73 070',
    'UB75 030',
    'UB73 090',
    'UB74 060',
    'UB74 030',
    'VB73 204',
    'RS75 080',
    'UB75 050',
    'UB73 065',
    'UB74 060',
    'VB73 204',
    'UB74 070',
    'UB75 030',
    'RS75 080',
    'UB73 070',
    'UB73 065',
    'UB74 020',
    'UB75 050',
    'UB74 030',
    'UB73 090',
    'RS75 080',
    'UB74 060',
    'UB75 050',
    'UB75 030',
    'UB73 090',
    'UB73 070',
    'UB74 030',
    'VB73 204',
    'UB73 065',
    'UB74 020',
    'UB74 070',
    'VB73 204',
    'UB74 060',
    'RS75 080',
    'UB73 065',
    'UB74 020',
    'UB74 070',
    'UB75 020',
    'UB75 050',
    'UB73 100',
    'UB73 070',
    'UB75 030',
    'UB74 030',
    'UB73 090',
    'UB74 030',
    'UB75 030',
    'UB75 050',
    'UB74 070',
    'RS75 080',
    'UB74 020',
    'UB73 065',
    'UB74 060',
    'UB75 020',
    'UB75 050',
    'UB74 030',
    'UB75 020',
    'RS75 080',
    'UB74 060',
    'UB74 020',
    'VB73 204',
    'UB75 030',
    'UB74 070',
    'UB73 100',
    'UB73 090',
    'UB73 065',
    'UB75 020',
    'UB75 030',
    'UB73 100',
    'UB74 060',
    'UB74 020',
    'UB74 030',
    'UB73 090',
    'UB74 070',
    'UB73 070',
    'UB74 020',
    'RS75 080',
    'UB75 050',
    'UB74 030',
    'UB73 065',
    'VB73 204',
    'UB73 090',
    'UB74 060',
    'UB73 100',
    'UB75 020',
    'UB73 070',
    'UB74 070',
    'UB75 020',
    'UB74 060',
    'UB75 030',
    'UB74 030',
    'UB74 070',
    'UB74 020',
    'VB73 204',
    'UB73 090',
    'UB73 065',
    'UB75 050',
    'RS75 080',
    'UB73 100',
    'UB73 070',
    'UB74 020',
    'UB75 030',
    'UB73 090',
    'UB74 060',
    'UB74 030',
    'UB75 050',
    'RS75 080',
    'UB73 100',
    'UB73 070',
    'UB74 070',
    'UB75 020',
    'UB73 065',
    'VB73 204',
    'UB74 020',
    'UB73 090',
    'UB75 020',
    'RS75 080',
    'UB74 030',
    'UB74 060',
    'VB73 204',
    'UB75 050',
    'UB73 100',
    'UB75 030',
    'UB73 070',
    'UB74 070',
    'UB73 065',
    'UB73 065',
    'UB74 070',
    'RS75 080',
    'UB73 070',
    'UB75 020',
    'UB73 090',
    'UB75 030',
    'UB74 060',
    'UB73 100',
    'UB75 050',
    'UB74 030',
    'VB73 204',
    'UB74 020',
    'UB74 070',
    'UB75 020',
    'UB75 050',
    'UB73 100',
    'RS75 080',
    'UB74 030',
    'UB74 020',
    'UB74 060',
    'UB73 090',
    'UB73 065',
    'UB75 030',
    'VB73 204',
    'UB74 070',
    'UB73 100',
    'UB75 030',
    'UB75 020',
    'UB74 030',
    'VB73 204',
    'UB74 020',
    'UB74 060',
    'RS75 080',
    'UB74 070',
    'UB73 065',
    'RS75 080',
    'UB74 020',
    'UB73 100',
    'UB74 030',
    'UB73 090',
    'UB74 060',
    'UB75 050',
    'UB75 020',
    'UB73 070',
    'UB75 030',
    'UB74 030',
    'UB75 050',
    'VB73 204',
    'RS75 080',
    'UB73 090',
    'UB74 020',
    'UB73 100',
    'UB73 065',
    'UB75 020',
    'UB74 060',
    'UB74 070',
    'UB75 030',
    'UB73 100',
    'UB73 070',
    'UB74 020',
    'UB73 090',
    'VB73 204',
    'UB74 030',
    'UB73 065',
    'UB75 020',
    'UB74 070',
    'UB75 050',
    'UB74 060',
    'RS75 080',
    'UB75 030',
    'UB75 050',
    'UB73 090',
    'RS75 080',
    'VB73 204',
    'UB74 020',
    'UB73 070',
    'UB74 030',
    'UB74 060',
    'UB73 065',
    'UB73 100',
    'UB74 070',
    'UB75 020',
    'UB75 030',
    'UB75 050',
    'UB75 020',
    'UB74 030',
    'UB74 020',
    'UB73 065',
    'UB74 070',
    'RS75 080',
    'UB73 070',
    'VB73 204',
    'UB73 100',
    'UB73 090',
    'UB74 060',
    'UB73 100',
    'VB73 204',
    'UB73 090',
    'UB75 050',
    'UB74 020',
    'UB75 030',
    'UB73 065',
    'UB73 070',
    'UB74 030',
    'UB74 070',
    'RS75 080',
    'UB74 060',
    'UB75 020',
    'UB74 020',
    'UB75 050',
    'UB75 020',
    'UB75 030',
    'RS75 080',
    'UB73 070',
    'VB73 204',
    'UB74 030',
    'UB73 090',
    'UB74 070',
    'UB73 065',
    'UB74 060',
    'UB73 100',
    'UB73 070',
    'UB73 090',
    'UB75 020',
    'UB74 070',
    'UB73 100',
    'RS75 080',
    'UB74 020',
    'UB75 050',
    'UB75 030',
    'VB73 204',
    'UB74 030',
    'UB73 065',
    'UB74 060',
    'UB75 050',
    'UB73 065',
    'VB73 204',
    'UB73 070',
    'UB74 070',
    'UB73 090',
    'UB75 020',
    'UB73 100',
    'UB74 060',
    'UB75 030',
    'UB74 020',
    'RS75 080',
    'UB74 030',
    'UB74 060',
    'UB74 030',
    'VB73 204',
    'UB73 065',
    'UB74 020',
    'UB75 050',
    'UB75 030',
    'UB74 070',
    'UB73 100',
    'UB73 090',
    'UB73 070',
    'RS75 080',
    'UB75 050',
    'UB75 030',
    'UB75 020',
    'UB74 070',
    'UB74 060',
    'UB74 030',
    'UB74 020',
    'UB73 100',
    'UB73 090',
    'UB73 070',
    'UB73 065',
    'RS75 080',
    'VB73 204',
    'RS75 080',
    'UB74 030',
    'UB73 065',
    'UB74 070',
    'UB74 020',
    'UB75 050',
    'UB74 060',
    'UB75 030',
    'UB73 100',
    'UB73 090',
    'UB73 070',
    'VB73 204',
    'UB75 020',
    'UB73 100',
    'UB73 090',
    'UB74 020',
    'UB73 070',
    'UB74 030',
    'RS75 080',
    'VB73 204',
    'UB75 050',
    'UB73 065',
    'UB75 020',
    'UB74 060',
    'UB74 070',
    'UB75 020',
    'UB73 100',
    'UB73 070',
    'UB75 050',
    'UB74 070',
    'UB73 090',
    'VB73 204',
    'UB74 020',
    'UB74 030',
    'UB73 065',
    'UB74 060',
    'RS75 080',
    'UB74 020',
    'UB75 030',
    'UB75 020',
    'UB73 070',
    'UB75 050',
    'UB74 030',
    'UB73 065',
    'VB73 204',
    'UB73 090',
    'UB74 060',
    'RS75 080',
    'UB74 070',
    'UB73 100',
    'UB73 070',
    'UB73 065',
    'VB73 204',
    'UB74 020',
    'UB75 030',
    'UB75 050',
    'UB73 100',
    'RS75 080',
    'UB74 070',
    'UB73 090',
    'UB74 060',
    'UB74 030',
    'UB73 090',
    'UB75 030',
    'UB73 065',
    'UB74 020',
    'UB74 060',
    'UB73 100',
    'UB75 050',
    'UB73 070',
    'UB74 030',
    'UB74 070',
    'VB73 204',
    'RS75 080',
    'UB75 050',
    'UB73 090',
    'VB73 204',
    'UB73 100',
    'UB74 020',
    'RS75 080',
    'UB75 030',
    'UB74 030',
    'UB73 070',
    'UB74 070',
    'UB73 065',
    'UB74 060',
    'UB75 020',
    'UB73 090',
    'UB75 050',
    'VB73 204',
    'UB74 060',
    'RS75 080',
    'UB74 070',
    'UB73 070',
    'UB74 030',
    'UB75 030',
    'UB75 020',
    'UB73 100',
    'UB74 020',
    'UB73 065',
    'UB74 070',
    'RS75 080',
    'UB75 030',
    'UB73 090',
    'UB74 060',
    'UB75 050',
    'VB73 204',
    'UB74 020',
    'UB75 020',
    'UB74 030',
    'UB73 065',
    'UB73 100',
    'UB73 070',
    'UB75 020',
    'RS75 080',
    'UB73 070',
    'UB74 060',
    'UB75 050',
    'UB73 065',
    'UB75 030',
    'VB73 204',
    'UB74 030',
    'UB74 070',
    'UB74 020',
    'UB63 110',
    'UB63 130',
    'UB75 050',
    'VB73 204',
    'RS75 080',
    'UB74 020',
    'UB75 030',
    'UB63 090',
    'UB74 030',
    'UB63 140',
    'UB73 090',
    'UB73 100',
    'UB63 070',
    'UB74 060',
    'UB63 100',
    'UB63 080',
    'UB73 070',
    'UB75 020',
    'UB73 065',
    'UB74 070',
    'UB75 020',
    'UB74 070',
    'UB73 065',
    'UB63 090',
    'UB63 130',
    'UB75 030',
    'UB74 060',
    'UB74 030',
    'UB74 020',
    'RS75 080',
    'UB63 140',
    'UB73 100',
    'UB63 110',
    'UB73 070',
    'VB73 204',
    'UB73 090',
    'UB63 070',
    'UB63 100',
    'UB63 080',
    'UB75 050',
    'RS75 080',
    'UB63 070',
    'UB63 080',
    'UB63 100',
    'UB74 070',
    'UB73 100',
    'UB74 030',
    'UB63 130',
    'UB63 090',
    'UB63 110',
    'UB63 140',
    'UB73 065',
    'UB74 030',
    'UB74 020',
    'VB73 204',
    'UB73 090',
    'UB73 100',
    'RS75 080',
    'UB73 070',
    'UB75 020',
    'UB75 030',
    'UB75 050',
    'UB74 070',
    'UB74 060',
    'UB75 050',
    'UB73 065',
    'UB74 030',
    'UB74 070',
    'UB75 020',
    'UB74 060',
    'UB73 070',
    'UB73 090',
    'VB73 204',
    'UB73 100',
    'UB74 020',
    'RS75 080',
    'UB75 030',
    'UB75 030',
    'UB73 065',
    'UB74 030',
    'UB75 050',
    'VB73 204',
    'UB74 070',
    'UB73 090',
    'UB74 060',
    'RS75 080',
    'UB73 070',
    'UB74 020',
    'UB73 100',
    'UB75 020',
    'UB74 060',
    'VB73 204',
    'UB75 020',
    'UB73 065',
    'UB74 030',
    'UB74 020',
    'UB75 050',
    'UB75 030',
    'UB74 070',
    'UB73 070',
    'UB73 090',
    'UB73 100',
    'RS75 080',
    'UB75 030',
    'RS75 080',
    'UB75 050',
    'UB75 020',
    'UB74 070',
    'UB73 100',
    'UB74 060',
    'UB74 020',
    'UB73 090',
    'UB73 065',
    'VB73 204',
    'UB74 030',
    'RS75 080',
    'UB75 030',
    'UB74 030',
    'UB74 020',
    'UB75 020',
    'UB74 070',
    'UB73 100',
    'UB74 060',
    'VB73 204',
    'UB75 050',
    'UB73 090',
    'UB74 030',
    'RS75 080',
    'UB74 020',
    'UB75 020',
    'UB73 065',
    'UB74 060',
    'UB74 070',
    'UB73 100',
    'UB73 100',
    'UB73 070',
    'UB73 065',
    'UB73 090',
    'UB74 070',
    'UB75 050',
    'UB74 060',
    'UB75 030',
    'UB74 030',
    'UB75 020',
    'RS75 080',
    'UB74 020',
    'VB73 204',
    'UB73 090',
    'RS75 080',
    'UB73 065',
    'VB73 204',
    'UB73 070',
    'UB74 020',
    'UB74 070',
    'UB74 060',
    'UB75 020',
    'UB74 030',
    'UB73 100',
    'UB75 030',
    'UB75 050',
    'VB73 204',
    'UB75 020',
    'UB75 050',
    'UB75 030',
    'UB74 020',
    'RS75 080',
    'UB73 070',
    'UB74 030',
    'UB73 090',
    'UB73 065',
    'UB74 060',
    'UB74 070',
    'UB73 100',
    'UB74 070',
    'UB75 050',
    'UB73 070',
    'UB74 020',
    'VB73 204',
    'UB75 030',
    'UB74 060',
    'RS75 080',
    'UB73 100',
    'UB73 090',
    'UB73 065',
    'UB75 020',
    'UB74 030',
    'UB75 030',
    'UB73 070',
    'UB74 020',
    'UB73 100',
    'VB73 204',
    'UB74 030',
    'RS75 080',
    'UB75 050',
    'UB74 070',
    'UB73 065',
    'UB74 060',
    'UB75 020',
    'UB73 090',
    'UB74 030',
    'RS75 080',
    'UB74 070',
    'UB74 060',
    'UB75 030',
    'UB75 020',
    'UB73 100',
    'UB73 070',
    'VB73 204',
    'UB74 020',
    'UB75 050',
    'UB73 065',
    'UB73 090',
    'UB73 070',
    'UB73 065',
    'UB75 050',
    'RS75 080',
    'UB75 030',
    'UB75 020',
    'UB74 070',
    'UB74 060',
    'UB73 100',
    'VB73 204',
    'UB73 090',
    'UB74 020',
    'UB74 030',
    'UB74 060',
    'UB75 050',
    'UB74 030',
    'UB73 065',
    'UB73 090',
    'UB75 020',
    'UB74 020',
    'RS75 080',
    'UB73 100',
    'UB73 070',
    'VB73 204',
    'UB75 030',
    'UB74 070',
    'RS75 080',
    'UB74 060',
    'UB74 030',
    'UB75 050',
    'UB73 065',
    'UB74 020',
    'VB73 204',
    'UB73 100',
    'UB73 070',
    'UB75 030',
    'UB73 090',
    'UB74 070',
    'UB73 090',
    'RS75 080',
    'UB75 050',
    'UB73 065',
    'UB73 070',
    'UB74 070',
    'UB75 030',
    'UB73 100',
    'UB74 020',
    'UB74 030',
    'UB75 020',
    'UB74 060',
    'VB73 204',
    'UB74 030',
    'UB74 070',
    'UB74 020',
    'UB75 020',
    'UB73 100',
    'UB73 090',
    'UB75 030',
    'UB73 070',
    'UB75 050',
    'UB73 065',
    'VB73 204',
    'RS75 080',
    'UB74 060',
    'UB74 070',
    'UB75 020',
    'VB73 204',
    'UB73 090',
    'UB75 050',
    'UB74 060',
    'UB73 100',
    'UB74 030',
    'UB73 065',
    'RS75 080',
    'UB74 020',
    'UB73 070',
    'UB73 065',
    'UB74 070',
    'VB73 204',
    'UB74 020',
    'UB75 020',
    'UB74 030',
    'UB75 050',
    'RS75 080',
    'UB73 070',
    'UB73 100',
    'UB73 090',
    'UB74 060',
    'UB73 065',
    'UB73 070',
    'UB74 020',
    'UB74 070',
    'UB73 100',
    'UB73 090',
    'UB75 020',
    'UB75 050',
    'UB75 030',
    'RS75 080',
    'UB74 060',
    'VB73 204',
    'UB74 030',
    'UB73 090',
    'UB74 060',
    'UB75 030',
    'UB73 100',
    'UB73 070',
    'VB73 204',
    'RS75 080',
    'UB75 050',
    'UB73 065',
    'UB74 020',
    'UB74 030',
    'UB74 070',
    'UB74 020',
    'UB73 090',
    'UB74 030',
    'UB74 060',
    'UB75 030',
    'UB73 070',
    'UB74 070',
    'UB73 100',
    'RS75 080',
    'UB75 050',
    'VB73 204',
    'UB73 065',
    'UB73 065',
    'UB74 020',
    'UB73 070',
    'UB73 090',
    'VB73 204',
    'UB73 100',
    'UB75 030',
    'UB75 050',
    'UB75 020',
    'RS75 080',
    'UB74 060',
    'UB74 030',
    'UB74 070',
    'UB75 030',
    'UB74 030',
    'VB73 204',
    'UB74 060',
    'UB74 020',
    'UB73 070',
    'UB73 090',
    'UB73 065',
    'UB73 100',
    'RS75 080',
    'UB74 070',
    'UB75 050',
    'UB75 020',
    'UB74 030',
    'UB75 050',
    'UB73 065',
    'RS75 080',
    'UB75 020',
    'UB73 090',
    'UB75 030',
    'UB74 060',
    'VB73 204',
    'UB74 070',
    'UB74 020',
    'UB73 070',
    'UB73 100',
    'VB73 204',
    'UB75 020',
    'UB74 060',
    'UB75 050',
    'UB74 030',
    'UB73 070',
    'RS75 080',
    'UB74 020',
    'UB74 070',
    'UB73 065',
    'UB75 030',
    'UB74 070',
    'UB74 060',
    'UB74 020',
    'UB73 100',
    'UB74 030',
    'UB75 020',
    'UB75 030',
    'UB63 140',
    'UB63 100',
    'UB63 090',
    'UB73 070',
    'UB63 110',
    'UB63 080',
    'UB63 070',
    'UB73 090',
    'UB73 065',
    'UB75 050',
    'RS75 080',
    'UB63 130',
    'VB73 204',
    'UB73 100',
    'UB73 065',
    'UB63 110',
    'RS75 080',
    'UB74 060',
    'UB63 100',
    'UB63 130',
    'UB74 070',
    'VB73 204',
    'UB63 140',
    'UB74 030',
    'UB75 020',
    'UB75 050',
    'UB74 020',
    'UB75 030',
    'UB73 090',
    'UB63 080',
    'UB73 070',
    'UB63 090',
    'UB63 070',
    'UB63 070',
    'UB63 080',
    'UB63 110',
    'UB74 030',
    'UB63 090',
    'UB63 130',
    'UB63 100',
    'UB63 140',
    'UB74 070',
    'UB73 100',
    'RS75 080',
    'UB73 100',
    'RS75 080',
    'UB73 070',
    'UB73 065',
    'RS75 080',
    'UB75 020',
    'UB74 030',
    'UB75 050',
    'UB73 090',
    'UB75 030',
    'UB73 070',
    'UB74 060',
    'UB74 020',
    'UB73 100',
    'UB74 070',
    'VB73 204',
    'UB75 030',
    'RS75 080',
    'UB75 020',
    'UB73 100',
    'UB75 050',
    'UB74 060',
    'UB74 030',
    'UB73 065',
    'UB74 070',
    'UB73 070',
    'UB74 020',
    'UB73 090',
    'VB73 204',
    'UB74 030',
    'VB73 204',
    'UB73 100',
    'UB75 050',
    'UB74 060',
    'UB74 020',
    'UB73 090',
    'UB74 070',
    'UB75 020',
    'RS75 080',
    'UB73 065',
    'UB73 070',
    'UB75 030',
    'VB73 204',
    'UB75 050',
    'UB74 070',
    'UB74 060',
    'UB75 030',
    'UB75 020',
    'UB74 020',
    'UB73 090',
    'UB73 070',
    'UB73 100',
    'UB74 030',
    'UB73 065',
    'RS75 080',
    'VB73 204',
    'UB73 090',
    'UB74 020',
    'UB75 030',
    'UB73 100',
    'UB74 070',
    'UB73 065',
    'RS75 080',
    'UB75 020',
    'UB75 050',
    'UB74 030',
    'UB74 060',
    'UB73 070',
    'VB73 204',
    'UB73 070',
    'UB74 030',
    'UB75 030',
    'UB74 020',
    'RS75 080',
    'UB73 065',
    'UB74 070',
    'UB75 020',
    'UB75 050',
    'UB73 100',
    'UB74 060',
    'UB73 090',
    'UB74 070',
    'UB75 030',
    'UB73 065',
    'UB74 020',
    'RS75 080',
    'UB73 070',
    'UB75 020',
    'UB75 050',
    'UB74 060',
    'UB73 090',
    'UB74 030',
    'VB73 204',
    'UB73 100',
    'VB73 204',
    'UB75 020',
    'UB73 090',
    'UB73 070',
    'UB74 070',
    'UB73 100',
    'UB75 030',
    'UB73 065',
    'UB74 020',
    'RS75 080',
    'UB75 050',
    'UB74 060',
    'UB74 030',
    'UB75 030',
    'RS75 080',
    'UB75 050',
    'UB73 070',
    'UB73 065',
    'UB75 020',
    'UB73 100',
    'UB74 030',
    'UB74 060',
    'UB73 090',
    'VB73 204',
    'UB74 070',
    'UB74 020',
    'UB73 090',
    'UB73 100',
    'UB75 030',
    'UB74 020',
    'UB74 060',
    'UB75 050',
    'UB74 030',
    'RS75 080',
    'UB74 070',
    'UB73 065',
    'VB73 204',
    'UB73 070',
    'UB75 020',
    'UB74 030',
    'UB73 065',
    'UB74 060',
    'UB75 030',
    'UB75 050',
    'RS75 080',
    'UB74 020',
    'UB73 100',
    'UB75 020',
    'UB73 090',
    'UB74 070',
    'UB73 070',
    'VB73 204',
    'RS75 080',
    'UB75 050',
    'VB73 204',
    'UB74 020',
    'UB74 060',
    'UB75 020',
    'UB73 090',
    'UB74 030',
    'UB75 030',
    'UB73 100',
    'UB73 070',
    'UB73 065',
    'UB74 070',
    'VB73 204',
    'UB75 050',
    'UB74 030',
    'UB74 020',
    'UB73 090',
    'UB74 070',
    'RS75 080',
    'UB75 020',
    'UB73 065',
    'UB73 100',
    'UB73 070',
    'UB75 030',
    'UB74 060',
    'UB74 060',
    'UB73 065',
    'UB75 030',
    'UB73 070',
    'VB73 204',
    'UB73 090',
    'UB74 020',
    'UB75 050',
    'RS75 080',
    'UB74 070',
    'UB73 100',
    'UB74 030',
    'UB73 070',
    'UB75 050',
    'RS75 080',
    'UB75 030',
    'UB73 065',
    'UB74 030',
    'UB75 020',
    'UB73 090',
    'UB74 070',
    'UB73 100',
    'UB74 060',
    'UB74 020',
    'VB73 204',
    'UB74 030',
    'UB74 020',
    'UB74 070',
    'UB73 100',
    'UB75 020',
    'UB73 090',
    'UB75 030',
    'UB73 070',
    'UB75 050',
    'UB73 065',
    'VB73 204',
    'RS75 080',
    'UB74 060',
    'UB73 070',
    'UB73 090',
    'UB75 020',
    'UB73 100',
    'UB74 020',
    'UB75 050',
    'UB74 030',
    'VB73 204',
    'UB74 070',
    'RS75 080',
    'UB73 065',
    'UB74 060',
    'UB75 050',
    'UB74 020',
    'UB74 060',
    'UB74 030',
    'UB73 070',
    'UB73 065',
    'RS75 080',
    'VB73 204',
    'UB75 020',
    'UB73 100',
    'UB73 090',
    'UB74 070',
    'UB74 070',
    'UB75 050',
    'UB73 100',
    'UB73 065',
    'UB74 020',
    'UB75 020',
    'UB75 030',
    'UB74 030',
    'RS75 080',
    'UB73 090',
    'UB73 070',
    'VB73 204',
    'UB74 060',
    'UB74 030',
    'UB73 065',
    'UB74 070',
    'UB73 090',
    'UB73 070',
    'UB74 060',
    'VB73 204',
    'UB75 030',
    'UB74 020',
    'UB75 050',
    'UB73 100',
    'RS75 080',
    'VB73 204',
    'UB73 065',
    'UB74 030',
    'UB73 100',
    'UB75 030',
    'UB74 070',
    'UB74 060',
    'UB73 090',
    'UB74 020',
    'UB73 070',
    'RS75 080',
    'UB75 050',
    'UB75 020',
    'UB75 030',
    'UB73 090',
    'UB73 070',
    'UB73 065',
    'UB73 100',
    'UB74 030',
    'VB73 204',
    'RS75 080',
    'UB74 060',
    'UB74 070',
    'UB74 020',
    'UB75 050',
    'UB74 030',
    'VB73 204',
    'RS75 080',
    'UB74 060',
    'UB73 090',
    'UB73 070',
    'UB74 020',
    'UB73 065',
    'UB75 050',
    'UB75 030',
    'UB75 020',
    'UB73 100',
    'UB74 070',
    'UB74 060',
    'UB73 090',
    'UB75 020',
    'UB74 070',
    'UB73 100',
    'UB74 030',
    'UB74 020',
    'UB73 070',
    'UB75 050',
    'RS75 080',
    'VB73 204',
    'UB75 030',
    'UB73 065',
    'UB74 030',
    'RS75 080',
    'UB75 050',
    'UB75 020',
    'VB73 204',
    'UB74 060',
    'UB75 030',
    'UB74 070',
    'UB74 020',
    'UB73 070',
    'UB73 065',
    'UB63 080',
    'UB63 130',
    'UB63 090',
    'UB74 060',
    'UB63 110',
    'UB75 050',
    'UB63 100',
    'UB73 100',
    'UB74 020',
    'UB63 140',
    'UB74 070',
    'UB73 090',
    'UB73 065',
    'UB75 020',
    'VB73 204',
    'UB75 030',
    'RS75 080',
    'UB74 030',
    'UB63 070',
    'UB73 070',
    'UB63 100',
    'UB73 070',
    'UB63 090',
    'UB74 030',
    'UB63 080',
    'UB63 070',
    'UB74 020',
    'UB74 060',
    'UB75 020',
    'UB63 110',
    'UB73 090',
    'UB74 070',
    'UB75 030',
    'UB73 065',
    'UB75 050',
    'UB73 100',
    'RS75 080',
    'UB63 130',
    'UB63 140',
    'VB73 204',
    'UB74 030',
    'RS75 080',
    'UB63 110',
    'UB63 080',
    'UB74 070',
    'UB63 070',
    'UB63 130',
    'UB73 100',
    'UB63 140',
    'UB63 090',
    'UB63 100',
    'UB73 100',
    'UB73 070',
    'RS75 080',
    'UB75 020',
    'UB73 070',
    'UB74 030',
    'UB73 090',
    'UB75 030',
    'UB73 065',
    'VB73 204',
    'RS75 080',
    'UB74 020',
    'UB75 050',
    'UB74 060',
    'UB73 100',
    'UB74 070',
    'UB74 060',
    'UB75 030',
    'UB75 050',
    'VB73 204',
    'UB75 020',
    'UB74 070',
    'UB74 020',
    'UB73 065',
    'UB73 090',
    'UB74 030',
    'RS75 080',
    'UB73 100',
    'UB73 070',
    'UB75 030',
    'UB73 070',
    'UB74 030',
    'UB73 100',
    'UB73 065',
    'UB73 090',
    'UB75 050',
    'UB74 060',
    'UB74 070',
    'UB74 020',
    'UB75 020',
    'RS75 080',
    'VB73 204',
    'UB73 100',
    'UB73 090',
    'UB75 020',
    'UB73 070',
    'UB74 070',
    'VB73 204',
    'UB75 030',
    'UB73 065',
    'RS75 080',
    'UB74 030',
    'UB74 020',
    'UB75 050',
    'UB74 060',
    'RS75 080',
    'UB74 030',
    'UB73 090',
    'UB75 020',
    'UB73 070',
    'UB74 070',
    'VB73 204',
    'UB74 060',
    'UB74 020',
    'UB75 050',
    'UB75 030',
    'UB73 065',
    'UB73 100',
    'UB74 060',
    'UB73 100',
    'UB75 020',
    'UB73 065',
    'UB75 020',
    'UB74 030',
    'UB75 050',
    'UB74 020',
    'UB73 090',
    'UB73 065',
    'UB74 070',
    'UB75 030',
    'UB73 100',
    'UB74 060',
    'VB73 204',
    'RS75 080',
    'RS75 080',
    'VB73 204',
    'UB73 065',
    'UB75 050',
    'UB75 020',
    'UB73 100',
    'UB74 060',
    'UB74 070',
    'UB73 090',
    'UB74 020',
    'UB73 090',
    'UB74 020',
    'UB73 100',
    'UB75 030',
    'UB74 030',
    'UB74 070',
    'VB73 204',
    'UB73 070',
    'RS75 080',
    'UB75 020',
    'UB74 060',
    'UB75 050',
    'UB73 065',
    'UB74 060',
    'UB74 020',
    'UB73 100',
    'UB75 050',
    'UB73 070',
    'RS75 080',
    'UB75 030',
    'UB75 020',
    'VB73 204',
    'UB73 090',
    'UB73 065',
    'UB74 030',
    'UB74 070',
    'RS75 080',
    'UB74 070',
    'UB74 020',
    'UB74 060',
    'UB73 100',
    'UB73 065',
    'VB73 204',
    'UB73 070',
    'UB75 030',
    'UB74 030',
    'UB73 090',
    'UB75 050',
    'UB75 020',
    'UB74 060',
    'UB75 050',
    'RS75 080',
    'UB74 070',
    'UB73 100',
    'UB74 020',
    'UB75 030',
    'VB73 204',
    'UB73 065',
    'UB74 030',
    'UB73 090',
    'UB75 020',
    'UB73 070',
    'UB74 020',
    'UB74 030',
    'RS75 080',
    'UB75 050',
    'VB73 204',
    'UB74 060',
    'UB73 070',
    'UB73 100',
    'UB74 070',
    'UB75 030',
    'UB73 065',
    'UB73 090',
    'UB75 050',
    'UB73 070',
    'UB74 070',
    'UB75 020',
    'UB73 065',
    'UB74 020',
    'UB74 030',
    'UB75 030',
    'RS75 080',
    'UB74 060',
    'UB73 100',
    'UB73 090',
    'VB73 204',
    'UB73 065',
    'UB75 030',
    'UB73 070',
    'UB73 090',
    'UB75 020',
    'UB73 100',
    'UB74 070',
    'UB74 020',
    'RS75 080',
    'UB74 060',
    'UB74 030',
    'VB73 204',
    'UB75 050',
    'UB73 070',
    'UB74 030',
    'UB73 100',
    'RS75 080',
    'UB75 020',
    'VB73 204',
    'UB74 070',
    'UB74 020',
    'UB75 050',
    'UB75 030',
    'UB73 065',
    'UB74 060',
    'UB73 090',
    'UB73 090',
    'UB75 030',
    'UB73 065',
    'UB73 100',
    'UB75 050',
    'UB73 070',
    'UB74 070',
    'UB75 020',
    'UB74 030',
    'UB74 020',
    'VB73 204',
    'RS75 080',
    'UB74 060',
    'UB75 020',
    'UB73 090',
    'UB74 030',
    'RS75 080',
    'UB75 050',
    'UB73 100',
    'VB73 204',
    'UB75 030',
    'UB73 065',
    'UB74 070',
    'UB73 070',
    'UB74 020',
    'UB73 090',
    'UB75 030',
    'UB73 065',
    'VB73 204',
    'UB74 060',
    'UB73 070',
    'UB73 100',
    'UB74 030',
    'UB74 020',
    'RS75 080',
    'UB74 070',
    'UB75 050',
    'UB73 065',
    'UB74 070',
    'UB75 030',
    'UB73 100',
    'UB74 020',
    'UB73 070',
    'UB73 090',
    'UB74 060',
    'RS75 080',
    'VB73 204',
    'UB75 050',
    'UB74 030',
    'UB74 070',
    'UB73 090',
    'UB73 100',
    'UB74 020',
    'UB74 060',
    'UB74 030',
    'UB75 020',
    'UB75 030',
    'UB73 070',
    'VB73 204',
    'RS75 080',
    'UB73 065',
    'UB75 050',
    'UB74 070',
    'VB73 204',
    'UB75 020',
    'RS75 080',
    'UB74 060',
    'UB73 070',
    'UB74 030',
    'UB75 030',
    'UB74 020',
    'UB73 065',
    'UB73 100',
    'UB75 050',
    'UB73 090',
    'UB74 070',
    'UB73 090',
    'UB73 100',
    'RS75 080',
    'UB75 050',
    'UB75 020',
    'UB74 060',
    'UB74 030',
    'UB75 030',
    'VB73 204',
    'UB73 070',
    'UB73 065',
    'UB74 020',
    'UB74 020',
    'UB75 030',
    'UB75 050',
    'UB73 065',
    'UB74 030',
    'UB74 060',
    'RS75 080',
    'UB73 070',
    'VB73 204',
    'UB75 020',
    'UB74 070',
    'UB74 060',
    'UB74 030',
    'UB73 100',
    'RS75 080',
    'VB73 204',
    'UB63 070',
    'UB74 020',
    'UB75 050',
    'UB75 030',
    'UB73 065',
    'UB63 110',
    'UB74 070',
    'UB63 080',
    'UB63 130',
    'UB73 070',
    'UB63 090',
    'UB63 100',
    'UB63 140',
    'UB73 090',
    'UB75 020',
    'UB74 070',
    'UB63 100',
    'RS75 080',
    'UB63 130',
    'UB74 020',
    'UB73 070',
    'VB73 204',
    'UB74 030',
    'UB63 140',
    'UB73 065',
    'UB75 020',
    'UB74 060',
    'UB63 110',
    'UB63 070',
    'UB63 090',
    'UB75 030',
    'UB73 090',
    'UB75 050',
    'UB73 100',
    'UB63 080',
    'UB63 080',
    'RS75 080',
    'UB74 030',
    'UB63 130',
    'UB63 140',
    'UB73 100',
    'UB63 070',
    'UB63 110',
    'UB63 090',
    'UB63 100',
    'UB74 070',
    'UB73 070',
    'RS75 080',
    'UB73 100',
    'UB74 070',
    'UB73 065',
    'UB75 030',
    'UB74 030',
    'UB73 070',
    'VB73 204',
    'UB74 020',
    'UB73 090',
    'UB75 020',
    'UB73 100',
    'UB75 050',
    'RS75 080',
    'UB74 060',
    'UB74 030',
    'UB74 070',
    'UB75 020',
    'UB75 050',
    'UB75 030',
    'VB73 204',
    'UB74 060',
    'UB74 020',
    'RS75 080',
    'UB73 100',
    'UB73 065',
    'UB73 070',
    'UB73 090',
    'UB73 100',
    'UB74 070',
    'VB73 204',
    'UB75 020',
    'UB74 060',
    'UB73 070',
    'UB75 030',
    'UB73 065',
    'RS75 080',
    'UB75 050',
    'UB74 020',
    'UB73 090',
    'UB74 030',
    'UB75 020',
    'UB73 090',
    'UB73 100',
    'UB74 030',
    'UB73 065',
    'UB73 070',
    'UB74 060',
    'UB74 070',
    'VB73 204',
    'RS75 080',
    'UB75 050',
    'UB74 020',
    'UB75 030',
    'UB74 070',
    'UB73 090',
    'VB73 204',
    'UB74 020',
    'UB74 030',
    'UB73 065',
    'UB75 030',
    'RS75 080',
    'UB75 050',
    'UB73 100',
    'UB73 070',
    'UB75 020',
    'UB74 060',
    'UB73 065',
    'UB73 100',
    'UB75 020',
    'UB74 060',
    'UB75 050',
    'RS75 080',
    'UB75 030',
    'UB73 065',
    'UB75 020',
    'UB74 060',
    'UB74 030',
    'UB74 020',
    'UB73 090',
    'UB73 100',
    'VB73 204',
    'UB74 070',
    'UB74 060',
    'UB75 020',
    'UB74 070',
    'UB73 065',
    'VB73 204',
    'UB74 020',
    'UB73 100',
    'UB75 050',
    'RS75 080',
    'UB73 090',
    'RS75 080',
    'UB75 030',
    'UB73 100',
    'UB73 090',
    'VB73 204',
    'UB73 065',
    'UB74 020',
    'UB75 020',
    'UB75 050',
    'UB74 070',
    'UB73 070',
    'UB74 030',
    'UB74 060',
    'UB75 030',
    'UB75 050',
    'UB74 070',
    'VB73 204',
    'RS75 080',
    'UB74 020',
    'UB74 060',
    'UB73 100',
    'UB74 030',
    'UB73 065',
    'UB73 070',
    'UB75 020',
    'UB73 090',
    'UB74 020',
    'UB74 030',
    'UB74 060',
    'UB75 020',
    'RS75 080',
    'UB73 070',
    'VB73 204',
    'UB74 070',
    'UB75 030',
    'UB73 065',
    'UB73 100',
    'UB73 090',
    'UB75 050',
    'UB73 100',
    'UB73 065',
    'UB73 070',
    'UB75 050',
    'RS75 080',
    'UB74 020',
    'UB73 090',
    'UB74 060',
    'UB75 020',
    'UB75 030',
    'VB73 204',
    'UB74 070',
    'UB74 030',
    'UB74 070',
    'UB74 020',
    'UB73 065',
    'UB75 030',
    'UB74 030',
    'UB75 020',
    'VB73 204',
    'UB73 100',
    'UB75 050',
    'UB73 090',
    'UB73 070',
    'RS75 080',
    'UB74 060',
    'UB74 020',
    'UB75 050',
    'UB74 030',
    'RS75 080',
    'VB73 204',
    'UB75 030',
    'UB73 065',
    'UB73 100',
    'UB75 020',
    'UB74 060',
    'UB73 070',
    'UB74 070',
    'UB73 090',
    'UB74 030',
    'UB73 100',
    'UB74 060',
    'UB73 090',
    'UB73 070',
    'UB74 020',
    'UB75 020',
    'UB73 065',
    'UB75 030',
    'RS75 080',
    'UB74 070',
    'VB73 204',
    'UB73 065',
    'UB75 030',
    'UB73 070',
    'UB73 090',
    'UB75 020',
    'UB73 100',
    'UB74 070',
    'UB74 020',
    'RS75 080',
    'UB74 060',
    'UB74 030',
    'VB73 204',
    'UB75 050',
    'UB73 070',
    'UB74 030',
    'UB73 100',
    'RS75 080',
    'UB75 020',
    'VB73 204',
    'UB74 070',
    'UB74 020',
    'UB75 050',
    'UB75 030',
    'UB73 065',
    'UB74 060',
    'UB73 090',
    'UB73 090',
    'UB75 030',
    'UB73 065',
    'UB73 100',
    'UB75 050',
    'UB73 070',
    'UB74 070',
    'UB75 020',
    'UB74 030',
    'UB74 020',
    'VB73 204',
    'RS75 080',
    'UB74 060',
    'UB75 020',
    'UB73 090',
    'UB74 030',
    'RS75 080',
    'UB75 050',
    'UB73 100',
    'VB73 204',
    'UB75 030',
    'UB73 065',
    'UB74 070',
    'UB73 070',
    'UB74 020',
    'UB73 090',
    'UB75 030',
    'UB73 065',
    'VB73 204',
    'UB74 060',
    'UB73 070',
    'UB73 100',
    'UB74 030',
    'UB74 020',
    'RS75 080',
    'UB74 070',
    'UB75 050',
    'UB73 065',
    'UB74 070',
    'UB75 030',
    'UB73 100',
    'UB74 020',
    'UB73 070',
    'UB73 090',
    'UB74 060',
    'RS75 080',
    'UB75 020',
    'VB73 204',
    'UB75 050',
    'UB74 030',
    'UB74 070',
    'UB73 090',
    'UB73 100',
    'UB74 020',
    'UB74 060',
    'UB74 030',
    'UB75 020',
    'UB75 030',
    'UB73 070',
    'VB73 204',
    'RS75 080',
    'UB73 065',
    'UB75 050',
    'UB74 070',
    'VB73 204',
    'UB75 020',
    'RS75 080',
    'UB74 060',
    'UB73 070',
    'UB74 030',
    'UB75 030',
    'UB74 020',
    'UB73 065',
    'UB73 100',
    'UB75 050',
    'UB73 090',
    'UB74 070',
    'UB73 090',
    'UB73 100',
    'RS75 080',
    'UB75 050',
    'UB75 020',
    'UB74 060',
    'UB74 030',
    'UB75 030',
    'VB73 204',
    'UB73 070',
    'UB73 065',
    'UB74 020',
    'UB74 020',
    'UB75 030',
    'UB75 050',
    'UB73 065',
    'UB73 100',
    'UB73 090',
    'UB74 030',
    'UB74 060',
    'RS75 080',
    'UB73 070',
    'VB73 204',
    'UB75 020',
    'UB74 070',
    'UB74 060',
    'UB74 030',
    'UB73 100',
    'RS75 080',
    'VB73 204',
    'UB63 070',
    'UB74 020',
    'UB75 050',
    'UB75 030',
    'UB73 065',
    'UB63 110',
    'UB74 070',
    'UB63 080',
    'UB63 130',
    'UB73 070',
    'UB63 090',
    'UB63 100',
    'UB63 140',
    'UB73 090',
    'UB75 020',
    'UB74 070',
    'UB63 100',
    'RS75 080',
    'UB63 130',
    'UB74 020',
    'UB73 070',
    'VB73 204',
    'UB74 030',
    'UB63 140',
    'UB73 065',
    'UB75 020',
    'UB74 060',
    'UB63 110',
    'UB63 070',
    'UB63 090',
    'UB75 030',
    'UB73 090',
    'UB75 050',
    'UB73 100',
    'UB63 080',
    'UB63 080',
    'RS75 080',
    'UB74 030',
    'UB63 130',
    'UB63 140',
    'UB73 100',
    'UB63 070',
    'UB63 110',
    'UB63 090',
    'UB63 100',
    'UB74 070',
    'UB73 070',
    'RS75 080',
    'UB73 100',
    'UB74 070',
    'UB73 065',
    'UB75 030',
    'UB74 030',
    'UB73 070',
    'VB73 204',
    'UB74 020',
    'UB73 090',
    'UB75 020',
    'UB73 100',
    'UB75 050',
    'RS75 080',
    'UB74 060',
    'UB74 030',
    'UB74 070',
    'UB75 020',
    'UB75 050',
    'UB75 030',
    'VB73 204',
    'UB74 060',
    'UB74 020',
    'RS75 080',
    'UB73 100',
    'UB73 065',
    'UB73 070',
    'UB73 090',
    'UB73 100',
    'UB74 070',
    'VB73 204',
    'UB75 020',
    'UB74 060',
    'UB73 070',
    'UB75 030',
    'UB73 065',
    'RS75 080',
    'UB75 050',
    'UB74 020',
    'UB73 090',
    'UB74 030',
    'UB75 020',
    'UB73 090',
    'UB73 100',
    'UB74 030',
    'UB73 065',
    'UB73 070',
    'UB74 060',
    'UB74 070',
    'VB73 204',
    'RS75 080',
    'UB75 050',
    'UB74 020',
    'UB75 030',
    'UB74 070',
    'UB73 090',
    'VB73 204',
    'UB74 020',
    'UB74 030',
    'UB73 065',
    'UB75 030',
    'RS75 080',
    'UB75 050',
    'UB73 100',
    'UB73 070',
    'UB75 020',
    'UB74 060',
    'UB73 065',
    'UB73 100',
    'UB75 020',
    'UB74 060',
    'UB75 050',
    'RS75 080',
    'UB75 030',
    'UB73 065',
    'UB75 020',
    'UB74 060',
    'UB74 030',
    'UB74 020',
    'UB73 090',
    'UB73 100',
    'VB73 204',
    'UB74 070',
    'UB74 060',
    'UB75 020',
    'UB74 070',
    'UB73 065',
    'VB73 204',
    'UB74 020',
    'UB73 100',
    'UB75 050',
    'RS75 080',
    'UB73 090',
    'RS75 080',
    'UB75 030',
    'UB73 100',
    'UB73 090',
    'VB73 204',
    'UB73 065',
    'UB74 020',
    'UB75 020',
    'UB75 050',
    'UB74 070',
    'UB73 070',
    'UB74 030',
    'UB74 060',
    'UB75 030',
    'UB75 050',
    'UB74 070',
    'VB73 204',
    'RS75 080',
    'UB74 020',
    'UB74 060',
    'UB73 100',
    'UB74 030',
    'UB73 065',
    'UB73 070',
    'UB75 020',
    'UB73 090',
    'UB74 020',
    'UB74 030',
    'UB74 060',
    'UB75 020',
    'RS75 080',
    'UB73 070',
    'VB73 204',
    'UB74 070',
    'UB75 030',
    'UB73 065',
    'UB73 100',
    'UB73 090',
    'UB75 050',
    'UB73 100',
    'UB73 065',
    'UB73 070',
    'UB75 050',
    'RS75 080',
    'UB74 020',
    'UB73 090',
    'UB74 060',
    'UB75 020',
    'UB75 030',
    'VB73 204',
    'UB74 070',
    'UB74 030',
    'UB74 070',
    'UB74 020',
    'UB73 065',
    'UB75 030',
    'UB74 030',
    'UB75 020',
    'VB73 204',
    'UB73 100',
    'UB75 050',
    'UB73 090',
    'UB73 070',
    'RS75 080',
    'UB74 060',
    'UB74 020',
    'UB75 050',
    'UB74 030',
    'RS75 080',
    'VB73 204',
    'UB75 030',
    'UB73 065',
    'UB73 100',
    'UB75 020',
    'UB74 060',
    'UB73 070',
    'UB74 070',
    'UB73 090',
    'UB74 030',
    'UB73 100',
    'UB74 060',
    'UB73 090',
    'UB73 070',
    'UB74 020',
    'UB75 020',
    'UB73 065',
    'UB75 030',
    'RS75 080',
    'UB74 070',
    'VB73 204',
    'UB74 060',
    'UB74 030',
    'UB75 020',
    'UB74 020',
    'UB75 030',
    'UB73 100',
    'UB73 090',
    'UB75 050',
    'UB73 070',
    'UB73 065',
    'VB73 204',
    'RS75 080',
    'UB74 070',
    'UB73 070',
    'UB73 090',
    'UB74 020',
    'UB73 065',
    'UB75 050',
    'UB75 030',
    'UB74 070',
    'UB73 100',
    'UB75 020',
    'UB74 060',
    'UB74 030',
    'RS75 080',
    'VB73 204',
    'UB75 030',
    'UB73 090',
    'UB74 030',
    'UB75 020',
    'UB73 065',
    'VB73 204',
    'UB73 100',
    'UB74 020',
    'UB74 070',
    'UB75 050',
    'RS75 080',
    'UB73 070',
    'UB74 060',
    'UB74 070',
    'UB73 065',
    'UB75 050',
    'UB75 020',
    'UB73 090',
    'UB74 030',
    'VB73 204',
    'UB73 070',
    'UB73 100',
    'UB74 020',
    'RS75 080',
    'UB75 030',
    'UB73 070',
    'UB74 020',
    'UB75 050',
    'RS75 080',
    'UB74 070',
    'UB73 065',
    'VB73 204',
    'UB74 060',
    'UB75 030',
    'UB73 090',
    'UB73 100',
    'UB74 030',
    'UB73 065',
    'UB75 050',
    'UB73 090',
    'UB73 070',
    'UB73 100',
    'UB75 030',
    'UB74 020',
    'UB74 070',
    'UB74 030',
    'RS75 080',
    'UB75 020',
    'VB73 204',
    'UB74 060',
    'UB73 070',
    'UB75 050',
    'RS75 080',
    'UB74 030',
    'VB73 204',
    'UB73 100',
    'UB74 020',
    'UB73 090',
    'UB73 065',
    'UB75 020',
    'UB74 060',
    'UB74 070',
    'UB75 030',
    'UB73 070',
    'UB73 090',
    'UB74 060',
    'UB74 070',
    'RS75 080',
    'UB75 030',
    'UB74 020',
    'UB75 050',
    'UB75 020',
    'UB73 065',
    'UB74 030',
    'VB73 204',
    'UB73 100',
    'UB75 020',
    'UB73 065',
    'UB73 090',
    'UB75 050',
    'UB75 030',
    'UB74 020',
    'UB74 060',
    'UB73 100',
    'VB73 204',
    'UB74 030',
    'RS75 080',
    'UB74 070',
    'UB73 070',
    'UB74 020',
    'UB74 070',
    'UB74 060',
    'UB73 100',
    'UB74 030',
    'UB75 020',
    'UB75 030',
    'UB73 090',
    'UB75 050',
    'UB73 070',
    'RS75 080',
    'VB73 204',
    'UB73 065',
    'UB63 140',
    'UB63 080',
    'UB73 100',
    'UB74 070',
    'UB75 030',
    'RS75 080',
    'UB73 065',
    'UB63 110',
    'UB75 050',
    'UB74 030',
    'UB73 070',
    'UB75 020',
    'UB63 130',
    'UB74 020',
    'VB73 204',
    'UB63 090',
    'UB63 070',
    'UB73 090',
    'UB74 060',
    'UB63 100',
    'UB63 130',
    'UB75 020',
    'UB63 080',
    'UB74 060',
    'UB63 140',
    'UB74 070',
    'UB63 090',
    'UB73 100',
    'UB73 065',
    'UB74 020',
    'UB74 030',
    'RS75 080',
    'UB75 030',
    'VB73 204',
    'UB63 100',
    'UB75 050',
    'UB73 090',
    'UB63 070',
    'UB73 070',
    'UB63 110',
    'UB63 110',
    'UB63 140',
    'UB63 090',
    'UB74 020',
    'UB63 080',
    'UB63 130',
    'UB75 020',
    'UB63 100',
    'UB73 100',
    'UB63 070',
    'UB75 030',
    'UB75 050',
    'UB73 090',
    'RS75 080',
    'UB74 030',
    'VB73 204',
    'UB73 070',
    'UB74 070',
    'UB74 060',
    'UB73 065',
    'UB73 070',
    'VB73 204',
    'UB74 060',
    'UB73 090',
    'UB75 020',
    'RS75 080',
    'UB75 050',
    'UB73 065',
    'UB73 100',
    'UB74 070',
    'UB74 030',
    'UB74 020',
    'UB75 030',
    'RS75 080',
    'UB74 070',
    'VB73 204',
    'UB74 030',
    'UB74 060',
    'UB73 100',
    'UB73 065',
    'UB75 030',
    'UB75 020',
    'UB73 070',
    'UB73 090',
    'UB75 050',
    'UB74 020',
    'UB73 065',
    'UB75 050',
    'UB73 090',
    'UB73 070',
    'VB73 204',
    'UB74 060',
    'UB73 100',
    'UB74 070',
    'UB75 030',
    'RS75 080',
    'UB74 030',
    'UB75 020',
    'UB74 020',
    'VB73 204',
    'UB73 090',
    'UB74 060',
    'UB75 030',
    'UB73 065',
    'UB74 030',
    'UB75 020',
    'UB75 050',
    'UB73 070',
    'UB73 100',
    'UB74 070',
    'UB74 020',
    'RS75 080',
    'UB74 030',
    'UB74 060',
    'VB73 204',
    'UB75 050',
    'UB75 030',
    'RS75 080',
    'UB73 070',
    'UB73 090',
    'UB73 065',
    'UB74 020',
    'UB75 020',
    'UB73 100',
    'UB74 070',
    'UB75 050',
    'UB75 020',
    'UB75 030',
    'UB74 070',
    'UB74 020',
    'UB73 100',
    'UB73 065',
    'UB73 070',
    'RS75 080',
    'UB74 030',
    'UB74 060',
    'UB73 090',
    'VB73 204',
    'UB74 020',
    'UB75 020',
    'UB74 070',
    'UB74 030',
    'UB73 100',
    'RS75 080',
    'UB75 050',
    'UB73 090',
    'UB73 065',
    'UB73 070',
    'UB75 030',
    'UB74 060',
    'UB74 060',
    'UB73 100',
    'UB75 020',
    'UB73 065',
    'UB75 030',
    'UB75 050',
    'RS75 080',
    'UB73 065',
    'UB73 100',
    'UB74 020',
    'UB75 020',
    'UB73 090',
    'UB74 070',
    'VB73 204',
    'UB74 060',
    'UB74 030',
    'UB75 020',
    'UB74 070',
    'RS75 080',
    'UB73 090',
    'UB73 100',
    'VB73 204',
    'UB74 060',
    'UB74 020',
    'UB73 065',
    'UB75 050',
    'UB74 060',
    'RS75 080',
    'VB73 204',
    'UB75 050',
    'UB73 065',
    'UB74 030',
    'UB73 070',
    'UB75 030',
    'UB73 090',
    'UB75 020',
    'UB74 020',
    'UB74 070',
    'UB73 100',
    'RS75 080',
    'UB73 070',
    'UB73 065',
    'UB75 030',
    'UB74 020',
    'UB73 100',
    'VB73 204',
    'UB74 060',
    'UB75 050',
    'UB74 070',
    'UB73 090',
    'UB75 020',
    'UB74 030',
    'UB74 020',
    'UB75 020',
    'UB73 065',
    'UB75 030',
    'UB74 070',
    'UB74 030',
    'UB73 100',
    'VB73 204',
    'UB73 070',
    'UB74 060',
    'RS75 080',
    'UB75 050',
    'UB73 090',
    'UB73 065',
    'UB74 070',
    'VB73 204',
    'UB73 100',
    'RS75 080',
    'UB75 030',
    'UB75 020',
    'UB73 070',
    'UB74 060',
    'UB73 090',
    'UB74 030',
    'UB75 050',
    'UB74 020',
    'UB75 050',
    'VB73 204',
    'UB74 060',
    'UB74 020',
    'UB75 020',
    'UB73 100',
    'UB73 090',
    'UB73 070',
    'UB75 030',
    'RS75 080',
    'UB74 070',
    'UB74 030',
    'UB73 065',
    'UB74 020',
    'UB75 020',
    'VB73 204',
    'UB73 100',
    'UB74 030',
    'UB74 060',
    'UB74 070',
    'RS75 080',
    'UB75 030',
    'UB73 090',
    'UB73 070',
    'UB73 065',
    'UB75 050',
    'RS75 080',
    'UB74 060',
    'UB74 030',
    'UB73 065',
    'UB74 020',
    'UB75 020',
    'UB74 070',
    'UB73 100',
    'UB73 070',
    'UB73 090',
    'UB75 030',
    'VB73 204',
    'VB73 204',
    'UB75 050',
    'UB73 070',
    'RS75 080',
    'UB75 030',
    'UB73 090',
    'UB73 100',
    'UB75 020',
    'UB74 020',
    'UB73 065',
    'UB74 060',
    'UB74 070',
    'UB74 030',
    'UB74 070',
    'UB73 090',
    'UB73 070',
    'UB73 065',
    'UB74 030',
    'UB73 100',
    'RS75 080',
    'UB74 060',
    'UB75 050',
    'UB75 020',
    'UB74 020',
    'VB73 204',
    'UB75 030',
    'UB75 050',
    'UB73 070',
    'UB74 060',
    'UB75 030',
    'UB73 065',
    'UB74 070',
    'VB73 204',
    'UB74 020',
    'UB74 030',
    'UB73 090',
    'UB73 100',
    'RS75 080',
    'UB75 020',
    'UB74 070',
    'UB74 020',
    'UB73 065',
    'UB75 020',
    'UB73 100',
    'UB73 070',
    'UB74 030',
    'UB75 030',
    'UB75 050',
    'UB73 090',
    'VB73 204',
    'RS75 080',
    'UB75 050',
    'UB73 100',
    'UB74 030',
    'UB74 070',
    'UB75 030',
    'UB74 020',
    'UB74 060',
    'VB73 204',
    'UB73 065',
    'UB73 090',
    'RS75 080',
    'UB73 070',
    'UB74 070',
    'UB73 100',
    'UB73 065',
    'UB74 020',
    'UB74 060',
    'UB74 030',
    'UB75 030',
    'RS75 080',
    'UB75 020',
    'UB75 050',
    'VB73 204',
    'UB73 070',
    'UB73 090',
    'UB73 070',
    'RS75 080',
    'UB75 030',
    'UB74 020',
    'UB74 060',
    'VB73 204',
    'UB73 090',
    'UB75 050',
    'UB75 020',
    'UB74 030',
    'UB73 065',
    'UB74 070',
    'UB73 100',
    'VB73 204',
    'UB75 050',
    'UB74 030',
    'UB75 020',
    'UB75 030',
    'UB73 070',
    'RS75 080',
    'UB73 090',
    'UB73 100',
    'UB74 060',
    'UB74 070',
    'UB74 020',
    'UB73 065',
    'UB75 030',
    'UB74 060',
    'UB75 020',
    'UB74 020',
    'UB74 030',
    'UB74 070',
    'UB75 050',
    'UB73 090',
    'RS75 080',
    'VB73 204',
    'UB73 100',
    'UB73 065',
    'UB73 070',
    'UB75 050',
    'UB74 070',
    'UB74 060',
    'UB74 030',
    'UB75 030',
    'UB73 090',
    'UB73 070',
    'UB75 020',
    'RS75 080',
    'UB73 100',
    'VB73 204',
    'UB74 020',
    'UB73 065',
    'UB75 020',
    'UB73 090',
    'UB73 100',
    'UB75 030',
    'UB74 060',
    'UB75 050',
    'UB63 070',
    'UB63 080',
    'UB63 090',
    'UB63 100',
    'UB63 110',
    'UB63 130',
    'UB74 030',
    'VB73 204',
    'UB73 065',
    'UB74 020',
    'UB74 070',
    'RS75 080',
    'UB73 070',
    'UB63 140',
    'UB74 020',
    'VB73 204',
    'UB73 100',
    'UB74 070',
    'UB74 060',
    'UB74 030',
    'RS75 080',
    'UB73 065',
    'UB63 130',
    'UB75 020',
    'UB63 110',
    'UB63 100',
    'UB73 090',
    'UB73 070',
    'UB75 030',
    'UB63 080',
    'UB63 070',
    'UB75 050',
    'UB63 090',
    'UB63 140',
    'UB63 140',
    'UB74 020',
    'UB73 065',
    'RS75 080',
    'UB63 110',
    'UB63 130',
    'UB74 030',
    'UB63 100',
    'UB63 090',
    'UB63 080',
    'UB63 070',
    'UB75 030',
    'UB75 020',
    'UB74 060',
    'UB73 090',
    'UB73 100',
    'VB73 204',
    'UB73 070',
    'UB74 070',
    'UB75 050',
    'UB73 100',
    'RS75 080',
    'UB74 020',
    'UB75 020',
    'UB74 030',
    'UB73 065',
    'UB74 070',
    'UB75 050',
    'UB73 070',
    'UB74 060',
    'UB73 090',
    'UB75 030',
    'VB73 204',
    'UB73 065',
    'UB75 020',
    'UB75 030',
    'UB73 090',
    'UB73 070',
    'RS75 080',
    'VB73 204',
    'UB74 060',
    'UB75 050',
    'UB74 070',
    'UB74 030',
    'UB74 020',
    'UB73 100',
    'UB74 030',
    'UB73 090',
    'UB75 020',
    'UB75 050',
    'RS75 080',
    'UB73 065',
    'UB75 030',
    'UB74 060',
    'UB73 100',
    'UB73 070',
    'UB74 070',
    'UB74 020',
    'VB73 204',
    'UB74 070',
    'UB75 020',
    'UB75 030',
    'VB73 204',
    'UB75 050',
    'UB74 060',
    'UB74 020',
    'UB73 100',
    'UB73 065',
    'UB73 090',
    'UB74 030',
    'RS75 080',
    'UB73 070',
    'UB73 070',
    'VB73 204',
    'UB74 070',
    'UB75 030',
    'UB73 090',
    'UB73 065',
    'UB73 100',
    'UB75 050',
    'UB74 020',
    'UB75 020',
    'RS75 080',
    'UB74 030',
    'UB74 060',
    'UB74 020',
    'UB73 100',
    'UB74 060',
    'UB75 030',
    'UB74 070',
    'UB73 070',
    'UB74 030',
    'UB73 090',
    'UB75 020',
    'UB73 065',
    'RS75 080',
    'UB75 050',
    'UB74 060',
    'UB73 090',
    'VB73 204',
    'RS75 080',
    'UB74 030',
    'UB73 100',
    'UB75 020',
    'UB73 070',
    'UB74 070',
    'UB75 030',
    'UB74 020',
    'UB75 050',
    'UB73 065',
    'VB73 204',
    'VB73 204',
    'RS75 080',
    'RS75 080',
    'VB73 204',
    'UB73 090',
    'RS75 080',
    'UB73 065',
    'UB75 050',
    'UB74 020',
    'UB74 070',
    'VB73 204',
    'UB73 100',
    'UB74 060',
    'UB75 020',
    'UB75 030',
    'UB73 065',
    'RS75 080',
    'UB74 030',
    'UB74 070',
    'VB73 204',
    'UB75 020',
    'UB73 100',
    'UB74 060',
    'UB75 050',
    'UB74 020',
    'UB73 090',
    'VB73 204',
    'UB74 020',
    'UB73 065',
    'UB75 030',
    'UB75 050',
    'RS75 080',
    'UB73 090',
    'UB73 100',
    'UB74 030',
    'UB74 060',
    'UB73 070',
    'UB74 070',
    'UB75 020',
    'UB75 030',
    'VB73 204',
    'UB74 020',
    'UB75 050',
    'RS75 080',
    'UB73 065',
    'UB73 100',
    'UB74 030',
    'UB73 070',
    'UB74 070',
    'UB73 090',
    'UB75 020',
    'UB74 060',
    'RS75 080',
    'UB75 050',
    'UB73 070',
    'UB73 100',
    'UB75 030',
    'UB73 065',
    'UB74 030',
    'UB75 020',
    'UB73 090',
    'VB73 204',
    'UB74 070',
    'UB74 060',
    'UB74 020',
    'UB74 030',
    'VB73 204',
    'UB75 050',
    'UB74 060',
    'RS75 080',
    'UB73 100',
    'UB75 030',
    'UB74 070',
    'UB73 090',
    'UB75 020',
    'UB73 070',
    'UB73 065',
    'UB74 020',
    'UB74 030',
    'UB75 020',
    'UB75 050',
    'RS75 080',
    'UB74 020',
    'UB74 060',
    'UB75 030',
    'UB73 065',
    'VB73 204',
    'UB73 070',
    'UB73 100',
    'UB73 090',
    'UB74 070',
    'UB74 020',
    'UB74 060',
    'UB73 100',
    'UB73 090',
    'UB75 020',
    'UB73 070',
    'UB74 030',
    'UB73 065',
    'UB75 030',
    'RS75 080',
    'UB74 070',
    'VB73 204',
];

export const values = [
    15.483333333333333,
    146.96666666666675,
    358.1666666666671,
    123.0166666666668,
    250.65000000000038,
    288.18333333333345,
    146.74999999999994,
    429.2666666666664,
    177.01666666666665,
    310.2166666666667,
    44.18333333333333,
    320.7166666666663,
    14.483333333333333,
    25.566666666666666,
    303.98333333333335,
    419.09999999999945,
    315.93333333333294,
    14.433333333333332,
    70.68333333333327,
    30.53333333333334,
    272.69999999999857,
    136.23333333333318,
    277.59999999999985,
    339.51666666666745,
    313.93333333333453,
    275.2333333333337,
    67.33333333333331,
    47.74999999999999,
    308.8166666666665,
    27.4,
    392.40000000000015,
    251.25000000000043,
    290.13333333333327,
    166.86666666666687,
    281.33333333333314,
    349.0833333333337,
    233.1666666666669,
    238.60000000000042,
    26.41666666666666,
    588.0999999999995,
    32.83333333333333,
    452.28333333333336,
    291.0499999999999,
    486.5333333333336,
    62.416666666666615,
    343.43333333333175,
    323.4499999999998,
    547.9833333333339,
    375.3166666666661,
    296.73333333333363,
    692.1166666666707,
    470.3000000000005,
    863.8999999999965,
    43.96666666666669,
    39.11666666666665,
    699.0333333333334,
    166.5,
    726.8666666666671,
    625.1833333333353,
    565.5166666666645,
    829.6000000000038,
    523.766666666665,
    826.5166666666668,
    485.19999999999794,
    557.5000000000023,
    40.6,
    693.7000000000003,
    607.6166666666667,
    828.8333333333371,
    646.8833333333356,
    42.53333333333335,
    518.1499999999997,
    748.5666666666687,
    159.63333333333333,
    830.6500000000043,
    18.599999999999987,
    639.6000000000013,
    500.4666666666682,
    538.8833333333307,
    80.01666666666667,
    34.31666666666666,
    456.9166666666645,
    659.0999999999998,
    633.2166666666694,
    770.4666666666703,
    462.48333333333284,
    847.916666666671,
    522.1166666666676,
    743.1500000000003,
    584.1500000000002,
    24.18333333333333,
    582.8166666666673,
    29.73333333333333,
    49.21666666666666,
    503.6666666666643,
    563.9666666666685,
    5.416666666666668,
    537.2666666666669,
    312.6999999999995,
    29.05,
    251.1499999999992,
    315.03333333333325,
    613.749999999999,
    556.6833333333342,
    809.1666666666669,
    2.8166666666666664,
    474.96666666666687,
    351.69999999999914,
    483.7333333333331,
    289.74999999999983,
    348.03333333333387,
    345.8333333333335,
    353.6500000000003,
    182.56666666666646,
    329.68333333333305,
    14.4,
    479.11666666666633,
    599.1833333333327,
    179.60000000000053,
    171.8833333333337,
    454.6999999999996,
    175.89999999999995,
    273.46666666666647,
    146.0999999999999,
    1.9666666666666663,
    116.71666666666664,
    299.93333333333334,
    257.4166666666667,
    248.4166666666666,
    2.85,
    105.31666666666668,
    3.5166666666666666,
    25.08333333333333,
    72.78333333333333,
    96.51666666666664,
    12.366666666666667,
    37.73333333333333,
    255.63333333333333,
    2.8,
    29.016666666666666,
    14.166666666666668,
    28.8,
    21.283333333333328,
    1.8166666666666669,
    1.3833333333333333,
    20.75,
    170.03333333333333,
    88.46666666666668,
    51.516666666666644,
    1.4,
    13.633333333333335,
    222.78333333333336,
    219.60000000000008,
    233.6166666666669,
    184.7666666666668,
    289.8999999999998,
    227.65000000000043,
    93.01666666666662,
    252.08333333333326,
    49.81666666666666,
    378.13333333333327,
    174.7000000000003,
    309.6500000000001,
    17.833333333333332,
    263.71666666666704,
    344.06666666666734,
    292.2833333333333,
    405.5000000000007,
    329.4999999999997,
    266.4666666666664,
    200.9499999999997,
    402.1166666666665,
    19.716666666666665,
    439.86666666666673,
    521.583333333333,
    35.11666666666665,
    51.40000000000002,
    255.283333333334,
    378.566666666667,
    643.116666666667,
    211.24999999999957,
    381.36666666666565,
    431.93333333333356,
    25.733333333333334,
    363.48333333333284,
    433.216666666666,
    482.1166666666681,
    16.583333333333332,
    386.333333333334,
    71.6333333333333,
    71.16666666666666,
    306.28333333333313,
    253.2166666666661,
    25.716666666666665,
    508.53333333333137,
    264.16666666666663,
    320.833333333334,
    413.1833333333335,
    434.19999999999936,
    283.0833333333332,
    326.58333333333303,
    17.88333333333333,
    216.23333333333358,
    324.11666666666696,
    643.8333333333334,
    319.466666666667,
    564.9166666666666,
    232.133333333333,
    294.7166666666668,
    262.88333333333344,
    491.2666666666674,
    330.4666666666668,
    4.283333333333333,
    393.40000000000055,
    0.43333333333333335,
    8.883333333333333,
    108.68333333333328,
    195.28333333333333,
    284.8666666666667,
    154.08333333333331,
    0.45,
    6.366666666666667,
    81.18333333333334,
    30.99999999999999,
    1.3666666666666667,
    4.266666666666667,
    19.466666666666665,
    1.15,
    46.45000000000002,
    32.1,
    1.4666666666666666,
    30.10000000000001,
    29.133333333333336,
    43.08333333333334,
    5.6000000000000005,
    387.5000000000004,
    263.84999999999957,
    297.83333333333337,
    8.100000000000001,
    89.56666666666668,
    417.1999999999999,
    286.7,
    466.8666666666678,
    14.133333333333333,
    220.34999999999968,
    318.09999999999746,
    536.0333333333343,
    247.8333333333333,
    480.13333333333344,
    13.933333333333335,
    221.93333333333354,
    234.3,
    12.433333333333332,
    241.83333333333348,
    34.11666666666667,
    93.38333333333328,
    225.94999999999985,
    272.4333333333328,
    269.2333333333344,
    171.9500000000001,
    629.1166666666671,
    776.8499999999982,
    480.4,
    388.31666666666644,
    37.13333333333335,
    271.58333333333326,
    8.433333333333334,
    397.96666666666624,
    672.5833333333339,
    33.83333333333333,
    419.9500000000004,
    339.4333333333337,
    548.7000000000003,
    548.3000000000005,
    499.116666666667,
    738.9666666666672,
    379.31666666666644,
    351.2833333333333,
    28.38333333333333,
    432.8833333333336,
    225.55000000000013,
    13.833333333333336,
    14.083333333333332,
    482.7333333333338,
    459.23333333333267,
    485.0333333333329,
    459.7333333333338,
    8.600000000000003,
    332.1833333333332,
    300.6666666666664,
    219.8666666666669,
    321.98333333333403,
    17.783333333333335,
    7.583333333333334,
    345.63333333333304,
    246.833333333333,
    220.65000000000003,
    383.45000000000056,
    528.4833333333331,
    280.4000000000003,
    401.8000000000004,
    349.0500000000004,
    293.3000000000003,
    53.233333333333384,
    18.483333333333334,
    12.550000000000004,
    192.0166666666666,
    371.5166666666668,
    311.9666666666667,
    49.35,
    227.2999999999993,
    355.0166666666668,
    29.01666666666667,
    333.3833333333332,
    178.83333333333326,
    776.6166666666691,
    420.41666666666674,
    208.14999999999992,
    312.3666666666663,
    495.5166666666669,
    355.46666666666727,
    18.899999999999988,
    314.0999999999996,
    35.59999999999999,
    282.21666666666755,
    768.5166666666704,
    111.60000000000014,
    39.85000000000001,
    26.13333333333333,
    649.0833333333361,
    654.3666666666667,
    762.6333333333357,
    862.1166666666642,
    760.2166666666689,
    680.3666666666667,
    626.8666666666637,
    350.74999999999903,
    665.5000000000027,
    749.4000000000034,
    706.9500000000024,
    32.41666666666665,
    147.5833333333334,
    548.1166666666644,
    614.6500000000036,
    688.7000000000005,
    722.4666666666693,
    934.6333333333354,
    634.4000000000004,
    27.4,
    540.4333333333316,
    730.183333333336,
    828.9666666666674,
    989.0166666666679,
    716.3666666666693,
    814.8500000000038,
    690.1833333333369,
    549.5666666666635,
    917.766666666669,
    638.8000000000002,
    48.89999999999997,
    110.15000000000008,
    443.5666666666671,
    43.3166666666667,
    288.18333333333345,
    250.65000000000038,
    44.18333333333333,
    146.74999999999994,
    123.0166666666668,
    320.7166666666663,
    310.2166666666667,
    177.01666666666665,
    358.1666666666671,
    146.96666666666675,
    15.483333333333333,
    14.483333333333333,
    429.2666666666664,
    419.09999999999945,
    303.98333333333335,
    315.93333333333294,
    25.566666666666666,
    136.23333333333318,
    70.68333333333327,
    14.433333333333332,
    339.51666666666745,
    30.53333333333334,
    313.93333333333453,
    272.69999999999857,
    277.59999999999985,
    281.33333333333314,
    392.40000000000015,
    47.74999999999999,
    349.0833333333337,
    290.13333333333327,
    308.8166666666665,
    27.4,
    251.25000000000043,
    233.1666666666669,
    67.33333333333331,
    275.2333333333337,
    166.86666666666687,
    238.60000000000042,
    547.9833333333339,
    588.0999999999995,
    32.83333333333333,
    343.43333333333175,
    375.3166666666661,
    62.416666666666615,
    26.41666666666666,
    323.4499999999998,
    452.28333333333336,
    296.73333333333363,
    291.0499999999999,
    486.5333333333336,
    39.11666666666665,
    166.5,
    43.96666666666669,
    565.5166666666645,
    726.8666666666671,
    523.766666666665,
    863.8999999999965,
    470.3000000000005,
    625.1833333333353,
    699.0333333333334,
    829.6000000000038,
    692.1166666666707,
    607.6166666666667,
    693.7000000000003,
    159.63333333333333,
    485.19999999999794,
    828.8333333333371,
    826.5166666666668,
    748.5666666666687,
    40.6,
    646.8833333333356,
    557.5000000000023,
    42.53333333333335,
    518.1499999999997,
    270.41666666666595,
    209.01666666666657,
    10.549999999999997,
    410.333333333334,
    188.30000000000027,
    245.51666666666642,
    226.13333333333333,
    265.63333333333344,
    62.1,
    279.1166666666666,
    18.35,
    346.88333333333327,
    657.2666666666668,
    284.6166666666667,
    267.3666666666658,
    11.266666666666667,
    493.2833333333336,
    209.31666666666675,
    293.8333333333332,
    22.73333333333333,
    337.38333333333287,
    463.08333333333366,
    741.0833333333335,
    574.2666666666671,
    217.23333333333363,
    34.43333333333333,
    273.58333333333303,
    253.99999999999932,
    339.01666666666665,
    261.4333333333332,
    303.7999999999999,
    284.1500000000001,
    24.266666666666666,
    240.2666666666671,
    355.96666666666636,
    263.53333333333353,
    44.09999999999999,
    587.0666666666659,
    63.49999999999997,
    607.3666666666667,
    227.54999999999973,
    46.01666666666666,
    523.7999999999995,
    439.6999999999989,
    86.90000000000003,
    407.5500000000005,
    29.383333333333336,
    379.93333333333254,
    304.9333333333332,
    276.2666666666668,
    129.66666666666606,
    149.06666666666624,
    362.3333333333329,
    30.266666666666666,
    288.05000000000007,
    316.63333333333327,
    399.46666666666704,
    203.7666666666669,
    395.74999999999915,
    208.55000000000004,
    505.166666666666,
    498.8666666666678,
    160.83333333333337,
    430.5333333333329,
    177.01666666666648,
    154.50000000000009,
    52.68333333333332,
    592.7833333333333,
    76.98333333333333,
    479.00000000000114,
    361.6333333333332,
    147.0833333333332,
    54.61666666666663,
    257.90000000000003,
    158.98333333333338,
    151.2,
    252.90000000000006,
    231.25000000000043,
    147.78333333333345,
    135.73333333333358,
    199.86666666666625,
    303.1500000000004,
    126.76666666666605,
    9.65,
    15.250000000000002,
    152.19999999999996,
    232.6000000000001,
    169.65000000000012,
    192.90000000000006,
    284.71666666666624,
    13.733333333333334,
    70.63333333333338,
    65.66666666666673,
    76.11666666666672,
    2.833333333333333,
    43.24999999999999,
    6.466666666666666,
    68.71666666666667,
    83.48333333333333,
    61.63333333333331,
    116.6166666666669,
    49.81666666666666,
    184.7666666666668,
    227.65000000000043,
    93.01666666666662,
    252.08333333333326,
    309.6500000000001,
    174.7000000000003,
    17.833333333333332,
    222.78333333333336,
    233.6166666666669,
    378.13333333333327,
    289.8999999999998,
    219.60000000000008,
    266.4666666666664,
    35.11666666666665,
    329.4999999999997,
    439.86666666666673,
    521.583333333333,
    405.5000000000007,
    19.716666666666665,
    292.2833333333333,
    51.40000000000002,
    402.1166666666665,
    263.71666666666704,
    200.9499999999997,
    344.06666666666734,
    482.1166666666681,
    71.6333333333333,
    381.36666666666565,
    363.48333333333284,
    25.733333333333334,
    386.333333333334,
    378.566666666667,
    431.93333333333356,
    211.24999999999957,
    16.583333333333332,
    255.283333333334,
    433.216666666666,
    643.116666666667,
    413.1833333333335,
    17.88333333333333,
    508.53333333333137,
    71.16666666666666,
    326.58333333333303,
    216.23333333333358,
    283.0833333333332,
    320.833333333334,
    306.28333333333313,
    25.716666666666665,
    264.16666666666663,
    434.19999999999936,
    253.2166666666661,
    393.40000000000055,
    232.133333333333,
    319.466666666667,
    643.8333333333334,
    324.11666666666696,
    564.9166666666666,
    491.2666666666674,
    262.88333333333344,
    330.4666666666668,
    4.283333333333333,
    0.43333333333333335,
    294.7166666666668,
    30.99999999999999,
    195.28333333333333,
    154.08333333333331,
    6.366666666666667,
    284.8666666666667,
    8.883333333333333,
    108.68333333333328,
    81.18333333333334,
    0.45,
    29.133333333333336,
    1.4666666666666666,
    32.1,
    19.466666666666665,
    1.15,
    43.08333333333334,
    4.266666666666667,
    30.10000000000001,
    1.3666666666666667,
    46.45000000000002,
    466.8666666666678,
    5.6000000000000005,
    14.133333333333333,
    417.1999999999999,
    536.0333333333343,
    297.83333333333337,
    318.09999999999746,
    387.5000000000004,
    263.84999999999957,
    220.34999999999968,
    89.56666666666668,
    286.7,
    8.100000000000001,
    234.3,
    171.9500000000001,
    34.11666666666667,
    12.433333333333332,
    13.933333333333335,
    221.93333333333354,
    225.94999999999985,
    269.2333333333344,
    93.38333333333328,
    241.83333333333348,
    480.13333333333344,
    247.8333333333333,
    272.4333333333328,
    37.13333333333335,
    548.7000000000003,
    776.8499999999982,
    629.1166666666671,
    271.58333333333326,
    388.31666666666644,
    8.433333333333334,
    397.96666666666624,
    480.4,
    33.83333333333333,
    672.5833333333339,
    339.4333333333337,
    419.9500000000004,
    432.8833333333336,
    499.116666666667,
    13.833333333333336,
    351.2833333333333,
    14.083333333333332,
    548.3000000000005,
    485.0333333333329,
    225.55000000000013,
    482.7333333333338,
    459.23333333333267,
    28.38333333333333,
    738.9666666666672,
    379.31666666666644,
    321.98333333333403,
    7.583333333333334,
    219.8666666666669,
    459.7333333333338,
    8.600000000000003,
    345.63333333333304,
    220.65000000000003,
    300.6666666666664,
    246.833333333333,
    17.783333333333335,
    383.45000000000056,
    528.4833333333331,
    332.1833333333332,
    192.0166666666666,
    53.233333333333384,
    311.9666666666667,
    227.2999999999993,
    293.3000000000003,
    349.0500000000004,
    355.0166666666668,
    18.483333333333334,
    12.550000000000004,
    280.4000000000003,
    401.8000000000004,
    49.35,
    371.5166666666668,
    29.01666666666667,
    35.59999999999999,
    495.5166666666669,
    208.14999999999992,
    355.46666666666727,
    178.83333333333326,
    776.6166666666691,
    282.21666666666755,
    420.41666666666674,
    18.899999999999988,
    333.3833333333332,
    312.3666666666663,
    314.0999999999996,
    760.2166666666689,
    768.5166666666704,
    665.5000000000027,
    111.60000000000014,
    654.3666666666667,
    762.6333333333357,
    626.8666666666637,
    350.74999999999903,
    862.1166666666642,
    26.13333333333333,
    39.85000000000001,
    680.3666666666667,
    649.0833333333361,
    540.4333333333316,
    749.4000000000034,
    706.9500000000024,
    614.6500000000036,
    147.5833333333334,
    548.1166666666644,
    32.41666666666665,
    934.6333333333354,
    27.4,
    688.7000000000005,
    634.4000000000004,
    722.4666666666693,
    638.8000000000002,
    443.5666666666671,
    730.183333333336,
    110.15000000000008,
    48.89999999999997,
    716.3666666666693,
    828.9666666666674,
    917.766666666669,
    549.5666666666635,
    690.1833333333369,
    989.0166666666679,
    814.8500000000038,
    43.3166666666667,
    250.65000000000038,
    146.74999999999994,
    123.0166666666668,
    429.2666666666664,
    358.1666666666671,
    146.96666666666675,
    177.01666666666665,
    15.483333333333333,
    320.7166666666663,
    44.18333333333333,
    14.483333333333333,
    288.18333333333345,
    310.2166666666667,
    277.59999999999985,
    313.93333333333453,
    14.433333333333332,
    303.98333333333335,
    339.51666666666745,
    272.69999999999857,
    419.09999999999945,
    136.23333333333318,
    30.53333333333334,
    70.68333333333327,
    315.93333333333294,
    25.566666666666666,
    67.33333333333331,
    290.13333333333327,
    27.4,
    251.25000000000043,
    281.33333333333314,
    233.1666666666669,
    349.0833333333337,
    166.86666666666687,
    47.74999999999999,
    392.40000000000015,
    308.8166666666665,
    275.2333333333337,
    62.416666666666615,
    32.83333333333333,
    238.60000000000042,
    291.0499999999999,
    486.5333333333336,
    323.4499999999998,
    588.0999999999995,
    343.43333333333175,
    547.9833333333339,
    296.73333333333363,
    452.28333333333336,
    26.41666666666666,
    375.3166666666661,
    699.0333333333334,
    829.6000000000038,
    726.8666666666671,
    863.8999999999965,
    39.11666666666665,
    43.96666666666669,
    470.3000000000005,
    523.766666666665,
    166.5,
    565.5166666666645,
    692.1166666666707,
    625.1833333333353,
    485.19999999999794,
    607.6166666666667,
    646.8833333333356,
    828.8333333333371,
    693.7000000000003,
    40.6,
    557.5000000000023,
    826.5166666666668,
    518.1499999999997,
    748.5666666666687,
    42.53333333333335,
    159.63333333333333,
    18.35,
    188.30000000000027,
    62.1,
    209.01666666666657,
    10.549999999999997,
    410.333333333334,
    226.13333333333333,
    270.41666666666595,
    657.2666666666668,
    245.51666666666642,
    346.88333333333327,
    265.63333333333344,
    279.1166666666666,
    463.08333333333366,
    337.38333333333287,
    11.266666666666667,
    493.2833333333336,
    217.23333333333363,
    22.73333333333333,
    284.6166666666667,
    34.43333333333333,
    574.2666666666671,
    209.31666666666675,
    293.8333333333332,
    267.3666666666658,
    741.0833333333335,
    263.53333333333353,
    284.1500000000001,
    44.09999999999999,
    253.99999999999932,
    355.96666666666636,
    261.4333333333332,
    339.01666666666665,
    303.7999999999999,
    24.266666666666666,
    273.58333333333303,
    240.2666666666671,
    63.49999999999997,
    587.0666666666659,
    29.383333333333336,
    607.3666666666667,
    523.7999999999995,
    439.6999999999989,
    379.93333333333254,
    46.01666666666666,
    227.54999999999973,
    276.2666666666668,
    304.9333333333332,
    86.90000000000003,
    407.5500000000005,
    479.00000000000114,
    430.5333333333329,
    316.63333333333327,
    498.8666666666678,
    395.74999999999915,
    592.7833333333333,
    399.46666666666704,
    208.55000000000004,
    177.01666666666648,
    203.7666666666669,
    52.68333333333332,
    129.66666666666606,
    154.50000000000009,
    160.83333333333337,
    505.166666666666,
    76.98333333333333,
    362.3333333333329,
    288.05000000000007,
    149.06666666666624,
    30.266666666666666,
    303.1500000000004,
    54.61666666666663,
    126.76666666666605,
    135.73333333333358,
    252.90000000000006,
    169.65000000000012,
    158.98333333333338,
    147.0833333333332,
    15.250000000000002,
    199.86666666666625,
    231.25000000000043,
    361.6333333333332,
    284.71666666666624,
    147.78333333333345,
    151.2,
    152.19999999999996,
    192.90000000000006,
    9.65,
    257.90000000000003,
    232.6000000000001,
    70.63333333333338,
    65.66666666666673,
    61.63333333333331,
    6.466666666666666,
    83.48333333333333,
    68.71666666666667,
    76.11666666666672,
    116.6166666666669,
    2.833333333333333,
    43.24999999999999,
    13.733333333333334,
    92.46666666666668,
    9.566666666666666,
    1.4333333333333331,
    123.85000000000008,
    1.2833333333333334,
    101.29999999999984,
    295.6499999999993,
    172.1666666666665,
    276.6000000000001,
    129.83333333333314,
    23.95,
    187.46666666666718,
    219.71666666666687,
    460.3999999999985,
    214.98333333333287,
    26.93333333333333,
    336.4666666666675,
    202.53333333333356,
    376.2999999999992,
    455.9333333333332,
    272.3333333333321,
    385.4833333333335,
    370.6499999999994,
    35.34999999999999,
    310.1499999999998,
    49.73333333333332,
    267.65,
    248.4333333333333,
    20.266666666666673,
    442.5333333333327,
    20.583333333333325,
    554.8166666666671,
    328.23333333333295,
    449.5666666666674,
    385.73333333333335,
    311.033333333333,
    361.36666666666684,
    526.0833333333333,
    270.54999999999933,
    73.93333333333334,
    15.05,
    345.3000000000007,
    19.899999999999995,
    273.066666666666,
    407.75000000000097,
    369.8666666666665,
    533.2833333333342,
    543.45,
    360.24999999999983,
    518.9833333333331,
    61.2,
    636.2500000000006,
    319.8666666666665,
    67.39999999999996,
    306.23333333333346,
    14.716666666666665,
    357.7499999999996,
    293.3333333333333,
    399.2666666666669,
    327.63333333333344,
    334.2333333333337,
    55.16666666666666,
    237.99999999999974,
    369.04999999999984,
    241.6333333333339,
    295.7666666666663,
    264.39999999999975,
    26.43333333333333,
    8.100000000000001,
    5.6000000000000005,
    264.2166666666662,
    387.5000000000004,
    286.7,
    89.83333333333334,
    14.133333333333333,
    536.0333333333343,
    220.34999999999968,
    298.05000000000007,
    466.8666666666678,
    318.6166666666642,
    417.1999999999999,
    225.94999999999985,
    247.8333333333333,
    34.11666666666667,
    221.93333333333354,
    171.9500000000001,
    13.933333333333335,
    93.38333333333328,
    272.4333333333328,
    269.2333333333344,
    234.3,
    241.83333333333348,
    12.433333333333332,
    480.13333333333344,
    37.13333333333335,
    548.7000000000003,
    480.4,
    8.433333333333334,
    339.4333333333337,
    419.9500000000004,
    629.1166666666671,
    33.83333333333333,
    271.58333333333326,
    388.31666666666644,
    776.8499999999982,
    672.5833333333339,
    397.96666666666624,
    548.3000000000005,
    225.55000000000013,
    499.116666666667,
    13.833333333333336,
    28.38333333333333,
    738.9666666666672,
    482.7333333333338,
    379.31666666666644,
    485.0333333333329,
    459.23333333333267,
    14.083333333333332,
    432.8833333333336,
    351.2833333333333,
    332.1833333333332,
    459.7333333333338,
    321.98333333333403,
    219.8666666666669,
    383.45000000000056,
    300.6666666666664,
    345.63333333333304,
    220.65000000000003,
    246.833333333333,
    17.783333333333335,
    8.600000000000003,
    7.583333333333334,
    528.4833333333331,
    192.0166666666666,
    49.35,
    227.2999999999993,
    293.3000000000003,
    412.9333333333338,
    53.233333333333384,
    280.4000000000003,
    355.0166666666668,
    349.0500000000004,
    371.5166666666668,
    311.9666666666667,
    18.483333333333334,
    12.550000000000004,
    208.14999999999992,
    495.5166666666669,
    18.899999999999988,
    312.3666666666663,
    282.21666666666755,
    178.83333333333326,
    333.3833333333332,
    314.0999999999996,
    355.46666666666727,
    420.41666666666674,
    29.01666666666667,
    35.59999999999999,
    776.6166666666691,
    39.85000000000001,
    768.5166666666704,
    665.5000000000027,
    626.8666666666637,
    654.3666666666667,
    649.0833333333361,
    350.74999999999903,
    762.6333333333357,
    111.60000000000014,
    862.1166666666642,
    26.13333333333333,
    680.3666666666667,
    760.2166666666689,
    749.4000000000034,
    147.5833333333334,
    688.7000000000005,
    27.4,
    32.41666666666665,
    634.4000000000004,
    548.1166666666644,
    614.6500000000036,
    540.4333333333316,
    722.4666666666693,
    934.6333333333354,
    706.9500000000024,
    48.89999999999997,
    730.183333333336,
    443.5666666666671,
    828.9666666666674,
    110.15000000000008,
    690.1833333333369,
    989.0166666666679,
    638.8000000000002,
    716.3666666666693,
    917.766666666669,
    814.8500000000038,
    549.5666666666635,
    43.3166666666667,
    250.65000000000038,
    123.0166666666668,
    146.74999999999994,
    358.1666666666671,
    429.2666666666664,
    146.96666666666675,
    177.01666666666665,
    15.483333333333333,
    320.7166666666663,
    44.18333333333333,
    14.483333333333333,
    288.18333333333345,
    310.2166666666667,
    25.566666666666666,
    303.98333333333335,
    313.93333333333453,
    419.09999999999945,
    315.93333333333294,
    339.51666666666745,
    136.23333333333318,
    14.433333333333332,
    277.59999999999985,
    70.68333333333327,
    30.53333333333334,
    272.69999999999857,
    349.0833333333337,
    251.25000000000043,
    275.2333333333337,
    233.1666666666669,
    47.74999999999999,
    67.33333333333331,
    166.86666666666687,
    27.4,
    281.33333333333314,
    392.40000000000015,
    308.8166666666665,
    290.13333333333327,
    291.0499999999999,
    343.43333333333175,
    486.5333333333336,
    62.416666666666615,
    238.60000000000042,
    588.0999999999995,
    547.9833333333339,
    375.3166666666661,
    296.73333333333363,
    323.4499999999998,
    32.83333333333333,
    26.41666666666666,
    452.28333333333336,
    692.1166666666707,
    166.5,
    625.1833333333353,
    699.0333333333334,
    39.11666666666665,
    829.6000000000038,
    43.96666666666669,
    726.8666666666671,
    565.5166666666645,
    523.766666666665,
    863.8999999999965,
    470.3000000000005,
    42.53333333333335,
    159.63333333333333,
    646.8833333333356,
    826.5166666666668,
    693.7000000000003,
    557.5000000000023,
    828.8333333333371,
    607.6166666666667,
    485.19999999999794,
    40.6,
    518.1499999999997,
    748.5666666666687,
    657.2666666666668,
    226.13333333333333,
    209.01666666666657,
    62.1,
    18.35,
    410.333333333334,
    265.63333333333344,
    10.549999999999997,
    245.51666666666642,
    346.88333333333327,
    279.1166666666666,
    188.30000000000027,
    270.41666666666595,
    337.38333333333287,
    11.266666666666667,
    209.31666666666675,
    493.2833333333336,
    284.6166666666667,
    22.73333333333333,
    217.23333333333363,
    34.43333333333333,
    267.3666666666658,
    463.08333333333366,
    741.0833333333335,
    574.2666666666671,
    293.8333333333332,
    303.7999999999999,
    261.4333333333332,
    355.96666666666636,
    273.58333333333303,
    587.0666666666659,
    263.53333333333353,
    240.2666666666671,
    63.49999999999997,
    284.1500000000001,
    253.99999999999932,
    24.266666666666666,
    339.01666666666665,
    44.09999999999999,
    379.93333333333254,
    227.54999999999973,
    439.6999999999989,
    607.3666666666667,
    29.383333333333336,
    523.7999999999995,
    407.5500000000005,
    304.9333333333332,
    276.2666666666668,
    46.01666666666666,
    86.90000000000003,
    154.50000000000009,
    149.06666666666624,
    203.7666666666669,
    430.5333333333329,
    129.66666666666606,
    362.3333333333329,
    177.01666666666648,
    498.8666666666678,
    316.63333333333327,
    208.55000000000004,
    479.00000000000114,
    505.166666666666,
    76.98333333333333,
    592.7833333333333,
    30.266666666666666,
    399.46666666666704,
    288.05000000000007,
    395.74999999999915,
    160.83333333333337,
    52.68333333333332,
    169.65000000000012,
    9.65,
    257.90000000000003,
    231.25000000000043,
    192.90000000000006,
    232.6000000000001,
    147.78333333333345,
    252.90000000000006,
    361.6333333333332,
    126.76666666666605,
    152.19999999999996,
    147.0833333333332,
    151.2,
    54.61666666666663,
    284.71666666666624,
    303.1500000000004,
    135.73333333333358,
    158.98333333333338,
    199.86666666666625,
    15.250000000000002,
    6.466666666666666,
    13.733333333333334,
    61.63333333333331,
    65.66666666666673,
    2.833333333333333,
    70.63333333333338,
    68.71666666666667,
    43.24999999999999,
    116.6166666666669,
    83.48333333333333,
    76.11666666666672,
    92.46666666666668,
    1.4333333333333331,
    9.566666666666666,
    101.29999999999984,
    23.95,
    295.6499999999993,
    276.6000000000001,
    129.83333333333314,
    123.85000000000008,
    26.93333333333333,
    1.2833333333333334,
    219.71666666666687,
    172.1666666666665,
    187.46666666666718,
    460.3999999999985,
    214.98333333333287,
    385.4833333333335,
    336.4666666666675,
    272.3333333333321,
    20.266666666666673,
    376.2999999999992,
    310.1499999999998,
    267.65,
    35.34999999999999,
    248.4333333333333,
    370.6499999999994,
    202.53333333333356,
    455.9333333333332,
    49.73333333333332,
    345.3000000000007,
    15.05,
    442.5333333333327,
    554.8166666666671,
    73.93333333333334,
    311.033333333333,
    328.23333333333295,
    449.5666666666674,
    361.36666666666684,
    385.73333333333335,
    526.0833333333333,
    270.54999999999933,
    20.583333333333325,
    636.2500000000006,
    518.9833333333331,
    543.45,
    61.2,
    407.75000000000097,
    19.899999999999995,
    533.2833333333342,
    67.39999999999996,
    306.23333333333346,
    319.8666666666665,
    360.24999999999983,
    273.066666666666,
    369.8666666666665,
    237.99999999999974,
    295.7666666666663,
    357.7499999999996,
    369.04999999999984,
    26.43333333333333,
    334.2333333333337,
    14.716666666666665,
    264.39999999999975,
    293.3333333333333,
    241.6333333333339,
    399.2666666666669,
    55.16666666666666,
    327.63333333333344,
    5.766666666666668,
    1.4166666666666667,
    15.283333333333335,
    11.4,
    174.41666666666669,
    23.91666666666666,
    314.4333333333332,
    33.93333333333332,
    168.3166666666667,
    16.3,
    50.65000000000002,
    223.85,
    29.049999999999994,
    185.9333333333333,
    26.300000000000008,
    106.43333333333342,
    7.95,
    2.716666666666667,
    4.15,
    1.1833333333333331,
    5.883333333333333,
    2.75,
    1.45,
    14.616666666666667,
    2,
    1.3833333333333333,
    38.533333333333346,
    19.866666666666664,
    157.0833333333334,
    47.25000000000001,
    104.3666666666666,
    26.600000000000005,
    7.483333333333333,
    3.3666666666666667,
    51.38333333333336,
    276.23333333333335,
    114.94999999999992,
    63.333333333333385,
    19.516666666666666,
    418.0499999999985,
    494.03333333333086,
    678.966666666667,
    642.3833333333369,
    26.96666666666667,
    284.4166666666667,
    697.2499999999997,
    666.9000000000004,
    29.36666666666667,
    714.8666666666693,
    93.73333333333342,
    385.34999999999985,
    576.7333333333353,
    373.3833333333329,
    570.1000000000017,
    535.6333333333308,
    511.1333333333329,
    780.2000000000012,
    97.45000000000003,
    35.75000000000002,
    44.66666666666665,
    626.5333333333335,
    516.7666666666663,
    668.0333333333348,
    665.3166666666713,
    549.8499999999998,
    761.4833333333354,
    769.0000000000039,
    350.74999999999903,
    649.0833333333361,
    862.5999999999974,
    626.8666666666637,
    680.3666666666667,
    39.85000000000001,
    111.60000000000014,
    665.5000000000027,
    654.3666666666667,
    763.266666666669,
    26.13333333333333,
    548.1166666666644,
    706.9500000000024,
    540.4333333333316,
    614.6500000000036,
    33.69999999999998,
    749.4000000000034,
    27.4,
    934.6333333333354,
    722.4666666666693,
    688.7000000000005,
    147.5833333333334,
    634.4000000000004,
    730.183333333336,
    48.89999999999997,
    716.3666666666693,
    989.0166666666679,
    110.15000000000008,
    549.5666666666635,
    690.1833333333369,
    828.9666666666674,
    443.5666666666671,
    814.8500000000038,
    917.766666666669,
    638.8000000000002,
    43.3166666666667,
    65.98333333333332,
    365.916666666667,
    29.583333333333336,
    299.91666666666663,
    326.73333333333335,
    315.2500000000003,
    290.05,
    227.6166666666671,
    238.5833333333331,
    328.76666666666665,
    175.53333333333322,
    25.416666666666675,
    406.7833333333333,
    80.46666666666664,
    279.16666666666646,
    719.2999999999986,
    251.58333333333331,
    658.7833333333349,
    36.00000000000001,
    486.1000000000008,
    517.1833333333324,
    526.7000000000012,
    555.3833333333333,
    66.16666666666664,
    453.41666666666464,
    600.0500000000005,
    467.48333333333295,
    430.7833333333338,
    97.80000000000008,
    520.0166666666671,
    449.0333333333341,
    49.18333333333332,
    355.71666666666687,
    427.55,
    285.3333333333335,
    285.7500000000004,
    35.51666666666668,
    256.8000000000002,
    313.71666666666715,
    528.6666666666669,
    323.4499999999998,
    338.2666666666663,
    275.8166666666669,
    305.8166666666664,
    448.4166666666672,
    22.86666666666666,
    431.11666666666696,
    60.999999999999964,
    291.0499999999999,
    32.83333333333333,
    233.7833333333337,
    699.0333333333334,
    728.4666666666672,
    167.9,
    43.96666666666669,
    830.8333333333369,
    71.61666666666666,
    868.266666666663,
    693.2000000000039,
    565.5166666666645,
    470.3000000000005,
    625.1833333333353,
    524.9833333333316,
    159.63333333333333,
    557.5000000000023,
    693.7000000000003,
    826.5166666666668,
    485.19999999999794,
    40.6,
    607.6166666666667,
    828.8333333333371,
    518.1499999999997,
    42.53333333333335,
    748.5666666666687,
    646.8833333333356,
    279.1166666666666,
    209.01666666666657,
    410.333333333334,
    188.30000000000027,
    346.88333333333327,
    265.63333333333344,
    657.2666666666668,
    226.13333333333333,
    62.1,
    10.549999999999997,
    245.51666666666642,
    18.35,
    270.41666666666595,
    293.8333333333332,
    11.266666666666667,
    741.0833333333335,
    209.31666666666675,
    493.2833333333336,
    22.73333333333333,
    337.38333333333287,
    463.08333333333366,
    217.23333333333363,
    34.43333333333333,
    574.2666666666671,
    267.3666666666658,
    284.6166666666667,
    273.58333333333303,
    261.4333333333332,
    587.0666666666659,
    253.99999999999932,
    284.1500000000001,
    355.96666666666636,
    303.7999999999999,
    263.53333333333353,
    339.01666666666665,
    24.266666666666666,
    63.49999999999997,
    44.09999999999999,
    240.2666666666671,
    276.2666666666668,
    407.5500000000005,
    439.6999999999989,
    86.90000000000003,
    379.93333333333254,
    523.7999999999995,
    227.54999999999973,
    46.01666666666666,
    29.383333333333336,
    607.3666666666667,
    304.9333333333332,
    430.5333333333329,
    395.74999999999915,
    498.8666666666678,
    288.05000000000007,
    30.266666666666666,
    160.83333333333337,
    316.63333333333327,
    362.3333333333329,
    399.46666666666704,
    76.98333333333333,
    129.66666666666606,
    479.00000000000114,
    154.50000000000009,
    149.06666666666624,
    52.68333333333332,
    203.7666666666669,
    177.01666666666648,
    208.55000000000004,
    505.166666666666,
    592.7833333333333,
    147.0833333333332,
    169.65000000000012,
    135.73333333333358,
    158.98333333333338,
    147.78333333333345,
    9.65,
    15.250000000000002,
    231.25000000000043,
    199.86666666666625,
    54.61666666666663,
    361.6333333333332,
    252.90000000000006,
    126.76666666666605,
    232.6000000000001,
    257.90000000000003,
    151.2,
    152.19999999999996,
    284.71666666666624,
    303.1500000000004,
    192.90000000000006,
    65.66666666666673,
    13.733333333333334,
    6.466666666666666,
    68.71666666666667,
    116.6166666666669,
    43.24999999999999,
    70.63333333333338,
    61.63333333333331,
    83.48333333333333,
    76.11666666666672,
    2.833333333333333,
    1.4333333333333331,
    9.566666666666666,
    92.46666666666668,
    214.98333333333287,
    123.85000000000008,
    129.83333333333314,
    295.6499999999993,
    23.95,
    26.93333333333333,
    219.71666666666687,
    276.6000000000001,
    101.29999999999984,
    460.3999999999985,
    172.1666666666665,
    1.2833333333333334,
    187.46666666666718,
    370.6499999999994,
    310.1499999999998,
    376.2999999999992,
    272.3333333333321,
    336.4666666666675,
    20.266666666666673,
    385.4833333333335,
    267.65,
    202.53333333333356,
    455.9333333333332,
    35.34999999999999,
    49.73333333333332,
    248.4333333333333,
    554.8166666666671,
    361.36666666666684,
    20.583333333333325,
    526.0833333333333,
    449.5666666666674,
    15.05,
    345.3000000000007,
    73.93333333333334,
    270.54999999999933,
    328.23333333333295,
    385.73333333333335,
    311.033333333333,
    442.5333333333327,
    543.45,
    518.9833333333331,
    636.2500000000006,
    319.8666666666665,
    67.39999999999996,
    61.2,
    369.8666666666665,
    407.75000000000097,
    19.899999999999995,
    306.23333333333346,
    273.066666666666,
    360.24999999999983,
    533.2833333333342,
    334.2333333333337,
    357.7499999999996,
    14.716666666666665,
    293.3333333333333,
    295.7666666666663,
    55.16666666666666,
    399.2666666666669,
    237.99999999999974,
    241.6333333333339,
    327.63333333333344,
    26.43333333333333,
    369.04999999999984,
    264.39999999999975,
    11.4,
    1.4166666666666667,
    15.283333333333335,
    5.766666666666668,
    314.4333333333332,
    106.43333333333342,
    223.85,
    16.3,
    174.41666666666669,
    185.9333333333333,
    23.91666666666666,
    33.93333333333332,
    168.3166666666667,
    29.049999999999994,
    26.300000000000008,
    50.65000000000002,
    1.45,
    5.883333333333333,
    14.616666666666667,
    4.15,
    2.716666666666667,
    1.3833333333333333,
    2.75,
    1.1833333333333331,
    7.95,
    2,
    51.38333333333336,
    47.25000000000001,
    157.0833333333334,
    38.533333333333346,
    7.483333333333333,
    19.516666666666666,
    19.866666666666664,
    276.23333333333335,
    63.333333333333385,
    26.600000000000005,
    3.3666666666666667,
    104.3666666666666,
    114.94999999999992,
    697.2499999999997,
    642.3833333333369,
    576.7333333333353,
    29.36666666666667,
    284.4166666666667,
    494.03333333333086,
    418.0499999999985,
    678.966666666667,
    385.34999999999985,
    93.73333333333342,
    26.96666666666667,
    666.9000000000004,
    714.8666666666693,
    535.6333333333308,
    516.7666666666663,
    511.1333333333329,
    549.8499999999998,
    373.3833333333329,
    44.66666666666665,
    35.75000000000002,
    570.1000000000017,
    626.5333333333335,
    97.45000000000003,
    780.2000000000012,
    668.0333333333348,
    665.3166666666713,
    421.4000000000016,
    38.58333333333333,
    21.966666666666665,
    476.16666666667084,
    180.7666666666668,
    313.18333333333334,
    343.46666666666664,
    404.1333333333334,
    426.25000000000017,
    343.2000000000004,
    19.533333333333328,
    314.33333333333337,
    350.8833333333332,
    269.5833333333331,
    261.7833333333336,
    100.53333333333336,
    289.3333333333336,
    325.21666666666647,
    644.6333333333333,
    16.78333333333333,
    396.93333333333305,
    308.599999999999,
    301.8499999999998,
    10.7,
    238.43333333333337,
    355.01666666666665,
    528.4833333333312,
    605.5666666666716,
    533.7666666666674,
    425.26666666666483,
    45.83333333333337,
    745.8999999999987,
    111.48333333333342,
    736.3333333333313,
    866.1833333333337,
    663.900000000002,
    45.449999999999974,
    637.0000000000022,
    617.4000000000002,
    97.4166666666666,
    254.4333333333334,
    107.28333333333332,
    273.4833333333335,
    44.91666666666664,
    110.0166666666668,
    265.73333333333323,
    42.98333333333331,
    312.20000000000044,
    183.1,
    135.28333333333322,
    21.71666666666668,
    65.98333333333332,
    365.916666666667,
    29.583333333333336,
    299.91666666666663,
    326.73333333333335,
    315.2500000000003,
    290.05,
    227.6166666666671,
    238.5833333333331,
    328.76666666666665,
    175.53333333333322,
    25.416666666666675,
    406.7833333333333,
    80.46666666666664,
    279.16666666666646,
    719.2999999999986,
    251.58333333333331,
    658.7833333333349,
    36.00000000000001,
    486.1000000000008,
    517.1833333333324,
    526.7000000000012,
    555.3833333333333,
    66.16666666666664,
    453.41666666666464,
    600.0500000000005,
    467.48333333333295,
    430.7833333333338,
    97.80000000000008,
    520.0166666666671,
    449.0333333333341,
    49.18333333333332,
    355.71666666666687,
    427.55,
    285.3333333333335,
    285.7500000000004,
    35.51666666666668,
    256.8000000000002,
    313.71666666666715,
    528.6666666666669,
    323.4499999999998,
    338.2666666666663,
    275.8166666666669,
    305.8166666666664,
    448.4166666666672,
    22.86666666666666,
    431.11666666666696,
    60.999999999999964,
    291.0499999999999,
    32.83333333333333,
    233.7833333333337,
    350.11666666666673,
    375.28333333333336,
    72.64999999999998,
    30.70000000000001,
    438.85000000000014,
    55.06666666666666,
    462.4166666666671,
    339.06666666666626,
    290.9333333333332,
    228.2833333333334,
    263.64999999999975,
    288.0166666666668,
    197.31666666666672,
    543.300000000001,
    600.6499999999999,
    665.5166666666663,
    447.94999999999914,
    116.20000000000006,
    638.3000000000006,
    601.9000000000005,
    440.3333333333325,
    745.2333333333337,
    52.93333333333339,
    628.8833333333358,
    505.76666666666665,
    277.433333333333,
    370.0333333333329,
    264.15000000000003,
    273.6833333333335,
    271.1166666666667,
    228.88333333333333,
    302.6999999999999,
    318.3333333333339,
    32.96666666666667,
    31.91666666666668,
    184.98333333333323,
    95.23333333333339,
    215.41666666666762,
    251.73333333333332,
    7.066666666666667,
    285.48333333333335,
    136.33333333333366,
    235.08333333333343,
    6.566666666666666,
    231.66666666666669,
    307.96666666666727,
    191.2333333333337,
    9.816666666666668,
    320.7000000000005,
    158.06666666666618,
    280.16666666666663,
    585.6999999999998,
    930.8666666666676,
    560.1333333333338,
    529.633333333332,
    468.7833333333324,
    692.7999999999988,
    564.3666666666674,
    529.0000000000003,
    924.6500000000003,
    54.41666666666671,
    131.2833333333334,
    115.65000000000009,
    491.6333333333319,
    451.3499999999988,
    625.6000000000008,
    564.6333333333338,
    146.26666666666677,
    736.966666666668,
    670.9166666666669,
    526.6999999999996,
    676.9500000000007,
    374.83333333333326,
    81.2666666666667,
    48.050000000000026,
    864.2833333333328,
    469.6000000000003,
    430.5333333333329,
    387.09999999999917,
    498.8666666666678,
    288.05000000000007,
    30.266666666666666,
    160.83333333333337,
    316.63333333333327,
    362.3333333333329,
    399.46666666666704,
    76.98333333333333,
    129.66666666666606,
    393.78333333333404,
    154.50000000000009,
    149.06666666666624,
    52.68333333333332,
    203.7666666666669,
    177.01666666666648,
    208.55000000000004,
    411.1166666666664,
    592.7833333333333,
    147.0833333333332,
    169.65000000000012,
    151.23333333333358,
    158.98333333333338,
    172.80000000000013,
    13.883333333333333,
    15.250000000000002,
    243.18333333333374,
    199.86666666666625,
    61.633333333333304,
    407.24999999999983,
    276.49999999999994,
    126.76666666666605,
    232.6000000000001,
    257.90000000000003,
    151.2,
    166.09999999999994,
    291.78333333333285,
    322.71666666666704,
    192.90000000000006,
    65.66666666666673,
    13.733333333333334,
    6.466666666666666,
    68.71666666666667,
    116.6166666666669,
    77.65,
    70.63333333333338,
    61.63333333333331,
    83.48333333333333,
    76.11666666666672,
    2.833333333333333,
    1.4333333333333331,
    9.566666666666666,
    92.46666666666668,
    214.98333333333287,
    123.85000000000008,
    129.83333333333314,
    295.6499999999993,
    23.95,
    26.93333333333333,
    219.71666666666687,
    276.6000000000001,
    101.29999999999984,
    460.3999999999985,
    172.1666666666665,
    1.2833333333333334,
    187.46666666666718,
    370.6499999999994,
    310.1499999999998,
    376.2999999999992,
    272.3333333333321,
    336.4666666666675,
    20.266666666666673,
    385.4833333333335,
    267.65,
    202.53333333333356,
    455.9333333333332,
    35.34999999999999,
    49.73333333333332,
    248.4333333333333,
    554.8166666666671,
    361.36666666666684,
    20.583333333333325,
    526.0833333333333,
    449.5666666666674,
    15.05,
    345.3000000000007,
    73.93333333333334,
    270.54999999999933,
    328.23333333333295,
    385.73333333333335,
    311.033333333333,
    442.5333333333327,
    543.45,
    518.9833333333331,
    636.2500000000006,
    319.8666666666665,
    67.39999999999996,
    61.2,
    369.8666666666665,
    407.75000000000097,
    19.899999999999995,
    306.23333333333346,
    273.066666666666,
    360.24999999999983,
    533.2833333333342,
    334.2333333333337,
    357.7499999999996,
    14.716666666666665,
    293.3333333333333,
    295.7666666666663,
    55.16666666666666,
    399.2666666666669,
    237.99999999999974,
    241.6333333333339,
    327.63333333333344,
    26.43333333333333,
    369.04999999999984,
    264.39999999999975,
    11.4,
    1.4166666666666667,
    15.283333333333335,
    5.766666666666668,
    314.4333333333332,
    106.43333333333342,
    223.85,
    16.3,
    174.41666666666669,
    185.9333333333333,
    23.91666666666666,
    33.93333333333332,
    168.3166666666667,
    29.049999999999994,
    26.300000000000008,
    50.65000000000002,
    1.45,
    5.883333333333333,
    14.616666666666667,
    4.15,
    2.716666666666667,
    1.3833333333333333,
    2.75,
    1.1833333333333331,
    7.95,
    2,
    51.38333333333336,
    47.25000000000001,
    157.0833333333334,
    38.533333333333346,
    7.483333333333333,
    19.516666666666666,
    19.866666666666664,
    276.23333333333335,
    63.333333333333385,
    26.600000000000005,
    3.3666666666666667,
    104.3666666666666,
    114.94999999999992,
    697.2499999999997,
    642.3833333333369,
    576.7333333333353,
    29.36666666666667,
    284.4166666666667,
    494.03333333333086,
    418.0499999999985,
    678.966666666667,
    385.34999999999985,
    93.73333333333342,
    26.96666666666667,
    666.9000000000004,
    714.8666666666693,
    535.6333333333308,
    516.7666666666663,
    511.1333333333329,
    549.8499999999998,
    373.3833333333329,
    44.66666666666665,
    35.75000000000002,
    570.1000000000017,
    626.5333333333335,
    97.45000000000003,
    780.2000000000012,
    668.0333333333348,
    665.3166666666713,
    421.4000000000016,
    38.58333333333333,
    21.966666666666665,
    476.16666666667084,
    180.7666666666668,
    313.18333333333334,
    343.46666666666664,
    404.1333333333334,
    426.25000000000017,
    343.2000000000004,
    19.533333333333328,
    314.33333333333337,
    350.8833333333332,
    269.5833333333331,
    261.7833333333336,
    100.53333333333336,
    289.3333333333336,
    325.21666666666647,
    644.6333333333333,
    16.78333333333333,
    396.93333333333305,
    308.599999999999,
    301.8499999999998,
    10.7,
    238.43333333333337,
    355.01666666666665,
    528.4833333333312,
    605.5666666666716,
    533.7666666666674,
    425.26666666666483,
    45.83333333333337,
    745.8999999999987,
    111.48333333333342,
    736.3333333333313,
    866.1833333333337,
    663.900000000002,
    45.449999999999974,
    637.0000000000022,
    617.4000000000002,
    97.4166666666666,
    254.4333333333334,
    107.28333333333332,
    273.4833333333335,
    44.91666666666664,
    110.0166666666668,
    265.73333333333323,
    42.98333333333331,
    312.20000000000044,
    183.1,
    135.28333333333322,
    21.71666666666668,
    328.76666666666665,
    175.53333333333322,
    326.73333333333335,
    227.6166666666671,
    365.916666666667,
    315.2500000000003,
    299.91666666666663,
    406.7833333333333,
    29.583333333333336,
    65.98333333333332,
    25.416666666666675,
    238.5833333333331,
    290.05,
    80.46666666666664,
    600.0500000000005,
    517.1833333333324,
    66.16666666666664,
    526.7000000000012,
    555.3833333333333,
    486.1000000000008,
    719.2999999999986,
    658.7833333333349,
    453.41666666666464,
    279.16666666666646,
    251.58333333333331,
    36.00000000000001,
    430.7833333333338,
    467.48333333333295,
    285.3333333333335,
    427.55,
    97.80000000000008,
    35.51666666666668,
    520.0166666666671,
    285.7500000000004,
    355.71666666666687,
    449.0333333333341,
    256.8000000000002,
    49.18333333333332,
    313.71666666666715,
    291.0499999999999,
    60.999999999999964,
    305.8166666666664,
    528.6666666666669,
    323.4499999999998,
    338.2666666666663,
    22.86666666666666,
    32.83333333333333,
    448.4166666666672,
    233.7833333333337,
    275.8166666666669,
    431.11666666666696,
    55.06666666666666,
    290.9333333333332,
    288.0166666666668,
    228.2833333333334,
    263.64999999999975,
    72.64999999999998,
    30.70000000000001,
    438.85000000000014,
    375.28333333333336,
    350.11666666666673,
    462.4166666666671,
    339.06666666666626,
    197.31666666666672,
    628.8833333333358,
    638.3000000000006,
    116.20000000000006,
    665.5166666666663,
    600.6499999999999,
    447.94999999999914,
    543.300000000001,
    505.76666666666665,
    440.3333333333325,
    745.2333333333337,
    52.93333333333339,
    601.9000000000005,
    32.96666666666667,
    215.41666666666762,
    184.98333333333323,
    228.88333333333333,
    31.91666666666668,
    264.15000000000003,
    273.6833333333335,
    370.0333333333329,
    95.23333333333339,
    302.6999999999999,
    271.1166666666667,
    277.433333333333,
    318.3333333333339,
    6.566666666666666,
    280.16666666666663,
    235.08333333333343,
    251.73333333333332,
    136.33333333333366,
    307.96666666666727,
    191.2333333333337,
    158.06666666666618,
    285.48333333333335,
    9.816666666666668,
    231.66666666666669,
    7.066666666666667,
    320.7000000000005,
    692.7999999999988,
    115.65000000000009,
    930.8666666666676,
    468.7833333333324,
    924.6500000000003,
    491.6333333333319,
    564.3666666666674,
    560.1333333333338,
    54.41666666666671,
    529.0000000000003,
    529.633333333332,
    585.6999999999998,
    131.2833333333334,
    451.3499999999988,
    469.6000000000003,
    676.9500000000007,
    736.966666666668,
    526.6999999999996,
    864.2833333333328,
    625.6000000000008,
    670.9166666666669,
    564.6333333333338,
    81.2666666666667,
    374.83333333333326,
    48.050000000000026,
    146.26666666666677,
    208.55000000000004,
    154.50000000000009,
    498.8666666666678,
    393.78333333333404,
    399.46666666666704,
    288.05000000000007,
    76.98333333333333,
    129.66666666666606,
    362.3333333333329,
    387.09999999999917,
    52.68333333333332,
    592.7833333333333,
    149.06666666666624,
    316.63333333333327,
    30.266666666666666,
    203.7666666666669,
    160.83333333333337,
    411.1166666666664,
    430.5333333333329,
    177.01666666666648,
    158.98333333333338,
    316.63333333333316,
    192.90000000000006,
    276.49999999999994,
    199.86666666666625,
    134.39999999999992,
    257.90000000000003,
    322.71666666666704,
    61.633333333333304,
    172.80000000000013,
    258.15000000000043,
    151.23333333333358,
    151.2,
    15.250000000000002,
    169.65000000000012,
    291.78333333333285,
    168.18333333333328,
    232.6000000000001,
    13.883333333333333,
    126.76666666666605,
    61.63333333333331,
    116.6166666666669,
    83.48333333333333,
    405.4166666666661,
    65.66666666666673,
    68.71666666666667,
    524.0166666666664,
    76.11666666666672,
    487.06666666666746,
    70.63333333333338,
    363.13333333333367,
    257.56666666666723,
    485.93333333333254,
    279.1333333333331,
    343.9333333333332,
    39.916666666666686,
    64.38333333333331,
    311.0666666666665,
    432.7499999999997,
    138.6500000000002,
    51.33333333333332,
    47.11666666666669,
    379.11666666666684,
    316.9666666666667,
    447.54999999999967,
    212.69999999999982,
    205.91666666666646,
    121.11666666666682,
    333.2500000000004,
    224.98333333333332,
    300.15,
    275.0166666666668,
    243.65,
    299.38333333333287,
    44.1,
    51.81666666666673,
    218.94999999999996,
    137.7333333333333,
    172.98333333333323,
    24.56666666666667,
    107.8833333333333,
    284.03333333333336,
    20.35,
    73.45,
    100.88333333333328,
    40.23333333333333,
    123.85000000000008,
    433.583333333332,
    276.6000000000001,
    23.95,
    70.4333333333333,
    596.233333333335,
    460.3999999999985,
    478.59999999999985,
    533.1833333333341,
    283.98333333333335,
    861.6500000000025,
    821.6999999999994,
    546.9666666666649,
    73.60000000000002,
    248.4333333333333,
    740.8833333333348,
    623.5166666666682,
    35.34999999999999,
    820.9500000000023,
    924.8999999999992,
    544.4500000000003,
    49.73333333333332,
    455.9333333333332,
    536.6500000000007,
    516.6499999999983,
    419.3999999999997,
    442.5333333333327,
    450.6666666666674,
    20.583333333333325,
    328.23333333333295,
    345.3000000000007,
    270.54999999999933,
    15.05,
    311.033333333333,
    73.93333333333334,
    385.73333333333335,
    527.3166666666666,
    554.8166666666671,
    361.36666666666684,
    282.7333333333325,
    572.0000000000002,
    534.9000000000009,
    408.900000000001,
    360.24999999999983,
    636.2500000000006,
    67.39999999999996,
    61.2,
    328.8166666666669,
    344.56666666666666,
    398.9666666666664,
    518.9833333333331,
    14.716666666666665,
    293.3333333333333,
    369.04999999999984,
    334.2333333333337,
    295.7666666666663,
    327.63333333333344,
    237.99999999999974,
    241.6333333333339,
    357.7499999999996,
    55.16666666666666,
    26.43333333333333,
    399.2666666666669,
    264.39999999999975,
    5.766666666666668,
    1.4166666666666667,
    15.283333333333335,
    11.4,
    223.85,
    314.4333333333332,
    106.43333333333342,
    16.3,
    29.049999999999994,
    33.93333333333332,
    174.41666666666669,
    168.3166666666667,
    50.65000000000002,
    26.300000000000008,
    185.9333333333333,
    23.91666666666666,
    5.883333333333333,
    14.616666666666667,
    7.95,
    2,
    2.75,
    2.716666666666667,
    1.45,
    1.3833333333333333,
    4.15,
    1.1833333333333331,
    114.94999999999992,
    51.38333333333336,
    7.483333333333333,
    63.333333333333385,
    19.516666666666666,
    104.3666666666666,
    3.3666666666666667,
    47.25000000000001,
    38.533333333333346,
    276.23333333333335,
    19.866666666666664,
    26.600000000000005,
    157.0833333333334,
    284.4166666666667,
    26.96666666666667,
    93.73333333333342,
    697.2499999999997,
    494.03333333333086,
    678.966666666667,
    29.36666666666667,
    418.0499999999985,
    642.3833333333369,
    576.7333333333353,
    714.8666666666693,
    666.9000000000004,
    385.34999999999985,
    535.6333333333308,
    549.8499999999998,
    97.45000000000003,
    626.5333333333335,
    570.1000000000017,
    516.7666666666663,
    780.2000000000012,
    35.75000000000002,
    44.66666666666665,
    511.1333333333329,
    373.3833333333329,
    665.3166666666713,
    668.0333333333348,
    38.58333333333333,
    314.33333333333337,
    19.533333333333328,
    421.4000000000016,
    180.7666666666668,
    343.2000000000004,
    426.25000000000017,
    21.966666666666665,
    404.1333333333334,
    343.46666666666664,
    350.8833333333332,
    476.16666666667084,
    313.18333333333334,
    308.599999999999,
    16.78333333333333,
    355.01666666666665,
    261.7833333333336,
    644.6333333333333,
    396.93333333333305,
    301.8499999999998,
    10.7,
    289.3333333333336,
    238.43333333333337,
    269.5833333333331,
    325.21666666666647,
    100.53333333333336,
    528.4833333333312,
    866.1833333333337,
    45.83333333333337,
    736.3333333333313,
    533.7666666666674,
    663.900000000002,
    637.0000000000022,
    425.26666666666483,
    745.8999999999987,
    617.4000000000002,
    45.449999999999974,
    111.48333333333342,
    605.5666666666716,
    183.1,
    107.28333333333332,
    97.4166666666666,
    42.98333333333331,
    110.0166666666668,
    265.73333333333323,
    135.28333333333322,
    254.4333333333334,
    44.91666666666664,
    273.4833333333335,
    312.20000000000044,
    21.71666666666668,
    25.416666666666675,
    406.7833333333333,
    29.583333333333336,
    238.5833333333331,
    365.916666666667,
    299.91666666666663,
    315.2500000000003,
    326.73333333333335,
    227.6166666666671,
    65.98333333333332,
    328.76666666666665,
    290.05,
    175.53333333333322,
    486.1000000000008,
    600.0500000000005,
    80.46666666666664,
    66.16666666666664,
    279.16666666666646,
    719.2999999999986,
    251.58333333333331,
    453.41666666666464,
    526.7000000000012,
    658.7833333333349,
    517.1833333333324,
    36.00000000000001,
    555.3833333333333,
    449.0333333333341,
    49.18333333333332,
    313.71666666666715,
    430.7833333333338,
    97.80000000000008,
    355.71666666666687,
    35.51666666666668,
    285.7500000000004,
    285.3333333333335,
    467.48333333333295,
    520.0166666666671,
    256.8000000000002,
    427.55,
    291.0499999999999,
    233.7833333333337,
    60.999999999999964,
    528.6666666666669,
    448.4166666666672,
    32.83333333333333,
    338.2666666666663,
    431.11666666666696,
    305.8166666666664,
    323.4499999999998,
    22.86666666666666,
    275.8166666666669,
    288.0166666666668,
    462.4166666666671,
    339.06666666666626,
    263.64999999999975,
    375.28333333333336,
    290.9333333333332,
    438.85000000000014,
    30.70000000000001,
    72.64999999999998,
    350.11666666666673,
    228.2833333333334,
    55.06666666666666,
    543.300000000001,
    665.5166666666663,
    197.31666666666672,
    447.94999999999914,
    601.9000000000005,
    505.76666666666665,
    600.6499999999999,
    440.3333333333325,
    745.2333333333337,
    628.8833333333358,
    52.93333333333339,
    116.20000000000006,
    638.3000000000006,
    32.96666666666667,
    184.98333333333323,
    318.3333333333339,
    273.6833333333335,
    271.1166666666667,
    31.91666666666668,
    370.0333333333329,
    215.41666666666762,
    302.6999999999999,
    228.88333333333333,
    95.23333333333339,
    277.433333333333,
    264.15000000000003,
    7.066666666666667,
    158.06666666666618,
    231.66666666666669,
    285.48333333333335,
    307.96666666666727,
    6.566666666666666,
    136.33333333333366,
    280.16666666666663,
    320.7000000000005,
    235.08333333333343,
    251.73333333333332,
    191.2333333333337,
    9.816666666666668,
    924.6500000000003,
    564.3666666666674,
    692.7999999999988,
    491.6333333333319,
    529.0000000000003,
    585.6999999999998,
    468.7833333333324,
    930.8666666666676,
    529.633333333332,
    54.41666666666671,
    560.1333333333338,
    115.65000000000009,
    131.2833333333334,
    564.6333333333338,
    469.6000000000003,
    676.9500000000007,
    526.6999999999996,
    625.6000000000008,
    670.9166666666669,
    81.2666666666667,
    864.2833333333328,
    374.83333333333326,
    736.966666666668,
    48.050000000000026,
    451.3499999999988,
    146.26666666666677,
    592.7833333333333,
    411.1166666666664,
    498.8666666666678,
    399.46666666666704,
    430.5333333333329,
    362.3333333333329,
    160.83333333333337,
    154.50000000000009,
    203.7666666666669,
    177.01666666666648,
    129.66666666666606,
    149.06666666666624,
    387.09999999999917,
    30.266666666666666,
    76.98333333333333,
    316.63333333333327,
    393.78333333333404,
    288.05000000000007,
    52.68333333333332,
    208.55000000000004,
    172.80000000000013,
    15.250000000000002,
    322.71666666666704,
    134.39999999999992,
    276.49999999999994,
    258.15000000000043,
    151.23333333333358,
    61.633333333333304,
    158.98333333333338,
    316.63333333333316,
    126.76666666666605,
    169.65000000000012,
    168.18333333333328,
    13.883333333333333,
    151.2,
    192.90000000000006,
    232.6000000000001,
    291.78333333333285,
    257.90000000000003,
    199.86666666666625,
    116.6166666666669,
    405.4166666666661,
    138.6500000000002,
    279.1333333333331,
    61.63333333333331,
    68.71666666666667,
    343.9333333333332,
    76.11666666666672,
    83.48333333333333,
    65.66666666666673,
    70.63333333333338,
    363.13333333333367,
    524.0166666666664,
    432.7499999999997,
    485.93333333333254,
    487.06666666666746,
    39.916666666666686,
    64.38333333333331,
    311.0666666666665,
    257.56666666666723,
    333.2500000000004,
    212.69999999999982,
    275.0166666666668,
    447.54999999999967,
    300.15,
    121.11666666666682,
    224.98333333333332,
    205.91666666666646,
    51.33333333333332,
    379.11666666666684,
    316.9666666666667,
    243.65,
    47.11666666666669,
    24.56666666666667,
    284.03333333333336,
    107.8833333333333,
    73.45,
    20.35,
    299.38333333333287,
    51.81666666666673,
    137.7333333333333,
    100.88333333333328,
    44.1,
    218.94999999999996,
    40.23333333333333,
    172.98333333333323,
    861.6500000000025,
    276.6000000000001,
    821.6999999999994,
    433.583333333332,
    283.98333333333335,
    123.85000000000008,
    533.1833333333341,
    596.233333333335,
    460.3999999999985,
    23.95,
    478.59999999999985,
    546.9666666666649,
    70.4333333333333,
    536.6500000000007,
    924.8999999999992,
    623.5166666666682,
    73.60000000000002,
    544.4500000000003,
    740.8833333333348,
    516.6499999999983,
    455.9333333333332,
    35.34999999999999,
    248.4333333333333,
    820.9500000000023,
    419.3999999999997,
    49.73333333333332,
    15.05,
    20.583333333333325,
    361.36666666666684,
    345.3000000000007,
    311.033333333333,
    73.93333333333334,
    554.8166666666671,
    328.23333333333295,
    385.73333333333335,
    527.3166666666666,
    270.54999999999933,
    442.5333333333327,
    450.6666666666674,
    360.24999999999983,
    636.2500000000006,
    399.04999999999984,
    534.9000000000009,
    408.900000000001,
    61.2,
    344.56666666666666,
    518.9833333333331,
    572.0000000000002,
    67.39999999999996,
    328.8166666666669,
    282.7333333333325,
    493.0666666666668,
    357.7499999999996,
    58.98333333333343,
    333.1666666666659,
    496.23333333333335,
    327.63333333333344,
    609.1833333333329,
    26.43333333333333,
    429.0500000000007,
    465.21666666666687,
    393.966666666666,
    328.1333333333332,
    50.9,
    67.80000000000001,
    58.63333333333333,
    247.9166666666668,
    315.18333333333214,
    46.95,
    2,
    276.1666666666669,
    4.15,
    1.1833333333333331,
    1.3833333333333333,
    14.616666666666667,
    2.716666666666667,
    2.75,
    1.45,
    5.883333333333333,
    45.78333333333334,
    19.516666666666666,
    40.68333333333333,
    87.7333333333333,
    25.15000000000001,
    6.216666666666668,
    230.8,
    120.64999999999986,
    92.76666666666662,
    44.21666666666668,
    17.65,
    38.533333333333346,
    29.36666666666667,
    494.03333333333086,
    93.73333333333342,
    697.2499999999997,
    642.3833333333369,
    302.1,
    714.8666666666693,
    678.966666666667,
    385.34999999999985,
    418.0499999999985,
    26.96666666666667,
    576.7333333333353,
    667.5000000000006,
    626.5333333333335,
    35.75000000000002,
    535.6333333333308,
    665.3166666666713,
    373.3833333333329,
    97.45000000000003,
    780.2000000000012,
    516.7666666666663,
    44.66666666666665,
    570.1000000000017,
    668.0333333333348,
    549.8499999999998,
    511.1333333333329,
    180.7666666666668,
    476.16666666667084,
    21.966666666666665,
    421.4000000000016,
    343.2000000000004,
    38.58333333333333,
    350.8833333333332,
    426.25000000000017,
    343.46666666666664,
    19.533333333333328,
    314.33333333333337,
    404.1333333333334,
    313.18333333333334,
    325.21666666666647,
    16.78333333333333,
    308.599999999999,
    355.01666666666665,
    238.43333333333337,
    396.93333333333305,
    289.3333333333336,
    269.5833333333331,
    301.8499999999998,
    644.6333333333333,
    10.7,
    100.53333333333336,
    261.7833333333336,
    533.7666666666674,
    866.1833333333337,
    605.5666666666716,
    425.26666666666483,
    528.4833333333312,
    663.900000000002,
    745.8999999999987,
    111.48333333333342,
    45.83333333333337,
    45.449999999999974,
    736.3333333333313,
    617.4000000000002,
    637.0000000000022,
    110.0166666666668,
    107.28333333333332,
    254.4333333333334,
    273.4833333333335,
    265.73333333333323,
    44.91666666666664,
    97.4166666666666,
    42.98333333333331,
    312.20000000000044,
    183.1,
    135.28333333333322,
    21.71666666666668,
];

export const data: [string[], number[]] = [
    // this is demo data... replace with real mock data for model
    [...stations],
    [...values],
];

export default data;
