/**
 *
 * SelectStudTypeFilter
 *
 */

import SelectStudTypeFilterNew from './SelectStudTypeFilter';

export { SelectStudTypeFilterNew };

export default SelectStudTypeFilterNew;
