/**
 *
 * TopFaultCount
 *
 */

import TopFaultCount, { TopFaultCountProperty } from './TopFaultCount';

export { TopFaultCount, TopFaultCountProperty };

export default TopFaultCount;
