import { TaAnalysisTables } from 'models';

export const data: TaAnalysisTables | any[] = [
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-2',
        faultCode: '20002',
        mtbf: '',
        mttr: '1.7166666666666666',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-2',
        faultCode: '20028',
        mtbf: '',
        mttr: '5.466666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-2',
        faultCode: '20028',
        mtbf: '',
        mttr: '9.166666666666666',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-2',
        faultCode: '20028',
        mtbf: '',
        mttr: '3.6666666666666665',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '5.466666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '9.166666666666666',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.6666666666666665',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.7166666666666666',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.3',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot1-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.3',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '17068',
        mtbf: '3.28',
        mttr: '1.0472222222222223',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '20002',
        mtbf: '7.04',
        mttr: '0.7875',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.9',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '1.9666666666666666',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '20028',
        mtbf: '3.55',
        mttr: '4.3500000000000005',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '1.5',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '21051',
        mtbf: '6.26',
        mttr: '1.4666666666666668',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '1.8333333333333333',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '2.3',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '1.1333333333333333',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: '21053',
        mtbf: '',
        mttr: '3.183333333333333',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.5694444444444444',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.7208333333333337',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.0055555555555555',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.3393939393939396',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.3791666666666667',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.183333333333333',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.4',
        mttr: '',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.11',
        mttr: '',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '4.23',
        mttr: '',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.21',
        mttr: '',
    },
    {
        averageTA: 12.403404545454547,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.2',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.26666666666666666',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line1 Station1Robot2-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.26666666666666666',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: '20002',
        mtbf: '1.15',
        mttr: '2.5566666666666666',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: '20003',
        mtbf: '3.44',
        mttr: '0.25',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: '20028',
        mtbf: '0.89',
        mttr: '3.0018518518518515',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: '20028',
        mtbf: '1.3',
        mttr: '4.735185185185185',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: '20028',
        mtbf: '0.07',
        mttr: '2.075',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: '21051',
        mtbf: '0.01',
        mttr: '0.48333333333333334',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.0018518518518515',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.9075',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.075',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.89',
        mttr: '',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.16',
        mttr: '',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.08',
        mttr: '',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 50.38333124999999,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.44',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: '10033',
        mtbf: '0.73',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: '10033',
        mtbf: '0.92',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: '10033',
        mtbf: '0.63',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: '10033',
        mtbf: '1.42',
        mttr: '0.26666666666666666',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: '20103',
        mtbf: '3.44',
        mttr: '0.2583333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.26666666666666666',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.2583333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.73',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.92',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.63',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.42',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot2-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.44',
        mttr: '',
    },
    {
        averageTA: 27.607,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-1',
        faultCode: '20003',
        mtbf: '3.44',
        mttr: '0.25',
    },
    {
        averageTA: 27.607,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-1',
        faultCode: '20028',
        mtbf: '0.07',
        mttr: '2.2',
    },
    {
        averageTA: 27.607,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 27.607,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.2',
    },
    {
        averageTA: 27.607,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 27.607,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 27.607,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.44',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-2',
        faultCode: '20003',
        mtbf: '3.44',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '26.0',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.44',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-System',
        faultCode: '20103',
        mtbf: '3.44',
        mttr: '0.2833333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.2833333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot3-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.44',
        mttr: '',
    },
    {
        averageTA: 0.6398,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot4-1',
        faultCode: '20003',
        mtbf: '3.44',
        mttr: '0.25',
    },
    {
        averageTA: 0.6398,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0.6398,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.44',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot4-System',
        faultCode: '20103',
        mtbf: '3.44',
        mttr: '0.2583333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.2583333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line2 Station2Robot4-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.44',
        mttr: '',
    },
    {
        averageTA: 24.6036,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 24.6036,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '5.316666666666666',
    },
    {
        averageTA: 24.6036,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-1',
        faultCode: '20028',
        mtbf: '0.05',
        mttr: '1.85',
    },
    {
        averageTA: 24.6036,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '6.2',
    },
    {
        averageTA: 24.6036,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 24.6036,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '5.316666666666666',
    },
    {
        averageTA: 24.6036,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.85',
    },
    {
        averageTA: 24.6036,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.05',
        mttr: '',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: '13009',
        mtbf: '2.6',
        mttr: '2.516666666666667',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: '13009',
        mtbf: '3.85',
        mttr: '3.238888888888889',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: '13009',
        mtbf: '2.82',
        mttr: '3.8916666666666666',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: '17068',
        mtbf: '',
        mttr: '5.066666666666666',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: '20002',
        mtbf: '0.42',
        mttr: '2.234027777777778',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: '20002',
        mtbf: '0.51',
        mttr: '2.7095238095238092',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: '20002',
        mtbf: '3.03',
        mttr: '13.058333333333332',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: '20002',
        mtbf: '0.97',
        mttr: '3.1242424242424245',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.103225806451613',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.497435897435897',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '12.17037037037037',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.6559523809523804',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.38',
        mttr: '',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.33',
        mttr: '',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.66',
        mttr: '',
    },
    {
        averageTA: 39.6845875,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.82',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-System',
        faultCode: '20103',
        mtbf: '0.7',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.85',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.04',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station2Robot4-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.7',
        mttr: '',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: '20003',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '1.0666666666666667',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: '20029',
        mtbf: '5.29',
        mttr: '5.177777777777778',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '10.416666666666666',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.6583333333333333',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '5.177777777777778',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '10.416666666666666',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '4.29',
        mttr: '',
    },
    {
        averageTA: 0.21913636363636363,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '5.29',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line3 Station3Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot1-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.5166666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '8.866666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5166666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot1-System',
        faultCode: '20103',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot1-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot2-System',
        faultCode: '20103',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot2-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 45.208325,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot3-1',
        faultCode: '20028',
        mtbf: '0.02',
        mttr: '0.8666666666666667',
    },
    {
        averageTA: 45.208325,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot3-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 45.208325,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.7125',
    },
    {
        averageTA: 45.208325,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot3-System',
        faultCode: '20103',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot3-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '0.5166666666666667',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: '21051',
        mtbf: '4.72',
        mttr: '0.5791666666666666',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: '21051',
        mtbf: '1.6',
        mttr: '1.9366666666666668',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '10.883333333333333',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5166666666666667',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5791666666666666',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.9366666666666668',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '10.883333333333333',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '4.72',
        mttr: '',
    },
    {
        averageTA: 5.3291200000000005,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.6',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-System',
        faultCode: '20103',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station4Robot4-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot1-System',
        faultCode: '20103',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot1-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot2-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '4.966666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '4.966666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot2-System',
        faultCode: '20103',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot2-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot3-System',
        faultCode: '20103',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot3-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot4-System',
        faultCode: '20103',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station5Robot4-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '13009',
        mtbf: '2.09',
        mttr: '2.977777777777778',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '13009',
        mtbf: '',
        mttr: '4.916666666666667',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '17009',
        mtbf: '0.1',
        mttr: '3.9000000000000004',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '20002',
        mtbf: '1.23',
        mttr: '1.8357142857142854',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '20002',
        mtbf: '0.81',
        mttr: '1.161904761904762',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '20002',
        mtbf: '3.03',
        mttr: '3.325',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '20002',
        mtbf: '3.6',
        mttr: '1.1749999999999998',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '2.066666666666667',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '20004',
        mtbf: '0.16',
        mttr: '2.416666666666667',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '7.15',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '2.2333333333333334',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '1.9833333333333334',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.1783333333333337',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.1303921568627453',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.5380952380952384',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.422222222222222',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.066666666666667',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.82',
        mttr: '',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.27',
        mttr: '',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.51',
        mttr: '',
    },
    {
        averageTA: 38.40923333333334,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.8',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-2',
        faultCode: '20002',
        mtbf: '',
        mttr: '2.5',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.5',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '16376',
        mtbf: '2.12',
        mttr: '3.944444444444444',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '17068',
        mtbf: '0.84',
        mttr: '2.0',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '17068',
        mtbf: '',
        mttr: '2.066666666666667',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '17068',
        mtbf: '',
        mttr: '0.35',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '1.4833333333333334',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '20002',
        mtbf: '0.03',
        mttr: '0.25',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '20028',
        mtbf: '0.02',
        mttr: '5.205555555555556',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '20029',
        mtbf: '4.49',
        mttr: '6.727777777777778',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '20029',
        mtbf: '0.02',
        mttr: '0.9458333333333333',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '21051',
        mtbf: '3.13',
        mttr: '3.0466666666666664',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '21051',
        mtbf: '0.93',
        mttr: '1.975',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '21051',
        mtbf: '9.07',
        mttr: '17.591666666666665',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '3.55',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.0466666666666664',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.6400000000000001',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.9972222222222222',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.8966666666666667',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.13',
        mttr: '',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.84',
        mttr: '',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.48',
        mttr: '',
    },
    {
        averageTA: 43.89733181818183,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-2',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-System',
        faultCode: '20103',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5666666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot3-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot4-System',
        faultCode: '20103',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot4-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.9833333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.3333333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.6833333333333333',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot5-System',
        faultCode: '20103',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot5-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: '17068',
        mtbf: '0.23',
        mttr: '1.2491666666666665',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: '20002',
        mtbf: '0.58',
        mttr: '0.7833333333333333',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '1.9666666666666666',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: '21051',
        mtbf: '0.12',
        mttr: '1.0770833333333332',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: '21051',
        mtbf: '2.99',
        mttr: '1.15',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: '21051',
        mtbf: '0.24',
        mttr: '0.8041666666666667',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: '21051',
        mtbf: '3.38',
        mttr: '1.976666666666667',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.1652173913043478',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.0770833333333332',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.2861111111111112',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.8519607843137256',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.976666666666667',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.19',
        mttr: '',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.12',
        mttr: '',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.4',
        mttr: '',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.24',
        mttr: '',
    },
    {
        averageTA: 45.38101111111112,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.38',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-System',
        faultCode: '20103',
        mtbf: '0.08',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.816666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station6Robot6-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.08',
        mttr: '',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: '13009',
        mtbf: '0.14',
        mttr: '4.516666666666667',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: '13009',
        mtbf: '0.92',
        mttr: '0.7049999999999998',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: '20002',
        mtbf: '3.42',
        mttr: '2.236666666666667',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: '20002',
        mtbf: '0.58',
        mttr: '0.9428571428571428',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: '20002',
        mtbf: '6.05',
        mttr: '1.4000000000000001',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '1.1166666666666667',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: '20002',
        mtbf: '4.76',
        mttr: '1.7899999999999998',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: '20004',
        mtbf: '',
        mttr: '12.416666666666666',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.6574074074074066',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5352941176470588',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.4000000000000001',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.1166666666666667',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.7899999999999998',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.21',
        mttr: '',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.12',
        mttr: '',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '6.05',
        mttr: '',
    },
    {
        averageTA: 39.85581176470588,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '4.76',
        mttr: '',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: '17035',
        mtbf: '0.01',
        mttr: '0.9916666666666668',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: '20002',
        mtbf: '3.38',
        mttr: '3.283333333333333',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: '20002',
        mtbf: '0.25',
        mttr: '1.8833333333333333',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: '20028',
        mtbf: '1.26',
        mttr: '4.116666666666667',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: '20028',
        mtbf: '',
        mttr: '1.0333333333333334',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: '20029',
        mtbf: '',
        mttr: '2.0166666666666666',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.1625',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.1638888888888888',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.3666666666666667',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.68',
        mttr: '',
    },
    {
        averageTA: 48.37272727272727,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot2-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.02',
        mttr: '',
    },
    {
        averageTA: 57.00771428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-1',
        faultCode: '17070',
        mtbf: '0.02',
        mttr: '1.0666666666666667',
    },
    {
        averageTA: 57.00771428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-1',
        faultCode: '20028',
        mtbf: '0.02',
        mttr: '1.6833333333333333',
    },
    {
        averageTA: 57.00771428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 57.00771428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.2428571428571427',
    },
    {
        averageTA: 57.00771428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 57.00771428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.02',
        mttr: '',
    },
    {
        averageTA: 57.00771428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 60.215066666666665,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-2',
        faultCode: '21066',
        mtbf: '0.01',
        mttr: '0.26666666666666666',
    },
    {
        averageTA: 60.215066666666665,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.26666666666666666',
    },
    {
        averageTA: 60.215066666666665,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-System',
        faultCode: '20103',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot3-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.07',
        mttr: '',
    },
    {
        averageTA: 66.3488,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot5-1',
        faultCode: '20002',
        mtbf: '0.02',
        mttr: '1.038888888888889',
    },
    {
        averageTA: 66.3488,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.038888888888889',
    },
    {
        averageTA: 66.3488,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.02',
        mttr: '',
    },
    {
        averageTA: 38.48632,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot6-1',
        faultCode: '20002',
        mtbf: '0.18',
        mttr: '5.408333333333333',
    },
    {
        averageTA: 38.48632,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot6-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '5.408333333333333',
    },
    {
        averageTA: 38.48632,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot6-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.3',
    },
    {
        averageTA: 38.48632,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot6-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5833333333333334',
    },
    {
        averageTA: 38.48632,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line4 Station7Robot6-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.18',
        mttr: '',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: '17070',
        mtbf: '0.03',
        mttr: '1.3194444444444444',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: '20002',
        mtbf: '0.05',
        mttr: '1.3555555555555554',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: '20002',
        mtbf: '0.01',
        mttr: '3.85',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: '20028',
        mtbf: '0.03',
        mttr: '0.6666666666666667',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: '20029',
        mtbf: '0.0',
        mttr: '0.25',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.0',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.690740740740741',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.02',
        mttr: '',
    },
    {
        averageTA: 77.20807777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.21',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot2-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: '17068',
        mtbf: '',
        mttr: '0.8166666666666667',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: '17068',
        mtbf: '3.55',
        mttr: '4.883333333333333',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: '21051',
        mtbf: '0.16',
        mttr: '4.841666666666667',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: '21066',
        mtbf: '0.62',
        mttr: '0.25',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.1444444444444446',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.9388888888888887',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.3111111111111113',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.3388888888888886',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '5.13',
        mttr: '',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.84',
        mttr: '',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.08',
        mttr: '',
    },
    {
        averageTA: 39.10566428571428,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.22',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '17068',
        mtbf: '3.55',
        mttr: '2.028571428571429',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '17068',
        mtbf: '',
        mttr: '0.8',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '17068',
        mtbf: '',
        mttr: '2.1166666666666667',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '20002',
        mtbf: '',
        mttr: '2.2333333333333334',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '20002',
        mtbf: '1.84',
        mttr: '1.3033333333333332',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.8666666666666667',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '20029',
        mtbf: '',
        mttr: '11.783333333333333',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '21066',
        mtbf: '20.74',
        mttr: '4.033333333333333',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: '21066',
        mtbf: '',
        mttr: '2.7',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.6944444444444446',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.41875',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.1166666666666667',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.9333333333333333',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.475',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.97',
        mttr: '',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.69',
        mttr: '',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.11',
        mttr: '',
    },
    {
        averageTA: 22.26332777777777,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.0',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station10Robot5-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '13009',
        mtbf: '',
        mttr: '0.6666666666666666',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '13009',
        mtbf: '',
        mttr: '0.4166666666666667',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '16376',
        mtbf: '',
        mttr: '1.5166666666666666',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '17008',
        mtbf: '1.7',
        mttr: '0.25',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '17008',
        mtbf: '0.05',
        mttr: '4.883333333333334',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '17068',
        mtbf: '0.02',
        mttr: '0.991025641025641',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '17068',
        mtbf: '3.9',
        mttr: '1.8416666666666668',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '17068',
        mtbf: '2.48',
        mttr: '1.5',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '17068',
        mtbf: '1.06',
        mttr: '1.325',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '17068',
        mtbf: '0.05',
        mttr: '0.9602564102564102',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '17068',
        mtbf: '0.04',
        mttr: '1.0083333333333333',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20002',
        mtbf: '3.67',
        mttr: '3.56',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20002',
        mtbf: '1.18',
        mttr: '2.6472222222222226',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20002',
        mtbf: '3.05',
        mttr: '4.179166666666667',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20002',
        mtbf: '0.63',
        mttr: '0.9564814814814816',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20002',
        mtbf: '3.69',
        mttr: '1.4208333333333334',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20004',
        mtbf: '0.02',
        mttr: '0.55',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20004',
        mtbf: '0.02',
        mttr: '0.859375',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20029',
        mtbf: '2.25',
        mttr: '1.5233333333333334',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '1.3',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20029',
        mtbf: '0.66',
        mttr: '3.4615384615384617',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20029',
        mtbf: '2.05',
        mttr: '3.3222222222222224',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '20029',
        mtbf: '1.52',
        mttr: '2.6277777777777778',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '1.2',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '21066',
        mtbf: '1.95',
        mttr: '0.675',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '21066',
        mtbf: '2.59',
        mttr: '0.25',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '21066',
        mtbf: '2.16',
        mttr: '0.6555555555555556',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.8369791666666667',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.9844444444444447',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.6814814814814814',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.102222222222222',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.0083333333333333',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.6291666666666668',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.03',
        mttr: '',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.87',
        mttr: '',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.16',
        mttr: '',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.36',
        mttr: '',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.06',
        mttr: '',
    },
    {
        averageTA: 49.89646904761904,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 18.84365,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-2',
        faultCode: '20002',
        mtbf: '2.13',
        mttr: '2.1750000000000003',
    },
    {
        averageTA: 18.84365,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-2',
        faultCode: '20002',
        mtbf: '',
        mttr: '2.066666666666667',
    },
    {
        averageTA: 18.84365,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-2',
        faultCode: '20002',
        mtbf: '3.84',
        mttr: '1.125',
    },
    {
        averageTA: 18.84365,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.1750000000000003',
    },
    {
        averageTA: 18.84365,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.066666666666667',
    },
    {
        averageTA: 18.84365,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.125',
    },
    {
        averageTA: 18.84365,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.13',
        mttr: '',
    },
    {
        averageTA: 18.84365,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.84',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station5Robot5-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 42.63077142857143,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-1',
        faultCode: '20028',
        mtbf: '0.04',
        mttr: '1.325',
    },
    {
        averageTA: 42.63077142857143,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '1.7833333333333334',
    },
    {
        averageTA: 42.63077142857143,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.0166666666666666',
    },
    {
        averageTA: 42.63077142857143,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.325',
    },
    {
        averageTA: 42.63077142857143,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.8666666666666667',
    },
    {
        averageTA: 42.63077142857143,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.0',
        mttr: '',
    },
    {
        averageTA: 42.63077142857143,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.04',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot1-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot4-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.6166666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot4-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '13009',
        mtbf: '1.63',
        mttr: '1.3583333333333334',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '13009',
        mtbf: '5.41',
        mttr: '2.0277777777777777',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '17009',
        mtbf: '0.05',
        mttr: '3.595833333333333',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '3.1',
        mttr: '1.6958333333333335',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '3.17',
        mttr: '1.2066666666666666',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '0.29',
        mttr: '1.631531531531531',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '2.35',
        mttr: '1.5814814814814815',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '0.52',
        mttr: '1.9487179487179485',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '89.2',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: '20004',
        mtbf: '0.11',
        mttr: '1.3666666666666667',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.756666666666667',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.1999999999999997',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.3197368421052629',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.2550000000000001',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.9487179487179485',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '89.2',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.83',
        mttr: '',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.42',
        mttr: '',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.24',
        mttr: '',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.71',
        mttr: '',
    },
    {
        averageTA: 46.34824761904762,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.52',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station8Robot5-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot1-System',
        faultCode: '20103',
        mtbf: '1.64',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot1-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot1-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.64',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot4-System',
        faultCode: '20103',
        mtbf: '1.64',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot4-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot4-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.64',
        mttr: '',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: '17009',
        mtbf: '0.07',
        mttr: '7.566666666666667',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '14.616666666666667',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: '20002',
        mtbf: '2.17',
        mttr: '1.7500000000000004',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '1.9333333333333333',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '66.13333333333334',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: '20004',
        mtbf: '0.13',
        mttr: '9.875',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '1.1166666666666667',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '5.7444444444444445',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.019791666666667',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.375',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '66.13333333333334',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.02',
        mttr: '',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.28',
        mttr: '',
    },
    {
        averageTA: 33.25204285714286,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line5 Station9Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '9.48',
        mttr: '',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: '17068',
        mtbf: '',
        mttr: '0.8333333333333334',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: '17068',
        mtbf: '0.63',
        mttr: '0.5180555555555556',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: '17068',
        mtbf: '0.26',
        mttr: '1.1035353535353531',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: '20002',
        mtbf: '4.67',
        mttr: '0.7888888888888888',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '1.6166666666666667',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: '21066',
        mtbf: '5.05',
        mttr: '0.25',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: '21066',
        mtbf: '0.32',
        mttr: '',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.8333333333333334',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.4392156862745098',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.6096153846153847',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.6166666666666667',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.44',
        mttr: '',
    },
    {
        averageTA: 41.42585714285714,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.14',
        mttr: '',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '17009',
        mtbf: '0.02',
        mttr: '1.3666666666666667',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '17068',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '17068',
        mtbf: '0.02',
        mttr: '2.3666666666666667',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '20029',
        mtbf: '0.01',
        mttr: '0.325',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '20029',
        mtbf: '1.48',
        mttr: '2.6666666666666665',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '0.7666666666666667',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '20029',
        mtbf: '3.5',
        mttr: '20.616666666666667',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '0.8333333333333334',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.325',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.7416666666666667',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.7666666666666667',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.8333333333333334',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '14.322222222222223',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.42',
        mttr: '',
    },
    {
        averageTA: 37.44513529411765,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '8.09',
        mttr: '',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '17068',
        mtbf: '3.9',
        mttr: '0.8875',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '17068',
        mtbf: '0.75',
        mttr: '0.8500000000000001',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '20004',
        mtbf: '0.07',
        mttr: '0.569047619047619',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '20004',
        mtbf: '0.16',
        mttr: '0.6427083333333333',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '20004',
        mtbf: '0.03',
        mttr: '0.5833333333333335',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '20004',
        mtbf: '0.09',
        mttr: '1.0633333333333332',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '20028',
        mtbf: '',
        mttr: '3.933333333333333',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '20029',
        mtbf: '',
        mttr: '0.55',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: '21066',
        mtbf: '1.13',
        mttr: '0.25',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5291666666666667',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.653921568627451',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.4055555555555557',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.682638888888889',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.7441666666666668',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.09',
        mttr: '',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.19',
        mttr: '',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '3.56',
        mttr: '',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.33',
        mttr: '',
    },
    {
        averageTA: 49.595615,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot2-2',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.25',
        mttr: '',
    },
    {
        averageTA: 11.452066666666669,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot4-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '5.366666666666666',
    },
    {
        averageTA: 11.452066666666669,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot4-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.85',
    },
    {
        averageTA: 11.452066666666669,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot4-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '1.8833333333333333',
    },
    {
        averageTA: 11.452066666666669,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '5.366666666666666',
    },
    {
        averageTA: 11.452066666666669,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.3666666666666667',
    },
    {
        averageTA: 11.452066666666669,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.3',
        mttr: '',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '13009',
        mtbf: '1.69',
        mttr: '0.7783333333333333',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '13009',
        mtbf: '0.5',
        mttr: '0.4777777777777778',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '13009',
        mtbf: '',
        mttr: '0.4166666666666667',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '17068',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '20002',
        mtbf: '0.71',
        mttr: '0.638888888888889',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '20002',
        mtbf: '0.19',
        mttr: '0.5112179487179487',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '20002',
        mtbf: '1.0',
        mttr: '0.7805555555555556',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '20002',
        mtbf: '2.32',
        mttr: '0.6444444444444445',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: '20002',
        mtbf: '0.52',
        mttr: '1.8282051282051286',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.6946666666666667',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5049479166666667',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.7525641025641026',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.6444444444444445',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.7154761904761908',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.15',
        mttr: '',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.18',
        mttr: '',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.61',
        mttr: '',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.32',
        mttr: '',
    },
    {
        averageTA: 42.8025947368421,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station3Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.57',
        mttr: '',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.6333333333333333',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20002',
        mtbf: '10.76',
        mttr: '0.275',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20002',
        mtbf: '8.83',
        mttr: '0.8583333333333333',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20028',
        mtbf: '0.23',
        mttr: '0.9333333333333331',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20028',
        mtbf: '0.17',
        mttr: '0.7166666666666667',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '0.43333333333333335',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20028',
        mtbf: '0.14',
        mttr: '0.5769230769230769',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20028',
        mtbf: '1.76',
        mttr: '0.5555555555555556',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20029',
        mtbf: '1.46',
        mttr: '0.28888888888888886',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20029',
        mtbf: '2.48',
        mttr: '0.26666666666666666',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '0.85',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20029',
        mtbf: '4.28',
        mttr: '0.43333333333333335',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: '20029',
        mtbf: '2.94',
        mttr: '0.7083333333333334',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.8173333333333334',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.7038095238095238',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.7782051282051282',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5769841269841269',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.6097222222222222',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.18',
        mttr: '',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.21',
        mttr: '',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.05',
        mttr: '',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.11',
        mttr: '',
    },
    {
        averageTA: 53.723725,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.03',
        mttr: '',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: '13009',
        mtbf: '0.12',
        mttr: '1.9944444444444442',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: '13009',
        mtbf: '',
        mttr: '0.6666666666666666',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: '13009',
        mtbf: '4.66',
        mttr: '0.9222222222222222',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: '13009',
        mtbf: '0.01',
        mttr: '0.29166666666666663',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.9944444444444442',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.6666666666666666',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.9222222222222222',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.29166666666666663',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.12',
        mttr: '',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '4.66',
        mttr: '',
    },
    {
        averageTA: 46.31841818181819,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '13009',
        mtbf: '2.5',
        mttr: '2.7041666666666666',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '13009',
        mtbf: '3.64',
        mttr: '1.0523809523809524',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '13009',
        mtbf: '2.23',
        mttr: '0.8196969696969698',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '13009',
        mtbf: '0.54',
        mttr: '0.3902777777777778',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20002',
        mtbf: '0.53',
        mttr: '0.9913580246913578',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20002',
        mtbf: '0.79',
        mttr: '0.48333333333333334',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20002',
        mtbf: '0.57',
        mttr: '0.7743589743589744',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20002',
        mtbf: '0.23',
        mttr: '1.1330128205128207',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20002',
        mtbf: '5.46',
        mttr: '5.858333333333333',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20028',
        mtbf: '0.01',
        mttr: '0.39166666666666666',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20028',
        mtbf: '0.01',
        mttr: '0.25',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.1037634408602148',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5184210526315789',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.7392857142857143',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.7613888888888888',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.7425925925925925',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.46',
        mttr: '',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.61',
        mttr: '',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.43',
        mttr: '',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.16',
        mttr: '',
    },
    {
        averageTA: 61.58099130434783,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.49',
        mttr: '',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '17068',
        mtbf: '0.38',
        mttr: '0.47916666666666674',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '17068',
        mtbf: '3.53',
        mttr: '1.4333333333333333',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '1.0333333333333334',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '4.783333333333333',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '3.55',
        mttr: '1.05',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '1.15',
        mttr: '0.4749999999999999',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '1.5833333333333333',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '2.0833333333333335',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '21051',
        mtbf: '1.94',
        mttr: '0.9407407407407408',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '21051',
        mtbf: '3.28',
        mttr: '1.5694444444444444',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '21051',
        mtbf: '1.1',
        mttr: '0.3742424242424242',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '21051',
        mtbf: '0.19',
        mttr: '0.5656666666666667',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '5.966666666666667',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: '21066',
        mtbf: '1.09',
        mttr: '0.25',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.95',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.035416666666667',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.43828828828828825',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.5356807511737088',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.8166666666666664',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.27',
        mttr: '',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.34',
        mttr: '',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.25',
        mttr: '',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.16',
        mttr: '',
    },
    {
        averageTA: 48.01670416666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station8Robot5-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '7.69',
        mttr: '',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '13009',
        mtbf: '0.51',
        mttr: '20.461111111111112',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '16376',
        mtbf: '0.31',
        mttr: '5.178571428571429',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '17068',
        mtbf: '2.34',
        mttr: '2.0300000000000002',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20002',
        mtbf: '0.83',
        mttr: '3.9187499999999993',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20002',
        mtbf: '1.57',
        mttr: '2.4972222222222222',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20002',
        mtbf: '0.38',
        mttr: '9.616666666666665',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20002',
        mtbf: '0.21',
        mttr: '1.4666666666666663',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20002',
        mtbf: '0.87',
        mttr: '2.128333333333333',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20028',
        mtbf: '0.05',
        mttr: '1.4583333333333333',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '8.366666666666667',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20028',
        mtbf: '2.67',
        mttr: '1.0916666666666666',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '5.566666666666666',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20029',
        mtbf: '1.23',
        mttr: '1.1083333333333334',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20029',
        mtbf: '5.64',
        mttr: '1.2055555555555555',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20029',
        mtbf: '1.95',
        mttr: '1.75',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20029',
        mtbf: '1.21',
        mttr: '1.5041666666666667',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '20029',
        mtbf: '1.27',
        mttr: '0.5333333333333333',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '21051',
        mtbf: '1.15',
        mttr: '0.7166666666666667',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '21051',
        mtbf: '0.15',
        mttr: '2.134782608695652',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '21066',
        mtbf: '1.95',
        mttr: '0.8166666666666667',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: '21066',
        mtbf: '9.43',
        mttr: '5.066666666666666',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.2603174603174603',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.6166666666666665',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.3202380952380954',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.2722222222222221',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.0959459459459464',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.1',
        mttr: '',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.41',
        mttr: '',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.25',
        mttr: '',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.25',
        mttr: '',
    },
    {
        averageTA: 65.13317741935484,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.13',
        mttr: '',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '16376',
        mtbf: '',
        mttr: '0.8166666666666667',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '17068',
        mtbf: '',
        mttr: '0.2833333333333333',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '17068',
        mtbf: '0.06',
        mttr: '6.283333333333333',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '20002',
        mtbf: '0.32',
        mttr: '1.075',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '17.85',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '20029',
        mtbf: '',
        mttr: '1.6',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '20029',
        mtbf: '0.8',
        mttr: '1.7699999999999996',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '20029',
        mtbf: '0.02',
        mttr: '1.5611111111111111',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '20029',
        mtbf: '2.13',
        mttr: '1.5222222222222221',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '0.35',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: '21066',
        mtbf: '',
        mttr: '7.8',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.25',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.4316666666666666',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '4.348148148148148',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.5222222222222221',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '5.1',
        mttr: '',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.54',
        mttr: '',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.18',
        mttr: '',
    },
    {
        averageTA: 37.506089473684206,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.13',
        mttr: '',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '13009',
        mtbf: '3.47',
        mttr: '6.31',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '13009',
        mtbf: '2.0',
        mttr: '0.6738095238095239',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '13009',
        mtbf: '2.09',
        mttr: '1.2766666666666668',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '13009',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '13009',
        mtbf: '1.21',
        mttr: '1.0916666666666668',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '17008',
        mtbf: '0.03',
        mttr: '0.25',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '20002',
        mtbf: '0.53',
        mttr: '0.8803030303030304',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '20002',
        mtbf: '0.22',
        mttr: '0.9254385964912281',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '20002',
        mtbf: '0.19',
        mttr: '1.065686274509804',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '20002',
        mtbf: '0.31',
        mttr: '1.4807017543859646',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '20002',
        mtbf: '0.01',
        mttr: '0.2746031746031746',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '20004',
        mtbf: '0.15',
        mttr: '1.9928571428571427',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '20028',
        mtbf: '2.55',
        mttr: '2.4999999999999996',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: '20029',
        mtbf: '0.0',
        mttr: '0.25',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.9222222222222224',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.8862962962962962',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.1136363636363635',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.2934343434343434',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.28141025641025635',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.26',
        mttr: '',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.12',
        mttr: '',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.15',
        mttr: '',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.26',
        mttr: '',
    },
    {
        averageTA: 67.03332916666666,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line6 Station9Robot4-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 20.916,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station11Robot7-1',
        faultCode: '20028',
        mtbf: '0.05',
        mttr: '2.1083333333333334',
    },
    {
        averageTA: 20.916,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station11Robot7-1',
        faultCode: '21051',
        mtbf: '8.02',
        mttr: '1.2416666666666667',
    },
    {
        averageTA: 20.916,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station11Robot7-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '5.25',
    },
    {
        averageTA: 20.916,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station11Robot7-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.2416666666666667',
    },
    {
        averageTA: 20.916,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station11Robot7-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.736666666666667',
    },
    {
        averageTA: 20.916,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station11Robot7-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '8.02',
        mttr: '',
    },
    {
        averageTA: 20.916,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station11Robot7-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.12',
        mttr: '',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '1.7',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: '20003',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '2.783333333333333',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: '20028',
        mtbf: '2.47',
        mttr: '5.875',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: '20028',
        mtbf: '3.22',
        mttr: '2.4833333333333334',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: '21051',
        mtbf: '',
        mttr: '0.48333333333333334',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.783333333333333',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.483333333333333',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.0',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.85',
        mttr: '',
    },
    {
        averageTA: 18.50195,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.15',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-System',
        faultCode: '10033',
        mtbf: '2.01',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-System',
        faultCode: '10033',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.01',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot1-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.03',
        mttr: '',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: '13016',
        mtbf: '0.7',
        mttr: '3.2444444444444445',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '5.316666666666666',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: '20002',
        mtbf: '',
        mttr: '0.7333333333333333',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: '20003',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '1.85',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: '20028',
        mtbf: '',
        mttr: '1.25',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '5.316666666666666',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '1.85',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '2.993333333333333',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '3.1979166666666665',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.62',
        mttr: '',
    },
    {
        averageTA: 18.921415384615383,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '1.23',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-System',
        faultCode: '10033',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot2-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-1',
        faultCode: '20003',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '6.016666666666667',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-1',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-1',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '2.81',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: '10033',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: '10033',
        mtbf: '0.89',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: '10033',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: '10033',
        mtbf: '0.02',
        mttr: '84.4',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: '20103',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '0.25',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: 'Event_Agnostic_Downtime',
        mtbf: '',
        mttr: '84.475',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.89',
        mttr: '',
    },
    {
        averageTA: 0,
        averagemtbf: 132.45,
        averagemttr: 30.0,
        deviceNameOutlet: 'Line7 Station5Robot3-System',
        faultCode: 'Event_Agnostic_MTBF',
        mtbf: '0.01',
        mttr: '',
    },
];

export default data;
