/**
 *
 * CarBodyGraph
 *
 */

import CarBodyGraph, { CarBodyGraphProperty } from './CarBodyGraph';

export { CarBodyGraph, CarBodyGraphProperty };

export default CarBodyGraph;
