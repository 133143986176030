/**
 *
 * DashboardFilterPanel
 *
 */

import DashboardFilterPanel from './DashboardFilterPanel';

export { DashboardFilterPanel };

export default DashboardFilterPanel;
