/**
 *
 * EventRatePerDevice
 *
 */

import EventRatePerDevice, { EventRatePerDeviceProperty } from './EventRatePerDevice';

export { EventRatePerDevice, EventRatePerDeviceProperty };

export default EventRatePerDevice;
