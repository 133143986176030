/**
 *
 * SelectStudIdFilter
 *
 */

import ReportThresholdFilter, { ThresholdFilterView } from './ReportThresholdFilter';

export { ReportThresholdFilter, ThresholdFilterView };

export default ReportThresholdFilter;
