/**
 *
 * SelectFilter
 *
 */

import SelectFilter from './SelectFilter';

export { SelectFilter };

export default SelectFilter;
