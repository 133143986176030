/**
 *
 * EventRateCycleCount
 *
 */

import EventRateCycleCount, { EventRateCycleCountProperty } from './EventRateCycleCount';

export { EventRateCycleCount, EventRateCycleCountProperty };

export default EventRateCycleCount;
