/**
 *
 * UserManagement
 *
 */

import UserManagement from './UserManagement';
import UserManagementLoadable from './Loadable';

export { UserManagement, UserManagementLoadable };

export default UserManagement;
