import { MaintainanceActionTables } from 'models';

export const data: MaintainanceActionTables | any[] = [
    {
        id: 1,
        date: '11/07/2021',
        shiftInTime: 'FS',
        plantStation: 'AS73 305TSB101',
        boltType: '6A09',
        service: 'Prier',
        comment: 'No SOW',
        otherReadings: ' ',
    },
    {
        id: 2,
        date: '22/07/2021',
        shiftInTime: 'FS',
        plantStation: 'HC73 080TSB201',
        boltType: '6A09',
        service: 'Prier',
        comment: 'No SOW - fastener (stud)',
        otherReadings: ' ',
    },
    {
        id: 3,
        date: '23/07/2021',
        shiftInTime: 'NS',
        plantStation: 'HC73 080TSB301',
        boltType: '6A09',
        service: 'Prier',
        comment: 'DCPU-PM: Feed cycle failed',
        otherReadings: ' ',
    },
    {
        id: 4,
        date: '24/07/2021',
        shiftInTime: 'FS',
        plantStation: 'HC73 080TSB401',
        boltType: '6A09',
        service: 'Prier',
        comment: 'LMC: No room for lift',
        otherReadings: ' ',
    },
    {
        id: 5,
        date: '25/07/2021',
        shiftInTime: 'FS',
        plantStation: 'Line1Station2Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'No SOW',
        otherReadings: ' ',
    },
    {
        id: 6,
        date: '26/07/2021',
        shiftInTime: 'FS',
        plantStation: 'RS75 080TSB401',
        boltType: '6A09',
        service: 'Prier',
        comment: 'Weld tool not in R position',
        otherReadings: ' ',
    },
    {
        id: 7,
        date: '27/07/2021',
        shiftInTime: 'NS',
        plantStation: 'UB63 070TSB201',
        boltType: '6A09',
        service: 'Prier',
        comment: 'DCPU-CI: Start weld cycle not reset',
        otherReadings: ' ',
    },
    {
        id: 8,
        date: '28/07/2021',
        shiftInTime: 'FS',
        plantStation: 'HC73 080TSB201',
        boltType: '6A09',
        service: 'Prier',
        comment: 'DCPU-PM: Failure to reset faults',
        otherReadings: ' ',
    },
    {
        id: 9,
        date: '29/07/2021',
        shiftInTime: 'FS',
        plantStation: 'RS75 050TSB401',
        boltType: '6A09',
        service: 'Prier',
        comment: 'No SOW',
        otherReadings: ' ',
    },
    {
        id: 10,
        date: '01/08/2021',
        shiftInTime: 'FS',
        plantStation: 'RS75 050TSB401',
        boltType: '6A09',
        service: 'Prier',
        comment: 'Data link feeder',
        otherReadings: ' ',
    },
    {
        id: 11,
        date: '02/08/2021',
        shiftInTime: 'NS',
        plantStation: 'RS75 050TSB401',
        boltType: '6A09',
        service: 'Prier',
        comment: 'LMC: Lift height out of tolerance',
        otherReadings: ' ',
    },
    {
        id: 12,
        date: '03/08/2021',
        shiftInTime: 'FS',
        plantStation: 'RS75 050TSB401',
        boltType: '6A09',
        service: 'Prier',
        comment: 'SMPS: Lift faul',
        otherReadings: ' ',
    },
    {
        id: 13,
        date: '04/08/2021',
        shiftInTime: 'FS',
        plantStation: 'RS75 050TSB401',
        boltType: '6A09',
        service: 'Prier',
        comment: 'DCPU-PM: Program not selected',
        otherReadings: ' ',
    },
    {
        id: 14,
        date: '05/08/2021',
        shiftInTime: 'FS',
        plantStation: 'UB63 070TSB101',
        boltType: '6A09',
        service: 'Prier',
        comment: 'LMC: Linear motor lift timeout',
        otherReadings: ' ',
    },
];

export const dataDemo: MaintainanceActionTables | any[] = [
    {
        id: 1,
        date: '11/07/2021',
        shiftInTime: 'FS',
        plantStation: 'Line10Station9Robot8',
        boltType: '6A09',
        service: 'Prier',
        comment: 'No SOW',
        otherReadings: ' ',
    },
    {
        id: 2,
        date: '22/07/2021',
        shiftInTime: 'FS',
        plantStation: 'Line1Station1Robot1',
        boltType: '6A09',
        service: 'Prier',
        comment: 'No SOW - fastener (stud)',
        otherReadings: ' ',
    },
    {
        id: 3,
        date: '23/07/2021',
        shiftInTime: 'NS',
        plantStation: 'Line1Station1Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'DCPU-PM: Feed cycle failed',
        otherReadings: ' ',
    },
    {
        id: 4,
        date: '24/07/2021',
        shiftInTime: 'FS',
        plantStation: 'Line2Station2Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'LMC: No room for lift',
        otherReadings: ' ',
    },
    {
        id: 5,
        date: '25/07/2021',
        shiftInTime: 'FS',
        plantStation: 'Line2Station2Robot3',
        boltType: '6A09',
        service: 'Prier',
        comment: 'No SOW',
        otherReadings: ' ',
    },
    {
        id: 6,
        date: '26/07/2021',
        shiftInTime: 'FS',
        plantStation: 'Line2Station2Robot4',
        boltType: '6A09',
        service: 'Prier',
        comment: 'Weld tool not in R position',
        otherReadings: ' ',
    },
    {
        id: 7,
        date: '27/07/2021',
        shiftInTime: 'NS',
        plantStation: 'Line3Station2Robot4',
        boltType: '6A09',
        service: 'Prier',
        comment: 'DCPU-CI: Start weld cycle not reset',
        otherReadings: ' ',
    },
    {
        id: 8,
        date: '28/07/2021',
        shiftInTime: 'FS',
        plantStation: 'Line1Station2Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'DCPU-PM: Failure to reset faults',
        otherReadings: ' ',
    },
    {
        id: 9,
        date: '29/07/2021',
        shiftInTime: 'FS',
        plantStation: 'Line1Station2Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'No SOW',
        otherReadings: ' ',
    },
    {
        id: 10,
        date: '01/08/2021',
        shiftInTime: 'FS',
        plantStation: 'Line1Station2Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'Data link feeder',
        otherReadings: ' ',
    },
    {
        id: 11,
        date: '02/08/2021',
        shiftInTime: 'NS',
        plantStation: 'Line1Station2Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'LMC: Lift height out of tolerance',
        otherReadings: ' ',
    },
    {
        id: 12,
        date: '03/08/2021',
        shiftInTime: 'FS',
        plantStation: 'Line1Station2Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'SMPS: Lift faul',
        otherReadings: ' ',
    },
    {
        id: 13,
        date: '04/08/2021',
        shiftInTime: 'FS',
        plantStation: 'Line1Station2Robot2',
        boltType: '6A09',
        service: 'Prier',
        comment: 'DCPU-PM: Program not selected',
        otherReadings: ' ',
    },
    {
        id: 14,
        date: '05/08/2021',
        shiftInTime: 'FS',
        plantStation: 'UB63 070TSB101',
        boltType: '6A09',
        service: 'Prier',
        comment: 'LMC: Linear motor lift timeout',
        otherReadings: ' ',
    },
];

export default data;
