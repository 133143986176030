/**
 *
 * SelectDeviceNameFilter
 *
 */

import SelectDeviceNameFilterNew from './SelectDeviceNameFilter';

export { SelectDeviceNameFilterNew };

export default SelectDeviceNameFilterNew;
