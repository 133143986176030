/**
 *
 * EventRatePerEvent
 *
 */

import EventRatePerEvent, { EventRatePerEventProperty } from './EventRatePerEvent';

export { EventRatePerEvent, EventRatePerEventProperty };

export default EventRatePerEvent;
