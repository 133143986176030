/**
 *
 * VarianceAnalysis
 *
 */

import VarianceAnalysis, { VarianceAnalysisProperty } from './VarianceAnalysis';

export { VarianceAnalysis, VarianceAnalysisProperty };

export default VarianceAnalysis;
