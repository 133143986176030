/**
 *
 * SelectAggregateColumn
 *
 */

import SelectAggregateColumn from './SelectAggregateColumn';
import SelectAggregateColumnLoadable from './Loadable';

export { SelectAggregateColumn, SelectAggregateColumnLoadable };

export default SelectAggregateColumn;
