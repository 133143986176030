/**
 *
 * ProcessLog
 *
 */

import ProcessLog, { ProcessLogProperty } from './ProcessLog';

export { ProcessLog, ProcessLogProperty };

export default ProcessLog;
