/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
    studProjectionMeasurementTrendTitle: _t(
        translations.Widgets.StudProjectionMeasurementTrend.WidgetTitle,
        'StudProjectionMeasurementTrend widget',
    ), // default value
    studProjectionMeasurementTrendSubTitle: _t(
        translations.Widgets.StudProjectionMeasurementTrend.SubTitle,
        'A studProjectionMeasurementTrend widget',
    ), // default value
    boltProjection: _t(
        translations.Widgets.StudProjectionMeasurementTrend.BoltProjection,
        'Bolt projection (deviation average)',
    ),
};
