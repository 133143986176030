/**
 *
 * MttrAnalysisSpr
 *
 */

import MttrAnalysisSpr from './MttrAnalysisSpr';
import MttrAnalysisSprLoadable from './Loadable';

export { MttrAnalysisSpr, MttrAnalysisSprLoadable };

export default MttrAnalysisSpr;
