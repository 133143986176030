export const columns: any[] = [
    {
        name: 'deviceNameOutlet',
        // flex: 1,
        minWidth: 300,
        type: 'string',
    },
];

export default columns;
